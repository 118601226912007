import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { GetApplicationsRequest } from "api";
import { toDate } from "common/date";
import getApiClient from "common/ApiClientFactory";
import OrderByToggle from "components/OrderByToggle";
import { Link } from "react-router-dom";
import Pagination from "components/pagination/paginationInternal";
import { removeCollectionPrefix, copyToClipboard } from "common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { applications } from "./applications.module";
import Disabler from "components/Disabler";

const ApplicationsTable = () => {
  const rows = useSimplux(applications.rows);
  const page = useSimplux(applications.page);
  const pageSize = useSimplux(applications.pageSize);
  const search = useSimplux(applications.search);
  const orderBy = useSimplux(applications.orderBy);
  const total = useSimplux(applications.total);
  const pages = useSimplux(applications.pages);

  useEffect(() => {
    fetchData(page, pageSize, search, orderBy);
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Applications</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) => applications.setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            defaultValue={pageSize}
            onChange={(event) =>
              applications.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="ApplicationId"
                text="Application ID"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="CompanyName"
                text="Company"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Description"
                text="Description"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="RequestDate"
                text="Request Date"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="AcceptDate"
                text="Accept Date"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="DisableDate"
                text="Disable Date"
                orderBy={orderBy}
                onChange={(orderBy) => applications.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto) => {
            return (
              <tr key={dto.id}>
                <td>
                  <Badge
                    color="primary"
                    className="pointer"
                    onClick={() => copyToClipboard(dto.applicationId)}
                  >
                    {dto.applicationId}
                  </Badge>
                </td>
                <td>
                  <Link to={`/${dto.acquirer?.id?.toLowerCase()}`}>
                    {dto.acquirer?.acquirerId}
                  </Link>
                </td>
                <td>
                  <Link to={`/${dto.acquirer?.company?.id}`}>
                    {dto.acquirer?.company?.name}
                  </Link>
                </td>
                <td>{dto.description}</td>
                <td>{toDate(dto.requestDate)}</td>
                <td>{toDate(dto.acceptDate)}</td>
                <td>
                  <Disabler
                    disabledDate={dto.disableDate}
                    targetId={dto.id}
                    onUpdated={() => fetchData(page, pageSize, search, orderBy)}
                    id={dto.applicationId}
                    description={dto.description}
                  />
                </td>
                <td>
                  <Link to={`applications/${removeCollectionPrefix(dto.id)}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => applications.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  orderBy: string
) {
  let client = getApiClient();
  let request = new GetApplicationsRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = orderBy;

  let response = await client.get(request);

  applications.fromResponse(response);
}

export default ApplicationsTable;
