import React from "react";
import {
  createUltimatePagination,
  ITEM_TYPES,
} from "react-ultimate-pagination";

const WrapperComponent = ({ children }) => (
  <ul className="pagination">{children}</ul>
);

const withPreventDefault = (fn) => (event) => {
  event.preventDefault();
  fn();
};

const Page = ({ value, isActive, onClick }) => (
  <li className={isActive ? "page-item active" : "page-item"}>
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      {value}
    </button>
  </li>
);

const Ellipsis = ({ onClick }) => (
  <li className="page-item">
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      ...
    </button>
  </li>
);

const FirstPageLink = ({ onClick }) => (
  <li className="page-item">
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      &laquo;
    </button>
  </li>
);

const PreviousPageLink = ({ onClick }) => (
  <li className="page-item">
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      &lsaquo;
    </button>
  </li>
);

const NextPageLink = ({ onClick }) => (
  <li className="page-item">
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      &rsaquo;
    </button>
  </li>
);

const LastPageLink = ({ onClick }) => (
  <li className="page-item">
    <button className="page-link" onClick={withPreventDefault(onClick)}>
      &raquo;
    </button>
  </li>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};

const Pagination = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent,
});

export default Pagination;
