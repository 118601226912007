import Hero from "components/Hero";
import React from "react";
import NonceOffsetsTable from "./NonceOffsetsTable";

const NonceOffsets = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <NonceOffsetsTable />
      </section>
    </>
  );
};

export default NonceOffsets;
