import React, { useEffect, useState } from "react";
import Hero from "components/Hero";
import { useParams, useHistory } from "react-router-dom";
import getApiClient from "common/ApiClientFactory";
import {
  ContactPersonDto,
  UpdateContactPersonRequest,
  GetContactPersonRequest,
  CreateContactPerson,
  DeleteContactPerson,
} from "api";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import ContactPersonMerger from "./ContactPersonMerger";
import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";
import confirm from "reactstrap-confirm";

const ContactPersonDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  let { id, companyId, applicationId, terminalSupplierId, acquirerId } =
    useParams<any>();
  const [dto, setDto] = useState(new ContactPersonDto());

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ContactPersonDto>({
    defaultValues: dto,
  });

  const history = useHistory();

  const getBackLink = () => {
    if (acquirerId) {
      return `/acquirers/${acquirerId}/applications/${applicationId}`;
    } else if (companyId) {
      return `${isAdmin ? "/admin" : ""}/companies/${companyId}`;
    } else if (applicationId) {
      return `${isAdmin ? "/admin" : ""}/applications/${applicationId}`;
    } else if (terminalSupplierId) {
      return `${
        isAdmin ? "/admin" : ""
      }/terminal-suppliers/${terminalSupplierId}`;
    } else {
      return `${isAdmin ? "/admin" : ""}/contact-people`;
    }
  };

  const onSubmit = async (data: ContactPersonDto) => {
    let client = getApiClient();

    if (id !== "new") {
      let request = new UpdateContactPersonRequest();
      request.id = dto.id;
      request.firstName = data.firstName;
      request.lastName = data.lastName;
      request.phone = data.phone;
      request.email = data.email;

      await client.put(request);

      toast.success("Contact Person saved");
      history.push(getBackLink());
    } else {
      let request = new CreateContactPerson();
      request.firstName = data.firstName;
      request.lastName = data.lastName;
      request.phone = data.phone;
      request.email = data.email;

      if (companyId) {
        request.targetId = `Companies/${companyId}`;
      } else if (applicationId) {
        request.targetId = `Applications/${applicationId}`;
      } else if (terminalSupplierId) {
        request.targetId = `TerminalSuppliers/${terminalSupplierId}`;
      }

      await client.post(request);

      toast.success("Contact Person created");
      history.push(getBackLink());
    }
  };

  const onDelete = async (id: string) => {
    let confirmed = await confirm({
      title: "Confirm",
      message: (
        <>
          <span>
            Please confirm to delete{" "}
            <strong>
              {dto.firstName} {dto.lastName} {dto.email}
            </strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DeleteContactPerson();
      request.id = id;

      let client = getApiClient();
      await client.delete(request);

      toast.success("Contact Person deleted");
      history.push(getBackLink());
    }
  };

  useEffect(() => {
    if (id !== "new" && id) {
      fetchContactPerson(id, setDto, (cp) => reset(cp));
    }
  }, [id, reset]);

  const buttonText = id === "new" ? "add" : "save";

  return (
    <>
      <Hero />
      <section className="section m-4">
        {dto && (
          <Container className="mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>Contact Person</h3>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Details
                </small>
              </div>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="firstName"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="First name"
                          type="text"
                          {...field}
                          invalid={!!errors.firstName}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="lastName"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Last name"
                          type="text"
                          {...field}
                          invalid={!!errors.lastName}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Phone"
                          type="text"
                          {...field}
                          invalid={!!errors.phone}
                        />
                      )}
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: "Required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          placeholder="Email"
                          type="email"
                          {...field}
                          invalid={!!errors.email}
                        />
                      )}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Button type="submit" color="primary">
                    {buttonText}
                  </Button>
                </Col>
                <Col>
                  {id !== "new" && (
                    <Button
                      type="button"
                      color="danger"
                      className="float-right"
                      onClick={() => onDelete(dto.id)}
                    >
                      Delete
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
            {isAdmin && id !== "new" && (
              <Row>
                <Col>
                  <ContactPersonMerger
                    targetContactPersonId={`ContactPeople/${id.toUpperCase()}`}
                  />
                </Col>
              </Row>
            )}
          </Container>
        )}
      </section>
    </>
  );
};

async function fetchContactPerson(
  id: string,
  setDto: React.Dispatch<React.SetStateAction<ContactPersonDto>>,
  callback: (cp: ContactPersonDto) => void
) {
  let client = getApiClient();
  let request = new GetContactPersonRequest();

  request.id = id;
  let dto = await client.get(request);

  if (callback) {
    callback(dto);
  }

  setDto(dto);
}

export default ContactPersonDetail;
