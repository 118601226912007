import React, { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { userDetail } from "./user-detail.module";
import { useSimplux } from "@simplux/react";
import {
  UpdateUserRequest,
  GetUserRequest,
  UserDetailDto,
  GetCompaniesRequest,
  DeleteUser,
} from "../../../api";
import { Controller, useForm } from "react-hook-form";
import getApiClient from "../../../common/ApiClientFactory";
import Hero from "../../../components/Hero";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";

const lookupCompanies = (inputValue: string) => {
  return new Promise((resolve) => {
    let request = new GetCompaniesRequest();
    request.search = inputValue;
    request.pageSize = 8;
    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.rows.map((m) => {
        return { value: m.id, label: m.name, dto: m };
      });

      resolve(options);
    });
  });
};

const handleCompanySelections = (selection: any) => {
  if (!selection) {
    return;
  }

  userDetail.addCompany(selection.dto);
};

const UserDetail = () => {
  let { id } = useParams<any>();
  let dto = useSimplux(userDetail.dto);
  let selectedCompany = useSimplux(userDetail.selectedCompany);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<UserDetailDto>();
  const history = useHistory();

  const onSubmit = async (data: UserDetailDto) => {
    let client = getApiClient();
    let request = new UpdateUserRequest();
    Object.assign(request, data);

    request.roles = [];

    if (data.isAdmin) {
      request.roles.push("Admin");
    }
    if (data.isServiceCenter) {
      request.roles.push("ServiceCenter");
    }
    if (data.isAcquirer) {
      request.roles.push("Acquirer");
    }

    request.companyIds = dto.companyIds as string[];

    request.id = `users/${id}`;
    await client.put(request);

    userDetail.reset();

    history.push("/admin/user-management");
  };

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetUserRequest();

      request.id = `users/${id}`;
      let dto = await client.get(request);

      reset(dto);
      userDetail.setUser(dto);
    }

    async();
  }, [id, reset]);

  const onDelete = async (id: string) => {
    let confirmed = await confirm({
      title: "Confirm",
      message: (
        <>
          <span>
            Please confirm to delete{" "}
            <strong>
              {dto.firstName} {dto.lastName} {dto.email}
            </strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DeleteUser();
      request.id = id;

      let client = getApiClient();
      await client.delete(request);

      toast.success("User deleted");
      history.push("/admin/user-management");
    }
  };

  const buttonText = id === "new" ? "add" : "save";

  return (
    <>
      <Hero />
      <section className="section m-4 mb-dropdown">
        <Container className="mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3>User</h3>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Details</small>
            </div>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="firstName"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="First name"
                        type="text"
                        {...field}
                        invalid={!!errors.firstName}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Last name"
                        type="text"
                        {...field}
                        invalid={!!errors.lastName}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: "Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder="Email"
                        type="email"
                        {...field}
                        invalid={!!errors.email}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Phone"
                        type="text"
                        {...field}
                        invalid={!!errors.phoneNumber}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Roles</small>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <div className="custom-control custom-checkbox mb-3">
                    <Controller
                      control={control}
                      name="isAdmin"
                      render={({ field }) => (
                        <Input
                          className="custom-control-input"
                          id="isAdmin"
                          type="checkbox"
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />

                    <label className="custom-control-label" htmlFor="isAdmin">
                      Admin
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Assigned Companies
              </small>
            </div>
            <Row>
              <Col>
                <ListGroup className="mb-2">
                  {dto.companies?.map((company, i) => {
                    return (
                      <ListGroupItem key={company.id}>
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-1">
                            <Link to={`/${company.id}`} className="mr-1">
                              {company.name}
                            </Link>
                            <small>{company.country}</small>
                          </h5>
                          <small>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() =>
                                userDetail.removeCompany(company.id)
                              }
                            >
                              x
                            </Button>
                          </small>
                        </div>
                        <p className="mb-1">
                          {company.street1}, {company.postCode} {company.city}
                        </p>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <AsyncSelect
                    defaultOptions
                    loadOptions={lookupCompanies}
                    onChange={handleCompanySelections}
                    value={selectedCompany}
                    isClearable
                    placeholder="Assign company..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button type="submit" color="primary">
                  {buttonText}
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => onDelete(dto.key)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

export default UserDetail;
