import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Button,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { GetContactPeopleRequest } from "api";
import getApiClient from "common/ApiClientFactory";
import OrderByToggle from "components/OrderByToggle";
import { Link, useHistory } from "react-router-dom";
import Pagination from "components/pagination/paginationInternal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { contactPeople } from "./contact-people.module";
import { removeCollectionPrefix } from "common/utils";

const ContactPeopleTable = () => {
  const rows = useSimplux(contactPeople.rows);
  const page = useSimplux(contactPeople.page);
  const pageSize = useSimplux(contactPeople.pageSize);
  const search = useSimplux(contactPeople.search);
  const orderBy = useSimplux(contactPeople.orderBy);
  const total = useSimplux(contactPeople.total);
  const pages = useSimplux(contactPeople.pages);

  const history = useHistory();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetContactPeopleRequest();
      request.page = page;
      request.pageSize = pageSize;
      request.search = search;
      request.orderBy = orderBy;

      let response = await client.get(request);

      contactPeople.fromResponse(response);
    }

    async();
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Contact People</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) =>
                  contactPeople.setSearch(event.target.value)
                }
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            defaultValue={pageSize}
            onChange={(event) =>
              contactPeople.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>
          <Button
            color="primary"
            onClick={() => history.push("/admin/contact-people/new")}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="FirstName"
                text="First Name"
                orderBy={orderBy}
                onChange={(orderBy) => contactPeople.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="LastName"
                text="Last Name"
                orderBy={orderBy}
                onChange={(orderBy) => contactPeople.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Email"
                text="Email"
                orderBy={orderBy}
                onChange={(orderBy) => contactPeople.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Phone"
                text="Phone"
                orderBy={orderBy}
                onChange={(orderBy) => contactPeople.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto) => {
            return (
              <tr key={dto.id}>
                <td>{dto.firstName}</td>
                <td>{dto.lastName}</td>
                <td>{dto.email}</td>
                <td>{dto.phone}</td>
                <td>
                  <Link
                    to={`/admin/contact-people/${removeCollectionPrefix(
                      dto.id
                    )}`}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => contactPeople.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ContactPeopleTable;
