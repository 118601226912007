import React from "react";
import { Button, Badge } from "reactstrap";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";
import { toDate } from "common/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { DisableRequest } from "api";
import getApiClient from "common/ApiClientFactory";

type DisablerProps = {
  targetId: string;
  disabledDate: string;
  id: string;
  description: string;
  onUpdated: () => void;
};

const Disabler = (props: DisablerProps) => {
  const disable = async () => {
    let confirmed = await confirm({
      title: "Disabling",
      message: (
        <>
          <span>
            Please confirm disabling <Badge color="primary">{props.id}</Badge>{" "}
            <strong>{props.description}</strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DisableRequest();
      request.id = props.targetId;
      request.disable = true;

      let client = getApiClient();
      await client.post(request);
      toast.success("disabled");
      props.onUpdated();
    }
  };

  const enable = async () => {
    let confirmed = await confirm({
      title: "Remove disable date",
      message: (
        <>
          <span>
            Please confirm enabling <Badge color="primary">{props.id}</Badge>{" "}
            <strong>{props.description}</strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DisableRequest();
      request.id = props.targetId;
      request.disable = false;

      let client = getApiClient();
      await client.post(request);
      toast.success("reenabled");
      props.onUpdated();
    }
  };

  if (!props.disabledDate) {
    return (
      <Button color="warning" size="sm" onClick={disable}>
        <FontAwesomeIcon icon={faMinusCircle} />
      </Button>
    );
  }

  return (
    <>
      <span className="mr-2">{toDate(props.disabledDate)}</span>
      <FontAwesomeIcon icon={faTimes} onClick={enable} className="pointer" />
    </>
  );
};

export default Disabler;
