import {
  createMutations,
  createSimpluxModule,
  createSelectors,
} from "@simplux/core";
import { PagedResponse, MerchantRangeDto } from "api";

const merchantRangesModule = createSimpluxModule({
  name: "merchant-ranges-table",
  initialState: {
    rows: [] as MerchantRangeDto[],
    total: 0,
    page: 1,
    pageSize: 25,
    search: "",
    orderBy: "MerchantRangeId",
  },
});

export const merchantRanges = {
  ...merchantRangesModule,
  ...createMutations(merchantRangesModule, {
    fromResponse(state, response: PagedResponse<MerchantRangeDto>) {
      state.rows = response.rows;
      state.total = response.totalRows;
      state.page = response.page;
      state.pageSize = response.pageSize;
    },
    setPageSize(state, pageSize: number) {
      state.page = 1;
      state.pageSize = pageSize;
    },
    setSearch(state, search: string) {
      state.search = search;
      state.page = 1;
    },
    setOrderBy(state, orderBy: string) {
      state.orderBy = orderBy;
    },
    setPage(state, page: number) {
      state.page = page;
    },
  }),
  ...createSelectors(merchantRangesModule, {
    rows: ({ rows }) => rows,
    page: ({ page }) => page,
    pageSize: ({ pageSize }) => pageSize,
    search: ({ search }) => search,
    orderBy: ({ orderBy }) => orderBy,
    total: ({ total }) => total,
    pages: ({ total, pageSize }) => Math.ceil(total / pageSize),
  }),
};
