import React from "react";
import Hero from "../../../components/Hero";
import ApplicationsTable from "./ApplicationsTable";

const Applications = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <ApplicationsTable />
      </section>
    </>
  );
};

export default Applications;
