import {
  createSimpluxModule,
  createMutations,
  createSelectors,
} from "@simplux/core";
import { RequestDto, PagedResponse } from "../../../api";

const requestsModule = createSimpluxModule({
  name: "requests",
  initialState: {
    rows: [] as RequestDto[],
    total: 0,
    page: 1,
    pageSize: 25,
    search: "",
    showOnlyOpen: true,
  },
});

export const requests = {
  ...requestsModule,
  ...createMutations(requestsModule, {
    fromResponse(state, response: PagedResponse<RequestDto>) {
      state.rows = response.rows;
      state.total = response.totalRows;
      state.page = response.page;
      state.pageSize = response.pageSize;
    },
    setPageSize(state, pageSize: number) {
      state.page = 1;
      state.pageSize = pageSize;
    },
    setSearch(state, search: string) {
      state.search = search;
      state.page = 1;
    },
    setShowOnlyOpen(state, showOnlyOpen: boolean) {
      state.showOnlyOpen = showOnlyOpen;
    },
    setPage(state, page: number) {
      state.page = page;
    },
  }),
  ...createSelectors(requestsModule, {
    rows: ({ rows }) => rows,
    page: ({ page }) => page,
    pageSize: ({ pageSize }) => pageSize,
    search: ({ search }) => search,
    showOnlyOpen: ({ showOnlyOpen }) => showOnlyOpen,
    pages: ({ total, pageSize }) => Math.ceil(total / pageSize),
    total: ({ total }) => total,
  }),
};
