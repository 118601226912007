import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import {
  PagedResponse,
  ContactPersonDto,
  GetContactPeopleFromAssignedCompany,
} from "api";
import getApiClient from "common/ApiClientFactory";

type SelectContactPersonTableProps = {
  serviceCenterId?: string;
  acquirerId?: string;
  onChange: (contactPersonId: string) => void;
};

const SelectContactPersonTable = (props: SelectContactPersonTableProps) => {
  const [response, setResponse] = useState<PagedResponse<ContactPersonDto>>();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetContactPeopleFromAssignedCompany();
      request.page = 1;
      request.pageSize = 1000;
      request.serviceCenterId = props.serviceCenterId;
      request.acquirerId = props.acquirerId;

      let response = await client.get(request);

      setResponse(response);
    }

    if (props.serviceCenterId || props.acquirerId) {
      async();
    } else {
      setResponse(new PagedResponse<ContactPersonDto>());
    }
  }, [props.serviceCenterId, props.acquirerId]);

  return (
    <>
      <Table className="align-items-center table-hover table-sm">
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">First name</th>
            <th scope="col">Last name</th>
            <th scope="col">E-Mail</th>
            <th scope="col">Phone</th>
          </tr>
        </thead>
        <tbody>
          {response?.rows?.map((dto, i) => {
            return (
              <tr key={dto.id}>
                <td>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id={`radio-${dto.id}`}
                      name="custom-radio-2"
                      type="radio"
                      value={dto.id}
                      onChange={(event) => props.onChange(event.target.value)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`radio-${dto.id}`}
                    >
                      &nbsp;
                    </label>
                  </div>
                </td>
                <td>{dto.firstName}</td>
                <td>{dto.lastName}</td>
                <td>{dto.email}</td>
                <td>{dto.phone}</td>
              </tr>
            );
          })}
          {!response?.totalRows && (props.serviceCenterId || props.acquirerId) && (
            <tr>
              <td colSpan={5}>No entries</td>
            </tr>
          )}
          {!props.serviceCenterId && !props.acquirerId && (
            <tr>
              <td colSpan={5}>Please make a selection first</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default SelectContactPersonTable;
