import { JsonServiceClient } from "@servicestack/client";

export function getApiUrl() {
  if (window.location.origin === "http://localhost:3000") {
    return "http://localhost:8088";
  }

  return window.location.origin + "/api";
}

let client = new JsonServiceClient(getApiUrl());

function getApiClient() {
  return client;
}

export default getApiClient;
