import React from "react";
import { ContactPersonDto } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";

type PropTypes = {
  dto: ContactPersonDto;
};

const ContactPersonDisplay = (props: PropTypes) => {
  if (!props.dto) {
    return null;
  }

  return (
    <>
      <strong>
        <FontAwesomeIcon icon={faUser} /> Primary Contact
      </strong>
      <br />
      {props.dto.firstName} {props.dto.lastName}
      <br />
      <FontAwesomeIcon icon={faEnvelope} /> {props.dto.email}
      <br />
      <FontAwesomeIcon icon={faPhone} /> {props.dto.phone}
    </>
  );
};

export default ContactPersonDisplay;
