let moment = require("moment");

function toDate(str: string) {
  if (!str) {
    return undefined;
  }

  let m = moment(str);

  return m.format("DD.MM.YYYY");
}

function toDateTime(str: string) {
  if (!str) {
    return undefined;
  }

  let m = moment(str);

  if (m.year() === 1) {
    return undefined;
  }

  return m.format("DD.MM.YYYY HH:mm");
}

export { toDate, toDateTime };
