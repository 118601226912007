import React from "react";
import { GetAcquirersRequest, AcquirerDto } from "api";
import AsyncSelect from "react-select/async";
import getApiClient from "common/ApiClientFactory";
import { to11 } from "common/utils";

type AcquirerSelectProps = {
  onChange: (selection: AcquirerDto) => void;
  defaultValue: AcquirerDto;
};

const AcquirerSelect = (props: AcquirerSelectProps) => {
  const handleSelection = (selection: any) => {
    if (!selection) {
      props.onChange(undefined);
    } else {
      props.onChange(selection.dto);
    }
  };

  return (
    <AsyncSelect
      defaultOptions
      defaultValue={createOptionFromDto(props.defaultValue)}
      loadOptions={lookupAcquirers}
      onChange={handleSelection}
      isClearable
      placeholder="Select acquirer..."
    />
  );
};

const lookupAcquirers = (inputValue: string) => {
  return new Promise((resolve) => {
    let request = new GetAcquirersRequest();
    request.search = inputValue;
    request.pageSize = 13333337;
    request.orderBy = "AcquirerId";

    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.rows.map((serviceCenter) => {
        return createOptionFromDto(serviceCenter);
      });

      resolve(options);
    });
  });
};

const createOptionFromDto = (dto: AcquirerDto) => {
  if (!dto || !dto.id) {
    return undefined;
  }

  return {
    value: dto.id,
    label: `${to11(dto.acquirerId)} ${
      dto.company ? `(${dto.company.name})` : ""
    }`,
    dto: dto,
  };
};

export default AcquirerSelect;
