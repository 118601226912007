import React from "react";
import { ContactPersonDto, GetContactPeopleRequest } from "api";
import AsyncSelect from "react-select/async";
import getApiClient from "common/ApiClientFactory";

type ContactPersonSelectProps = {
  onChange: (selection: ContactPersonDto) => void;
};

const ContactPersonSelect = (props: ContactPersonSelectProps) => {
  const handleSelection = (selection: any) => {
    if (!selection) {
      props.onChange(undefined);
    } else {
      props.onChange(selection.dto);
    }
  };

  return (
    <AsyncSelect
      defaultOptions
      loadOptions={lookupContactPeople}
      onChange={handleSelection}
      isClearable
      placeholder="Select contact person..."
    />
  );
};

const lookupContactPeople = (inputValue: string) => {
  return new Promise((resolve) => {
    let request = new GetContactPeopleRequest();
    request.search = inputValue;
    request.pageSize = 25;
    request.orderBy = "LastName,FirstName";

    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.rows.map((serviceCenter) => {
        return createOptionFromDto(serviceCenter);
      });

      resolve(options);
    });
  });
};

const createOptionFromDto = (dto: ContactPersonDto) => {
  if (!dto || !dto.id) {
    return undefined;
  }

  return {
    value: dto.id,
    label: `${dto.lastName}, ${dto.firstName} (${dto.email})`,
    dto: dto,
  };
};

export default ContactPersonSelect;
