import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CertificateDto,
  DeleteCertificate,
  GetCertificate,
  KeyInterface,
  KeySystem,
  PutCertificate,
} from "api";
import classNames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { getBase64 } from "common/utils";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

type CertificateProps = {
  BelongsTo: string;
  KeyInterface: KeyInterface;
  KeySystem: KeySystem;
  Number: number;
};

const CertificateUpload = (props: CertificateProps) => {
  const getId = () => {
    return `certificates/${props.BelongsTo}/${props.KeySystem}/${props.KeyInterface}/${props.Number}`;
  };

  let id = getId();

  const [certificate, setCertificate] = useState<CertificateDto>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function async() {
      const client = getApiClient();

      let request = new GetCertificate();
      request.id = id;

      try {
        let certificate = await client.get(request);

        setCertificate(certificate);
      } catch {
        setCertificate(null);
      } finally {
        setLoading(false);
      }
    }

    async();
  }, [id]);

  const onFileChange = async (event) => {
    let base64 = await getBase64(event.target.files[0]);

    let client = getApiClient();
    let request = new PutCertificate();

    request.belongsTo = props.BelongsTo;
    request.interface = props.KeyInterface;
    request.system = props.KeySystem;
    request.dataBase64 = base64;
    request.number = props.Number;

    try {
      let certificate = await client.put(request);
      toast.success("file uploaded");
      setCertificate(certificate);
    } catch {
      toast.error("error while uploading file");
    }
  };

  const deleteCertificate = async (event) => {
    let client = getApiClient();
    let request = new DeleteCertificate();
    request.id = getId();

    setLoading(true);

    try {
      await client.delete(request);
      setCertificate(null);
    } catch {
      toast.error("error while deleting file");
    } finally {
      setLoading(false);
    }
  };

  const isValid =
    certificate &&
    new Date(certificate.notBefore) < new Date() &&
    new Date(certificate.notAfter) > new Date();

  return (
    <>
      <div className="">
        <small className="text-uppercase font-weight-bold">
          <strong>{props.KeyInterface.replace("_", "-")}</strong>{" "}
          {props.KeySystem.toUpperCase()} #{props.Number}
        </small>
      </div>
      {!loading && (
        <>
          {certificate && (
            <>
              <div style={{ lineHeight: "0.9" }}>
                <code
                  className={classNames({
                    "text-success": isValid,
                    "text-warning": !isValid,
                  })}
                >
                  <span className="text-default">
                    <strong>Subject</strong> {certificate.subject}
                  </span>
                  <br />
                  <span>
                    <strong>Not Before</strong>{" "}
                    {new Date(certificate.notBefore).toDateString()}
                  </span>
                  <br />
                  <span>
                    <strong>Not After</strong>{" "}
                    {new Date(certificate.notAfter).toDateString()}
                  </span>
                </code>
              </div>
              <div>
                <Button
                  className="mt-2 mb-4"
                  color="danger"
                  size="sm"
                  onClick={deleteCertificate}
                >
                  <FontAwesomeIcon icon={faTrashAlt} /> Delete Certificate
                </Button>
              </div>
            </>
          )}

          {!certificate && (
            <>
              <input type="file" onChange={onFileChange} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CertificateUpload;
