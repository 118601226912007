import React from "react";
import { Container, Col } from "reactstrap";
import Hero from "../../components/Hero";

const RequestComplete = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container className="mb-5">
          <Col md="12">
            <h4>Request sent</h4>
            <p>
              Thank you for you request. Your request will be checked by an
              administrator.
            </p>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default RequestComplete;
