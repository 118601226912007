import { CreateTerminalRange } from "api";
import getApiClient from "common/ApiClientFactory";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

type PropType = {
  serviceCenterId: string;
  onChange: () => void;
};

type FormData = {
  description: string;
  count: number;
};

const RequestTerminalRange = (props: PropType) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      count: 1,
      description: "",
    },
  });

  const onSubmit = (data: FormData) => {
    let client = getApiClient();

    let request = new CreateTerminalRange();
    request.description = data.description;
    request.serviceCenterId = props.serviceCenterId;
    request.count = data.count;

    client.post(request).then(() => {
      reset();

      if (request.count > 1) {
        toast.success("Terminal Range requested");
      } else {
        toast.success("Terminal Range created");
      }

      reset({ count: 1, description: "" });
      props.onChange();
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 mt-4">
          <small className="text-uppercase font-weight-bold">
            New Terminal Range
          </small>
        </div>
        <Row>
          <Col md={5}>
            <FormGroup>
              <Label for="description">Description</Label>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="description"
                    placeholder="Description"
                    type="text"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="count">Count</Label>
              <Controller
                control={control}
                name="count"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="count"
                    placeholder="Count"
                    type="number"
                    min="1"
                    invalid={!!errors.count}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" color="success">
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default RequestTerminalRange;
