import { yupResolver } from "@hookform/resolvers/yup";
import { GetPublicKeys, UpdatePublicKey } from "api";
import getApiClient from "common/ApiClientFactory";
import KeyInputs from "components/KeyInputs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { dtoToFormData, keyShape } from "validation/key";
import { InferType, object } from "yup";

const formSchema = object({
  siConfTest: object(keyShape),
  siConfProd: object(keyShape),
});

type FormData = InferType<typeof formSchema>;

const ServiceCenterKeys = ({
  serviceCenterId,
}: {
  serviceCenterId: string;
}) => {
  const request = new GetPublicKeys({ targetId: serviceCenterId });

  const { data, isLoading } = useQuery(
    [request],
    () => {
      return getApiClient().get(request);
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  if (isLoading) {
    return null;
  }

  const siConfTest = data.find(
    (p) => p.system === "Test" && p.interface === "SI_CONF"
  );
  const siConfProd = data.find(
    (p) => p.system === "Prod" && p.interface === "SI_CONF"
  );

  const formData = {
    siConfTest: dtoToFormData(siConfTest),
    siConfProd: dtoToFormData(siConfProd),
  } as FormData;

  return (
    <ServiceCenterKeysForm serviceCenterId={serviceCenterId} keys={formData} />
  );
};

const ServiceCenterKeysForm = ({
  serviceCenterId,
  keys,
}: {
  serviceCenterId: string;
  keys: FormData;
}) => {
  const methods = useForm<FormData>({
    defaultValues: keys,
    mode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: FormData) => {
    let api = getApiClient();

    let requests = [
      new UpdatePublicKey({
        belongsTo: serviceCenterId,
        interface: "SI_CONF",
        system: "Test",
        valuePre630: data.siConfTest.valuePre630,
        value630: data.siConfTest.value630,
        valuePost630: data.siConfTest.valuePost630,
        valuePost7402K: data.siConfTest.valuePost7402K,
        valuePost7403K: data.siConfTest.valuePost7403K,
        valuePost7404K: data.siConfTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: serviceCenterId,
        interface: "SI_CONF",
        system: "Prod",
        valuePre630: data.siConfProd.valuePre630,
        value630: data.siConfProd.value630,
        valuePost630: data.siConfProd.valuePost630,
        valuePost7402K: data.siConfProd.valuePost7402K,
        valuePost7403K: data.siConfProd.valuePost7403K,
        valuePost7404K: data.siConfProd.valuePost7404K,
      }),
    ];

    let promises = requests.map((m) => api.put(m));
    Promise.all(promises).then(() => toast.success("Keys updated"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <KeyInputs
          interface1="SI_CONF"
          testKey="siConfTest"
          prodKey="siConfProd"
        />
        <Row className="mb-4 mt-4">
          <Col>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

export default ServiceCenterKeys;
