import {
  createMutations,
  createSimpluxModule,
  createSelectors,
} from "@simplux/core";

const emailverificationModule = createSimpluxModule({
  name: "emailverification",
  initialState: {
    requestSent: false,
    emailVerficationError: false,
    emailVerificationSuccess: false,
  },
});

export const emailverification = {
  ...emailverificationModule,
  ...createMutations(emailverificationModule, {
    setRequestSent(state) {
      state.requestSent = true;
    },
    setError(state) {
      state.emailVerficationError = true;
    },
    setSuccess(state) {
      state.emailVerificationSuccess = true;
    },
  }),
  ...createSelectors(emailverificationModule, {
    requestSent: ({ requestSent }) => requestSent,
    emailVerficationError: ({ emailVerficationError }) => emailVerficationError,
    emailVerificationSuccess: ({ emailVerificationSuccess }) =>
      emailVerificationSuccess,
  }),
};
