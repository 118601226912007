import React from "react";
import Hero from "components/Hero";
import MerchantRangesTable from "./MerchantRangesTable";

const MerchantRanges = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <MerchantRangesTable />
      </section>
    </>
  );
};

export default MerchantRanges;
