import React, { useState } from "react";
import { RequestDto, ApproveCompanyRequest } from "api";
import {
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faPhone,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import CompanySelect from "components/CompanySelect";
import ContactPersonSelect from "components/ContactPersonSelect";
import { toDateTime } from "common/date";
import { toast } from "react-toastify";
import getApiClient from "common/ApiClientFactory";
import ApprovalState from "./ApprovalState";
import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";

type CompanyRequestProps = {
  request: RequestDto;
  dataChanged: () => void;
};

const CompanyRequest = (props: CompanyRequestProps) => {
  const [createNewCompany, setCreateNewCompany] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [createNewContactPerson, setCreateNewContactPerson] = useState(true);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);

  const isAdmin = useSimplux(user.isAdmin);

  const dispatch = (approve: boolean) => {
    let request = new ApproveCompanyRequest();
    request.requestId = props.request.id;
    request.approve = approve;

    if (approve) {
      request.createNewCompany = createNewCompany;

      if (!createNewCompany) {
        if (!selectedCompany || !selectedCompany.id) {
          toast.error("Select a company");
          return;
        }

        request.existingCompanyId = selectedCompany.id;
      }

      request.createNewContactPerson = createNewContactPerson;

      if (!createNewContactPerson) {
        if (!selectedContactPerson || !selectedContactPerson.id) {
          toast.error("Select a contact person");
          return;
        }

        request.existingContactPersonId = selectedContactPerson.id;
      }
    }

    let client = getApiClient();
    client.post(request).then(() => {
      if (approve) {
        toast.success("approved");
      } else {
        toast.success("rejected");
      }

      props.dataChanged();
    });
  };

  return (
    <>
      <Row className="mb-4">
        <Col lg="8">
          <div className="col-lg-12">
            <div className=" shadow border-0 card">
              <div className="py-5 card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="text-primary text-uppercase">
                      <FontAwesomeIcon icon={faIndustry} /> Company
                    </h6>
                  </div>
                  <div>
                    {props.request.done && (
                      <ApprovalState approved={props.request.approved} />
                    )}
                    {toDateTime(props.request.createdAt)}
                  </div>
                </div>
                <Container fluid>
                  <Row>
                    <Col md="4">
                      <address>
                        <strong>{props.request.company.name}</strong>
                        <br />
                        {props.request.company.street1}
                        <br />
                        {props.request.company.street2}
                        {props.request.company.street2 && <br />}
                        {props.request.company.postCode}{" "}
                        {props.request.company.city}
                        <br />
                        {props.request.company.country}
                        <br />
                      </address>
                    </Col>
                    <Col md="4">
                      <strong>Contact Person</strong>
                      <br />
                      {props.request.contactPerson.firstName}{" "}
                      {props.request.contactPerson.lastName}
                      <br />
                      <FontAwesomeIcon icon={faPhone} />{" "}
                      {props.request.contactPerson.phone}
                      <br />
                      <FontAwesomeIcon icon={faMailBulk} />{" "}
                      {props.request.contactPerson.email}
                      <br />
                    </Col>
                    <Col md="4">
                      <strong>Requester</strong>
                      <br />
                      {props.request.requester.firstName}{" "}
                      {props.request.requester.lastName}
                      <br />
                      <FontAwesomeIcon icon={faPhone} />{" "}
                      {props.request.requester.phone}
                      <br />
                      <FontAwesomeIcon icon={faMailBulk} />{" "}
                      {props.request.requester.email}
                      <br />
                    </Col>
                  </Row>
                  {!props.request.done && isAdmin && (
                    <>
                      <Row>
                        <Col md="4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="createNewCompany"
                                defaultChecked={true}
                                onChange={() => {
                                  setCreateNewCompany(true);
                                  setSelectedCompany(null);
                                }}
                              />
                              Create new Company
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="createNewCompany"
                                onChange={() => setCreateNewCompany(false)}
                              />
                              Choose existing
                            </Label>
                          </FormGroup>
                          {!createNewCompany && (
                            <CompanySelect
                              onChange={(company) =>
                                setSelectedCompany(company)
                              }
                            />
                          )}
                        </Col>
                        <Col md="4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="createNewContactPerson"
                                defaultChecked={true}
                                onChange={() => {
                                  setCreateNewContactPerson(true);
                                  setSelectedContactPerson(null);
                                }}
                              />
                              Create new Contact Person
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="createNewContactPerson"
                                onChange={() =>
                                  setCreateNewContactPerson(false)
                                }
                              />
                              Choose existing
                            </Label>
                          </FormGroup>
                          {!createNewContactPerson && (
                            <ContactPersonSelect
                              onChange={(contactPerson) =>
                                setSelectedContactPerson(contactPerson)
                              }
                            />
                          )}
                        </Col>
                        <Col md="4"></Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            color="success"
                            onClick={() => dispatch(true)}
                          >
                            Approve
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => dispatch(false)}
                          >
                            Reject
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CompanyRequest;
