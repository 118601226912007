import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  InputGroupText,
  Input,
  InputGroupAddon,
  FormGroup,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { user } from "./user.module";
import { Link, Redirect } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { signIn, checkCurrentUser } from "../../common/userFunctions";
import { toast } from "react-toastify";

type FormData = {
  email: string;
  password: string;
};

function Login() {
  const isLoggedIn = useSimplux(user.isLoggedIn);

  useEffect(() => {
    checkCurrentUser();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      toast.success("Logged in!", { autoClose: 1500 });
    }
  }, [isLoggedIn]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <>
      {isLoggedIn && <Redirect to="/" />}

      <Container className="full-height">
        <div className="loading-block pb-5">
          <div className="text-center">
            <img
              width="200"
              alt="logo"
              src={require("assets/img/brand/logo.png")}
              className="mb-4"
            />
          </div>
          <div className="text-center">
            <Form role="form" onSubmit={handleSubmit(signIn)}>
              Please sign in:
              <Row className="mt-3">
                <Col md={{ size: 6, offset: 3 }}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: "Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address",
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            placeholder="Email"
                            type="text"
                            {...field}
                            invalid={true}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={{ size: 6, offset: 3 }}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      name="password"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          {...field}
                          invalid={!!errors.password}
                        />
                      )}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 6, offset: 3 }}>
                  <Link to="/register" className="float-left">
                    <Button color="secondary" size="sm" type="button">
                      Register
                    </Button>
                  </Link>
                  <Link to="/forgot-password" className="float-right">
                    <Button color="secondary" size="sm" type="button">
                      Lost password?
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Login;
