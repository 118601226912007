import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  InputGroup,
  InputGroupText,
  Input,
  InputGroupAddon,
} from "reactstrap";
import {
  PagedResponse,
  ContactPersonDto,
  GetContactPeopleRequest,
} from "../api";
import getApiClient from "../common/ApiClientFactory";

type SearchContactPersonTableProps = {
  action: (contactPersonId: ContactPersonDto) => void;
};

const SearchContactPersonTable = (props: SearchContactPersonTableProps) => {
  const [response, setResponse] = useState<PagedResponse<ContactPersonDto>>();
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetContactPeopleRequest();
      request.page = 1;
      request.pageSize = 20;
      request.search = search;
      request.orderBy = "LastName";

      let response = await client.get(request);

      setResponse(response);
    }

    if (search) {
      async();
    } else {
      setResponse(new PagedResponse<ContactPersonDto>());
    }
  }, [search]);

  return (
    <>
      <InputGroup className="mb-4">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="ni ni-zoom-split-in" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Search to add more"
          type="text"
          onChange={(event) => setSearch(event.target.value)}
        />
      </InputGroup>

      {response && response.totalRows > 0 && (
        <Table className="align-items-center table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">E-Mail</th>
              <th scope="col">Phone</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {response?.rows?.map((dto, i) => {
              return (
                <tr key={dto.id}>
                  <td>{dto.firstName}</td>
                  <td>{dto.lastName}</td>
                  <td>{dto.email}</td>
                  <td>{dto.phone}</td>
                  <td>
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => props.action(dto)}
                    >
                      Add
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default SearchContactPersonTable;
