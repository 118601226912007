import React, { useEffect } from "react";
import Hero from "components/Hero";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { serviceCenter } from "pages/admin/service-center/service-center.module";
import { useSimplux } from "@simplux/react";
import getApiClient from "common/ApiClientFactory";
import {
  GetServiceCentersRequest,
  ServiceCenterDto,
  DisableRequest,
  CompanyDto,
} from "api";
import ContactPersonDisplay from "components/ContactPersonDisplay";
import CompanyDisplay from "components/CompanyDisplay";
import { removeCollectionPrefix } from "common/utils";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";

const MyServiceCenters = () => {
  const rows = useSimplux(serviceCenter.rows);

  useEffect(() => {
    fetchServiceCenters();
  }, []);

  const disable = async (serviceCenter: ServiceCenterDto) => {
    let confirmed = await confirm({
      title: "Disabling",
      message: (
        <>
          <span>
            Please confirm disabling Service Center{" "}
            <Badge color="primary">{serviceCenter.serviceCenterId}</Badge>{" "}
            <strong>{serviceCenter.description}.</strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DisableRequest();
      request.id = serviceCenter.id;
      request.disable = true;

      let client = getApiClient();
      await client.post(request);
      toast.success("disabled");

      fetchServiceCenters();
    }
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="d-flex justify-content-between">
            <h3>Service Centers</h3>
          </div>
          <Row>
            <Col>
              <ListGroup className="mb-2">
                {rows?.map((serviceCenter) => {
                  return (
                    <ListGroupItem key={serviceCenter.id}>
                      <div className="d-flex justify-content-between">
                        <div className="flex-fill">
                          <h5 className="mb-1">
                            <Link
                              to={`/service-centers/${removeCollectionPrefix(
                                serviceCenter.id
                              )}`}
                              className="mr-1"
                            >
                              {serviceCenter.serviceCenterId}
                            </Link>
                          </h5>
                          <p className="mb-1">{serviceCenter.description}</p>
                        </div>
                        <div className="px-2 listview-block">
                          <CompanyDisplay
                            dto={serviceCenter.company as CompanyDto}
                          />
                        </div>
                        <div className="px-2 listview-block">
                          <ContactPersonDisplay
                            dto={serviceCenter.company.primaryContactPerson}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div>
                            <small>
                              <Link
                                to={`/service-centers/${removeCollectionPrefix(
                                  serviceCenter.id
                                )}`}
                              >
                                <Button size="sm" color="primary">
                                  Manage
                                </Button>
                              </Link>
                            </small>
                          </div>
                          <div>
                            <small>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() =>
                                  disable(serviceCenter as ServiceCenterDto)
                                }
                              >
                                Disable
                              </Button>
                            </small>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  );
                })}
                {rows?.length === 0 && (
                  <ListGroupItem>
                    No service center IDs are assigned to you. Request one
                    below.
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Link to="/request/service-center-id">
                <Button color="success">Request Service Center</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

async function fetchServiceCenters() {
  let client = getApiClient();
  let request = new GetServiceCentersRequest();
  request.page = 1;
  request.pageSize = 133337;
  request.orderBy = "ServiceCenterId";

  let response = await client.get(request);

  serviceCenter.fromResponse(response);
}

export default MyServiceCenters;
