import React from "react";
import { Table } from "reactstrap";
import { useSimplux } from "@simplux/react";
import { CommunicationListDto } from "api";
import { search } from "./search.module";
import OrderByToggle from "components/OrderByToggle";
import { removeCollectionPrefix, communicationTypeDisplay } from "common/utils";
import { Link } from "react-router-dom";

const CommunicationsTable = ({
  rows,
  shouldRefetch,
}: {
  rows: CommunicationListDto[];
  shouldRefetch: () => void;
}) => {
  const orderBy = useSimplux(search.orderBy);

  return (
    <Table className="align-items-center table-sm table-hover">
      <thead>
        <tr>
          <th scope="col">
            <OrderByToggle
              field="Company"
              text="Company"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="System"
              text="System"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="EntityType"
              text="Owning Entity"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="EntityId"
              text="Owning Entity ID"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="Interface"
              text="Interface"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="Type"
              text="Type"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="Host"
              text="Host"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="Port"
              text="Port"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((dto) => {
          return (
            <tr key={dto.id}>
              <td>
                {dto.companyId && (
                  <Link
                    to={`/search/companies/${removeCollectionPrefix(
                      dto.companyId
                    )}`}
                  >
                    {dto.company}
                  </Link>
                )}
              </td>
              <td>{dto.system}</td>
              <td>{dto.entityType}</td>
              <td>
                <Link to={getBelongsToLink(dto)}>{dto.entityId ?? "-"}</Link>
              </td>
              <td>{dto.interface}</td>
              <td>{communicationTypeDisplay(dto.type)}</td>
              <td>{dto.host}</td>
              <td>{dto.port}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CommunicationsTable;

const getBelongsToLink = (dto: CommunicationListDto) => {
  switch (dto.entityType) {
    case "Acquirer":
      return `/acquirers/${removeCollectionPrefix(
        removeCollectionPrefix(dto.belongsTo)
      )}`;
    case "ServiceCenter":
      return `/service-centers/${removeCollectionPrefix(
        removeCollectionPrefix(dto.belongsTo)
      )}`;
    case "Pms":
      return `/pms/${removeCollectionPrefix(
        removeCollectionPrefix(dto.belongsTo)
      )}`;

    default:
      return "";
  }
};
