import {
  createMutations,
  createSimpluxModule,
  createSelectors,
} from "@simplux/core";
import {
  ServiceCenterDto,
  MerchantRangeDto,
  PagedResponse,
  TerminalRangeDto,
  CompanyDto,
  TerminalSupplierDto,
} from "../../../api";
import { isNumber } from "util";

const serviceCenterDetailModule = createSimpluxModule({
  name: "serviceCenterDetail",
  initialState: {
    dto: new ServiceCenterDto(),
    selectedCompany: undefined as any,
    activeTab: "merchant-ranges",
    merchantRangesResponse: new PagedResponse<MerchantRangeDto>(),
    merchantRangesPageSize: 25,
    merchantRangesPage: 1,
    merchantRangesSearch: "",
    terminalRangesResponse: new PagedResponse<TerminalRangeDto>(),
    terminalRangesPageSize: 25,
    terminalRangesPage: 1,
    terminalRangesSearch: "",
    terminalSuppliersResponse: new PagedResponse<TerminalSupplierDto>(),
    terminalSuppliersPageSize: 25,
    terminalSuppliersPage: 1,
    terminalSuppliersSearch: "",
  },
});

export const serviceCenterDetail = {
  ...serviceCenterDetailModule,
  ...createMutations(serviceCenterDetailModule, {
    setServiceCenter(state, serviceCenter: ServiceCenterDto) {
      state.dto = serviceCenter;
    },
    setMerchantRanges(state, merchantRanges: PagedResponse<MerchantRangeDto>) {
      state.merchantRangesResponse = merchantRanges;
    },
    setMerchantRangesPage(state, page: number) {
      state.merchantRangesPage = page;
    },
    setMerchantRangesSearch(state, search: string) {
      state.merchantRangesPage = 1;
      state.merchantRangesSearch = search;
    },
    setMerchantRangesPageSize(state, pageSize: number) {
      state.merchantRangesPage = 1;
      state.merchantRangesPageSize = pageSize;
    },
    setTerminalRanges(state, terminalRanges: PagedResponse<TerminalRangeDto>) {
      state.terminalRangesResponse = terminalRanges;
    },
    setTerminalRangesPage(state, page: number) {
      state.terminalRangesPage = page;
    },
    setTerminalRangesSearch(state, search: string) {
      state.terminalRangesPage = 1;
      state.terminalRangesSearch = search;
    },
    setTerminalRangesPageSize(state, pageSize: number) {
      state.terminalRangesPage = 1;
      state.terminalRangesPageSize = pageSize;
    },
    setTerminalSuppliers(
      state,
      terminalSuppliers: PagedResponse<TerminalSupplierDto>
    ) {
      state.terminalSuppliersResponse = terminalSuppliers;
    },
    setTerminalSuppliersPage(state, page: number) {
      state.terminalSuppliersPage = page;
    },
    setTerminalSuppliersSearch(state, search: string) {
      state.terminalSuppliersPage = 1;
      state.terminalSuppliersSearch = search;
    },
    setTerminalSuppliersPageSize(state, pageSize: number) {
      state.terminalSuppliersPage = 1;
      state.terminalSuppliersPageSize = pageSize;
    },
    reset(state) {
      state.dto = new ServiceCenterDto();
    },
    setActiveTab(state, activeTab: string) {
      state.activeTab = activeTab;
    },
    setCompany(state, company: CompanyDto) {
      state.dto.company = company;
    },
    clearCompany(state) {
      state.dto.company = undefined;
    },
  }),
  ...createSelectors(serviceCenterDetailModule, {
    dto: ({ dto }) => dto,
    activeTab: ({ activeTab }) => activeTab,
    merchantRangesResponse: ({ merchantRangesResponse }) =>
      merchantRangesResponse,
    merchantRangesPageSize: ({ merchantRangesPageSize }) =>
      merchantRangesPageSize,
    merchantRangesPage: ({ merchantRangesPage }) => merchantRangesPage,
    merchantRangesSearch: ({ merchantRangesSearch }) => merchantRangesSearch,
    merchantRangesPages: ({ merchantRangesResponse }) => {
      if (
        merchantRangesResponse &&
        isNumber(merchantRangesResponse.totalRows) &&
        isNumber(merchantRangesResponse.pageSize)
      ) {
        return Math.ceil(
          merchantRangesResponse.totalRows / merchantRangesResponse.pageSize
        );
      }

      return 1;
    },
    terminalRangesResponse: ({ terminalRangesResponse }) =>
      terminalRangesResponse,
    terminalRangesPageSize: ({ terminalRangesPageSize }) =>
      terminalRangesPageSize,
    terminalRangesPage: ({ terminalRangesPage }) => terminalRangesPage,
    terminalRangesSearch: ({ terminalRangesSearch }) => terminalRangesSearch,
    terminalRangesPages: ({ terminalRangesResponse }) => {
      if (
        terminalRangesResponse &&
        isNumber(terminalRangesResponse.totalRows) &&
        isNumber(terminalRangesResponse.pageSize)
      ) {
        return Math.ceil(
          terminalRangesResponse.totalRows / terminalRangesResponse.pageSize
        );
      }

      return 1;
    },
    terminalSuppliersResponse: ({ terminalSuppliersResponse }) =>
      terminalSuppliersResponse,
    terminalSuppliersPageSize: ({ terminalSuppliersPageSize }) =>
      terminalSuppliersPageSize,
    terminalSuppliersPage: ({ terminalSuppliersPage }) => terminalSuppliersPage,
    terminalSuppliersSearch: ({ terminalSuppliersSearch }) =>
      terminalSuppliersSearch,
    terminalSuppliersPages: ({ terminalRangesResponse }) => {
      if (
        terminalRangesResponse &&
        isNumber(terminalRangesResponse.totalRows) &&
        isNumber(terminalRangesResponse.pageSize)
      ) {
        return Math.ceil(
          terminalRangesResponse.totalRows / terminalRangesResponse.pageSize
        );
      }

      return 1;
    },
  }),
};
