import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  Button,
  InputGroupAddon,
} from "reactstrap";
import { acquirers } from "./acquirer.module";
import { useSimplux } from "@simplux/react";
import { GetAcquirersRequest } from "api";
import { toDate } from "common/date";
import getApiClient from "common/ApiClientFactory";
import OrderByToggle from "components/OrderByToggle";
import { Link, useHistory } from "react-router-dom";
import Pagination from "components/pagination/paginationInternal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import Disabler from "components/Disabler";
import { copyToClipboard, to11 } from "common/utils";

const AcquirerTable = () => {
  const rows = useSimplux(acquirers.rows);
  const page = useSimplux(acquirers.page);
  const pageSize = useSimplux(acquirers.pageSize);
  const search = useSimplux(acquirers.search);
  const total = useSimplux(acquirers.total);
  const orderBy = useSimplux(acquirers.orderBy);
  const pages = useSimplux(acquirers.pages);

  const history = useHistory();

  useEffect(() => {
    fetchData(page, pageSize, search, orderBy);
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Acquirers</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) => acquirers.setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            value={pageSize}
            onChange={(event) =>
              acquirers.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>
          <Button
            color="primary"
            onClick={() => history.push("/admin/acquirers/new")}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm acquirers">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="AcquirerId"
                text="Acquirer ID"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="CompanyName"
                text="Company"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Description"
                text="Description"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="RequestDate"
                text="Request Date"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="AcceptDate"
                text="Accept Date"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="DisableDate"
                text="Disable Date"
                orderBy={orderBy}
                onChange={(orderBy) => acquirers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto, i) => {
            return (
              <tr key={dto.id}>
                <td>
                  <Badge
                    color="primary"
                    className="pointer"
                    onClick={() => copyToClipboard(dto.acquirerId?.toString())}
                  >
                    {to11(dto.acquirerId)}
                  </Badge>
                </td>
                <td>
                  {dto.company && dto.company.id && (
                    <Link to={`/${dto.company.id}`}>{dto.company.name}</Link>
                  )}
                </td>
                <td>{dto.description}</td>
                <td>{toDate(dto.requestDate)}</td>
                <td>{toDate(dto.acceptDate)}</td>
                <td>
                  <Disabler
                    disabledDate={dto.disableDate}
                    targetId={dto.id}
                    onUpdated={() => fetchData(page, pageSize, search, orderBy)}
                    id={to11(dto.acquirerId).toString()}
                    description={dto.description}
                  />
                </td>
                <td>
                  <Link to={`/admin/${dto.id.toLowerCase()}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => acquirers.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  orderBy: string
) {
  let client = getApiClient();
  let request = new GetAcquirersRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = orderBy;
  let response = await client.get(request);

  acquirers.fromResponse(response);
}

export default AcquirerTable;
