import React, { useEffect, useState } from "react";
import Hero from "components/Hero";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import getApiClient from "common/ApiClientFactory";
import { GetTerminalSuppliersRequest, TerminalSupplierDto } from "api";
import { removeCollectionPrefix } from "common/utils";

const MyTerminalSuppliers = () => {
  const [terminalSuppliers, setTerminalSuppliers] =
    useState<TerminalSupplierDto[]>();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetTerminalSuppliersRequest();

      let response = await client.get(request);

      setTerminalSuppliers(response.rows);
    }

    async();
  }, []);

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="mb-3">
            <small className="text-uppercase font-weight-bold">
              Terminal Suppliers
            </small>
          </div>
          <Row>
            <Col>
              <ListGroup className="mb-2">
                {terminalSuppliers?.map((terminalSupplier) => {
                  const detailLink = `/terminal-suppliers/${removeCollectionPrefix(
                    terminalSupplier.id
                  )}`;

                  return (
                    <ListGroupItem key={terminalSupplier.id}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">
                          <Link to={detailLink} className="mr-1">
                            {terminalSupplier.terminalSupplierId}
                          </Link>
                        </h5>
                        <small>
                          <Link to={detailLink}>
                            <Button size="sm" color="primary">
                              Manage
                            </Button>
                          </Link>
                        </small>
                      </div>
                      <p className="mb-1">{terminalSupplier.description}</p>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyTerminalSuppliers;
