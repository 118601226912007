import React from "react";
import Hero from "components/Hero";
import KernelsTable from "./KernelsTable";

const Kernels = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <KernelsTable />
      </section>
    </>
  );
};

export default Kernels;
