import React, { useState } from "react";
import { RequestDto, ApproveTerminalRangeRequest } from "api";
import { Col, Container, Row, Button, Badge, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMailBulk,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { toDateTime } from "common/date";
import ApprovalState from "./ApprovalState";
import getApiClient from "common/ApiClientFactory";
import { toast } from "react-toastify";
import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";
import { removeCollectionPrefix } from "common/utils";
import { Link } from "react-router-dom";

type PropTypes = {
  request: RequestDto;
  dataChanged: () => void;
};

const TerminalRangeRequest = (props: PropTypes) => {
  const isAdmin = useSimplux(user.isAdmin);

  const [useCustomId, setUseCustomId] = useState(false);
  const [customId, setCustomId] = useState(props.request.nextId);

  const dispatch = (approve: boolean) => {
    let request = new ApproveTerminalRangeRequest();
    request.approve = approve;
    request.requestId = props.request.id;

    if (useCustomId) {
      request.start = customId;
    }

    let client = getApiClient();
    client
      .post(request)
      .then(() => {
        if (approve) {
          toast.success("approved");
        } else {
          toast.success("rejected");
        }

        props.dataChanged();
      })
      .catch(() => {
        toast.error("Error");
      });
  };

  return (
    <>
      <Row className="mb-4">
        <Col lg="8">
          <div className="col-lg-12">
            <div className=" shadow border-0 card">
              <div className="py-5 card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="text-primary text-uppercase">
                      <i className="ni ni-building"></i> Terminal Range
                    </h6>
                  </div>
                  <div>
                    {props.request.done && (
                      <ApprovalState approved={props.request.approved} />
                    )}
                    {toDateTime(props.request.createdAt)}
                  </div>
                </div>
                <Container fluid>
                  <Row>
                    <Col md="4">
                      <strong>Service Center</strong>
                      <br />
                      <Link
                        to={`/service-centers/${removeCollectionPrefix(
                          props.request.serviceCenter.id
                        )}`}
                      >
                        {props.request.serviceCenter.serviceCenterId}
                      </Link>
                      <br />
                      <br />
                      <strong>Description</strong>
                      <br />
                      {props.request.description}
                      <br />
                      <strong>Count: </strong>
                      {props.request.count}
                    </Col>
                    <Col md="4">
                      <address>
                        <strong>Company</strong>
                        <br />
                        {props.request.company.name}
                        <br />
                        {props.request.company.street1}
                        <br />
                        {props.request.company.street2}
                        {props.request.company.street2 && <br />}
                        {props.request.company.postCode}{" "}
                        {props.request.company.city}
                        <br />
                        {props.request.company.country}
                        <br />
                      </address>
                    </Col>
                    <Col md="4">
                      <strong>Requester</strong>
                      <br />
                      {props.request.requester.firstName}{" "}
                      {props.request.requester.lastName}
                      <br />
                      <FontAwesomeIcon icon={faPhone} />{" "}
                      {props.request.requester.phone}
                      <br />
                      <FontAwesomeIcon icon={faMailBulk} />{" "}
                      {props.request.requester.email}
                      <br />
                    </Col>
                  </Row>
                  {!props.request.done && isAdmin && (
                    <>
                      <Row>
                        <Col md={2}>
                          <div className="custom-control custom-checkbox mt-3">
                            <input
                              className="custom-control-input"
                              id="customCheck1"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                              onClick={() => setUseCustomId(!useCustomId)}
                            >
                              Custom ID
                            </label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <Input
                            type="number"
                            min={1}
                            max={9999}
                            disabled={!useCustomId}
                            defaultValue={props.request.nextId}
                            onChange={(event) =>
                              setCustomId(parseInt(event.target.value))
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            color="success"
                            onClick={() => dispatch(true)}
                          >
                            Approve
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => dispatch(false)}
                          >
                            Reject
                          </Button>
                          {props.request.count && (
                            <>
                              <Badge
                                color="primary"
                                className="float-right text-huge"
                              >
                                {(useCustomId
                                  ? customId
                                  : props.request.nextId) +
                                  props.request.count -
                                  1}
                              </Badge>
                              <FontAwesomeIcon
                                icon={faMinus}
                                className="float-right text-huge m-2"
                              />
                            </>
                          )}
                          <Badge
                            color="primary"
                            className="float-right text-huge"
                          >
                            {useCustomId ? customId : props.request.nextId}
                          </Badge>
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TerminalRangeRequest;
