import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
} from "@fortawesome/free-solid-svg-icons";

type SortableTextProps = {
  text: string;
  field: string;
  orderBy: string;
  isNumeric?: boolean;
  onChange: (orderBy: string) => void;
};

const OrderByToggle = (props: SortableTextProps) => {
  const toggle = () => {
    const newOrderBy = updateSort(props.orderBy, props.field);
    props.onChange(newOrderBy);
  };

  let icon: JSX.Element = null;

  if (!props.orderBy) {
  } else if (props.orderBy.indexOf(`-${props.field}`) !== -1) {
    if (props.isNumeric) {
      icon = <FontAwesomeIcon icon={faSortNumericUp} className="text-light" />;
    } else {
      icon = <FontAwesomeIcon icon={faSortAlphaUp} className="text-light" />;
    }
  } else if (props.orderBy.indexOf(`${props.field}`) !== -1) {
    if (props.isNumeric) {
      icon = (
        <FontAwesomeIcon icon={faSortNumericDown} className="text-light" />
      );
    } else {
      icon = <FontAwesomeIcon icon={faSortAlphaDown} className="text-light" />;
    }
  }

  return (
    <>
      <span onClick={toggle} className="pointer mr-1">
        {props.text}
      </span>
      {icon}
    </>
  );
};

export default OrderByToggle;

const updateSort = (orderBy: string, field: string) => {
  /* sort order cycle: undefined --> field --> -field --> undefined */

  /* if orderBy is null or undefined, we set it to the current field */
  if (orderBy === undefined || orderBy === null) {
    orderBy = field;
  } else {
    /* let's first extract the sort order and clean the orderBy field */
    var descending = orderBy.indexOf("-") === 0;

    orderBy = trimStart(orderBy, "-");

    if (orderBy !== field) {
      /* if orderBy has the value of another field, we set it to the current field */
      orderBy = field;
    } else if (descending) {
      /* Here we know that orderBy has the value of our field.
         If the sort order is descending, we cycle to the next state,
         which is resetting the orderBy to undefined
       */
      orderBy = "";
    } else {
      /* Here we know that orderBy has the value of our field and that the sort order was ascending.
         Therefore we set the new orderBy to descending
       */
      orderBy = "-" + field;
    }
  }

  return orderBy;
};

const trimStart = (target: string, trimText: string) => {
  if (target.startsWith(trimText)) {
    return target.substr(trimText.length, target.length - trimText.length);
  }

  return target;
};
