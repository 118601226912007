import React from "react";
import Hero from "../../../components/Hero";
import AcquirerTable from "./AcquirerTable";

const Acquirers = (props: any) => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <AcquirerTable />
      </section>
    </>
  );
};

export default Acquirers;
