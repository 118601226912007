import React, { useEffect } from "react";

import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Table,
  Badge,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { userManagement } from "./user-management.module";
import getApiClient from "../../../common/ApiClientFactory";
import { GetUsersRequest } from "../../../api";
import { toDateTime } from "../../../common/date";
import { Link } from "react-router-dom";
import Pagination from "../../../components/pagination/paginationInternal";
import ApprovalBadge from "./ApprovalBadge";
import EmailVerificationBadge from "./EmailVerificationBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import OrderByToggle from "components/OrderByToggle";

const roleColor = (role: string) => {
  if (role === "Admin") {
    return "danger";
  }

  return "primary";
};

const UserTable = () => {
  const response = useSimplux(userManagement.response);
  const page = useSimplux(userManagement.page);
  const pageSize = useSimplux(userManagement.pageSize);
  const search = useSimplux(userManagement.search);
  const pages = useSimplux(userManagement.pages);
  const orderBy = useSimplux(userManagement.orderBy);
  const onlyPendingApproval = useSimplux(userManagement.onlyPendingApproval);

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetUsersRequest();
      request.page = page;
      request.pageSize = pageSize;
      request.search = search;
      request.filterPendingApproval = onlyPendingApproval;
      request.orderBy = orderBy;

      let response = await client.get(request);

      userManagement.fromResponse(response);
    }

    async();
  }, [pageSize, search, page, onlyPendingApproval, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Users</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                defaultValue={search}
                text={search}
                onChange={(event) =>
                  userManagement.setSearch(event.target.value)
                }
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            value={pageSize}
            onChange={(event) =>
              userManagement.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>
          <div className="custom-control custom-checkbox mt-2">
            <input
              className="custom-control-input"
              id="onlyPending"
              type="checkbox"
              checked={onlyPendingApproval}
              onChange={() => userManagement.toggleOnlyPendingApproval()}
            />
            <label className="custom-control-label" htmlFor="onlyPending">
              Pending Approval
            </label>
          </div>
        </Col>
        <Col>{`${response?.rows?.length} of ${response?.totalRows}`}</Col>
      </Row>

      <Table className="align-items-center table-hover table-sm">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="FirstName"
                text="First name"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="LastName"
                text="Last name"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Email"
                text="E-Mail"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Roles"
                text="Roles"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="EmailVerificationApproved"
                text="E-Mail"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Approved"
                text="Approval"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="LastLoginAttempt"
                text="Last login attempt"
                orderBy={orderBy}
                onChange={(orderBy) => userManagement.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {response?.rows?.map((dto, i) => {
            const roleTags = dto.roles?.map((role) => (
              <Badge key={role} color={roleColor(role)} className="mr-1">
                {role}
              </Badge>
            ));

            return (
              <tr key={dto.key}>
                <td>{dto.firstName}</td>
                <td>{dto.lastName}</td>
                <td>{dto.email}</td>
                <td>{roleTags}</td>
                <td>
                  <EmailVerificationBadge verification={dto.verification} />
                </td>
                <td>
                  <ApprovalBadge isApproved={dto.approved} userId={dto.key} />
                </td>
                <td>{toDateTime(dto.lastLoginAttempt)}</td>
                <td>
                  <Link to={`/${dto.key}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {(() => {
            if (pages > 1) {
              return (
                <Row>
                  <Col>
                    <Pagination
                      currentPage={page}
                      totalPages={pages}
                      onChange={(page) => userManagement.setPage(page)}
                    />
                  </Col>
                </Row>
              );
            }
          })()}
        </Col>
      </Row>
    </>
  );
};

export default UserTable;
