import { faCertificate, faKey, faTty } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import { CreatePms, GetPMSRequest, PMSDto, UpdatePMSRequest } from "api";
import classnames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix, to11 } from "common/utils";
import CompanySelect from "components/CompanySelect";
import Hero from "components/Hero";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Certificates from "../certificates/Certificates";
import Communications from "../communications/Communications";
import PmsKeys from "./PmsKeys";

const PMSDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  const { id } = useParams<any>();
  const [dto, setDto] = useState<PMSDto>();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<PMSDto>();

  const history = useHistory();

  const [tab, setTab] = useState("public-keys");

  const onSubmit = async (data: PMSDto) => {
    let client = getApiClient();

    if (id !== "new") {
      let request = new UpdatePMSRequest();
      Object.assign(request, data);

      request.id = dto.id;
      request.companyId = data.company?.id;

      client
        .put(request)
        .then(() => {
          toast.success("PMS updated");
        })
        .catch(() => {
          toast.error("Error");
        });
    } else {
      let request = new CreatePms();
      Object.assign(request, data);
      request.companyId = data.company?.id;

      await client
        .post(request)
        .then((response) => {
          history.push(`/admin/pms/${removeCollectionPrefix(response.id)}`);
          toast.success("PMS created");
        })
        .catch(() => {
          toast.error("Error");
        });
    }
  };

  useEffect(() => {
    async function async() {
      const client = getApiClient();

      if (id !== "new") {
        let request = new GetPMSRequest();

        request.id = id;
        let dto = await client.get(request);

        reset(dto);
        setDto(dto);
      } else {
        let o = new PMSDto();
        reset(o);
        setDto(o);
      }
    }

    async();
  }, [id, reset]);

  const Title = () => {
    const pmsId = useWatch({
      control: control,
      name: "pmsId",
    });

    return (
      <h3>
        PMS <small className="text-primary">{to11(pmsId)}</small>
      </h3>
    );
  };

  return (
    <>
      <Hero />
      <section className="section mb-dropdown m-4">
        {dto && (
          <Container className="mb-5">
            <Title />
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Details
                  </small>
                </div>
                {isAdmin && (
                  <Row>
                    <Col lg="6" sm="12">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="pmsId"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              placeholder="Acquirer ID"
                              type="number"
                              {...field}
                              invalid={!!errors.pmsId}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Controller
                        control={control}
                        name="description"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            placeholder="Description"
                            type="text"
                            {...field}
                            invalid={!!errors.description}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {isAdmin && (
                  <>
                    <Row>
                      <Col lg="6" sm="12">
                        <FormGroup>
                          <Controller
                            control={control}
                            name="remarks"
                            render={({ field }) => (
                              <Input
                                placeholder="Remarks"
                                type="text"
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <small className="text-uppercase font-weight-bold">
                        Assigned Company
                      </small>
                    </div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Controller
                            control={control}
                            name="company"
                            render={({ field }) => <CompanySelect {...field} />}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mb-4 mt-2">
                  <Col>
                    <Button type="submit" color="primary">
                      {id === "new" ? "add" : "save"}
                    </Button>
                  </Col>
                </Row>
              </form>
            </>
            {id !== "new" && (
              <>
                <hr />
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: tab === "public-keys" })}
                      onClick={() => setTab("public-keys")}
                    >
                      <FontAwesomeIcon icon={faKey} /> Public Keys
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: tab === "certificates" })}
                      onClick={() => setTab("certificates")}
                    >
                      <FontAwesomeIcon icon={faCertificate} /> Root Certificates
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({
                        active: tab === "communications",
                      })}
                      onClick={() => setTab("communications")}
                    >
                      <FontAwesomeIcon icon={faTty} /> Communication
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={tab} className="pt-3">
                  <TabPane tabId="public-keys">
                    <PmsKeys pmsId={`pms/${id}`} />
                  </TabPane>
                  <TabPane tabId="certificates">
                    <Certificates
                      TargetId={`pms/${id}`}
                      AllowedInterfaces={["BE"]}
                    />
                  </TabPane>
                  <TabPane tabId="communications">
                    <Communications
                      TargetId={`pms/${id}`}
                      AllowedInterfaces={["BE"]}
                    />
                  </TabPane>
                </TabContent>
              </>
            )}
          </Container>
        )}
      </section>
    </>
  );
};

export default PMSDetail;
