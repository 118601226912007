import React from "react";
import Hero from "components/Hero";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert,
} from "reactstrap";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getApiUrl } from "common/ApiClientFactory";
import { useSimplux } from "@simplux/react";
import { user } from "./user/user.module";
import {
  faKey,
  faIndustry,
  faUsers,
  faCertificate,
  faTty,
} from "@fortawesome/free-solid-svg-icons";

const Downloads = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const isAdmin = useSimplux(user.isAdmin);

  if (!isLoggedIn) {
    return (
      <>
        <Hero />
        <section className="section m-4">
          <Container>
            <Row>
              <Col>
                <Alert color="info">Please login</Alert>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container fluid={isAdmin}>
          <div className="mb-3">
            <h3>Downloads</h3>
          </div>
          <Row>
            <Col md={6}>
              <ListGroup>
                <ListGroupItem
                  className="pointer"
                  tag="a"
                  href={`${getApiUrl()}/exports/public-keys`}
                  action
                >
                  <FontAwesomeIcon icon={faKey} />
                  &nbsp; Public Keys
                </ListGroupItem>
                <ListGroupItem
                  className="pointer"
                  tag="a"
                  href={`${getApiUrl()}/exports/id-master`}
                  action
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                  &nbsp; ID Master
                </ListGroupItem>
                <ListGroupItem
                  className="pointer"
                  tag="a"
                  href={`${getApiUrl()}/exports/communication`}
                  action
                >
                  <FontAwesomeIcon icon={faTty} />
                  &nbsp; Communication
                </ListGroupItem>
                <ListGroupItem
                  className="pointer"
                  tag="a"
                  href={`${getApiUrl()}/exports/certificates`}
                  action
                >
                  <FontAwesomeIcon icon={faCertificate} />
                  &nbsp; Certificates
                </ListGroupItem>
                {isAdmin && (
                  <>
                    <ListGroupItem
                      className="pointer"
                      tag="a"
                      href={`${getApiUrl()}/exports/companies`}
                      action
                    >
                      <FontAwesomeIcon icon={faIndustry} />
                      &nbsp; Companies
                    </ListGroupItem>
                    <ListGroupItem
                      className="pointer"
                      tag="a"
                      href={`${getApiUrl()}/exports/users`}
                      action
                    >
                      <FontAwesomeIcon icon={faUsers} />
                      &nbsp; Users
                    </ListGroupItem>
                  </>
                )}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Downloads;
