import getApiClient from "./ApiClientFactory";

import {
  Authenticate,
  RegisterRequest,
  GetCurrentUserRequest,
  VerifyEmailRequest,
  PatchUserRequest,
} from "api";

import { user } from "pages/user/user.module";
import { userManagement } from "pages/admin/user-management/user-management.module";
import { toast } from "react-toastify";

export async function checkCurrentUser() {
  let client = getApiClient();
  let request = new GetCurrentUserRequest();

  let response = await client.get(request);

  if (response.userId) {
    user.setIsLoggedIn(
      response.firstName,
      response.lastName,
      response.userId,
      response.roles,
      response.emailVerified,
      response.approved,
      response.level,
      response.hasCompanyAssigned
    );
  } else {
    user.setIsNotLoggedIn();
  }
}

export async function signIn(data: any) {
  let client = getApiClient();
  let request = new Authenticate();
  request.provider = "credentials";
  request.userName = data.email;
  request.password = data.password;
  request.useTokenCookie = true;

  try {
    await client.post(request);
    let getUserRequest = new GetCurrentUserRequest();
    let userResponse = await client.get(getUserRequest);
    user.setIsLoggedIn(
      userResponse.firstName,
      userResponse.lastName,
      userResponse.userId,
      userResponse.roles,
      userResponse.emailVerified,
      userResponse.approved,
      userResponse.level,
      userResponse.hasCompanyAssigned
    );
  } catch {
    user.setIsNotLoggedIn();
    toast.error("Login failed");
  }
}

export async function logout() {
  let client = getApiClient();
  let request = new Authenticate();
  request.provider = "logout";

  await client.post(request);

  user.setIsNotLoggedIn();

  window.location.href = "/login";
}

export async function registerNewUser(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string
) {
  let client = getApiClient();

  let request = new RegisterRequest();
  request.firstName = firstName;
  request.lastName = lastName;
  request.email = email;
  request.phone = phone;
  request.password = password;

  try {
    await client.post(request);
    user.setIsNotLoggedIn();
    return true;
  } catch (e) {
    if (e.responseStatus && e.responseStatus.message) {
      toast.error(e.responseStatus.message, { autoClose: 8000 });
    } else {
      toast.error("Error");
    }

    user.setIsNotLoggedIn();
    return false;
  }
}

export function verifyEmail(correlationId) {
  let client = getApiClient();

  let request = new VerifyEmailRequest();
  request.correlationId = correlationId;

  return client.post(request).catch(() => {
    toast.error("Error");
  });
}

export function updateApproval(id: string, approval: boolean) {
  let client = getApiClient();

  let request = new PatchUserRequest();
  request.id = id;
  request.approval = approval;

  userManagement.setApproval(id, approval);

  return client.patch(request);
}
