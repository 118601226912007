import { classNames } from "@servicestack/client";
import { useSimplux } from "@simplux/react";
import {
  GetMySubscriptions,
  SubscriptionsDto,
  UpdateMySubscriptions,
} from "api";
import getApiClient from "common/ApiClientFactory";
import Hero from "components/Hero";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { user } from "./user/user.module";

const Notifications = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const isAdmin = useSimplux(user.isAdmin);

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<SubscriptionsDto>();

  useEffect(() => {
    async function async() {
      let request = new GetMySubscriptions();
      const client = getApiClient();

      client
        .get(request)
        .then((r) => {
          reset(r);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    async();
  }, [reset]);

  const onSubmit = (data: SubscriptionsDto) => {
    let request = new UpdateMySubscriptions();
    request.acquirer = data.acquirer;
    request.application = data.application;
    request.email = data.email;
    request.merchantRange = isAdmin ? data.merchantRange : false;
    request.pms = data.pms;
    request.serviceCenter = data.serviceCenter;
    request.terminalRange = isAdmin ? data.terminalRange : false;
    request.terminalSupplier = isAdmin ? data.terminalSupplier : false;
    request.certificates = data.certificates;
    request.communications = data.communications;

    let client = getApiClient();
    client
      .put(request)
      .then((r) => {
        reset(r);
        toast.success("saved");
      })
      .catch(() => {
        toast.error("error");
      });
  };

  if (!isLoggedIn) {
    return (
      <>
        <Hero />
        <section className="section m-4">
          <Container>
            <Row>
              <Col>
                <Alert color="info">Please login</Alert>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container fluid={false}>
          <div className="mb-3">
            <h3>Notifications</h3>
          </div>
          {!loading && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className={classNames({ "has-danger": errors.email })}>
                <Label for="email">Email</Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="email"
                      {...field}
                      placeholder="someone@example.com"
                      type="email"
                      className={classNames({ "is-invalid": errors.email })}
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "invalid email address",
                        },
                      }}
                    />
                  )}
                />
                <FormText>Specify E-Mail to receive notifications</FormText>
              </FormGroup>
              <small className="text-uppercase font-weight-bold">
                Subscribe to updates
              </small>
              <FormGroup check>
                <Controller
                  control={control}
                  name="acquirer"
                  render={({ field }) => (
                    <Input
                      id="acquirer"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="acquirer">
                  Acquirer
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  control={control}
                  name="application"
                  render={({ field }) => (
                    <Input
                      id="application"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="application">
                  Application
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  control={control}
                  name="serviceCenter"
                  render={({ field }) => (
                    <Input
                      id="servicecenter"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="servicecenter">
                  Service Center
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  control={control}
                  name="pms"
                  render={({ field }) => (
                    <Input
                      id="pms"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="pms">
                  PMS
                </Label>
              </FormGroup>
              {isAdmin && (
                <>
                  <FormGroup check>
                    <Controller
                      control={control}
                      name="merchantRange"
                      render={({ field }) => (
                        <Input
                          id="merchantrange"
                          type="checkbox"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          checked={field.value}
                          ref={field.ref}
                        />
                      )}
                    />{" "}
                    <Label check for="merchantrange">
                      Merchant Range
                    </Label>
                  </FormGroup>

                  <FormGroup check>
                    <Controller
                      control={control}
                      name="terminalRange"
                      render={({ field }) => (
                        <Input
                          id="terminalrange"
                          type="checkbox"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          checked={field.value}
                          ref={field.ref}
                        />
                      )}
                    />{" "}
                    <Label check for="terminalrange">
                      Terminal Range
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Controller
                      control={control}
                      name="terminalSupplier"
                      render={({ field }) => (
                        <Input
                          id="terminalSupplier"
                          type="checkbox"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          checked={field.value}
                          ref={field.ref}
                        />
                      )}
                    />{" "}
                    <Label check for="terminalsupplier">
                      Terminal Supplier
                    </Label>
                  </FormGroup>
                </>
              )}

              <FormGroup check>
                <Controller
                  control={control}
                  name="certificates"
                  render={({ field }) => (
                    <Input
                      id="certificates"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="certificates">
                  Certificates
                </Label>
              </FormGroup>

              <FormGroup check>
                <Controller
                  control={control}
                  name="communications"
                  render={({ field }) => (
                    <Input
                      id="communications"
                      type="checkbox"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      ref={field.ref}
                    />
                  )}
                />{" "}
                <Label check for="communications">
                  Communications
                </Label>
              </FormGroup>

              <Button color="primary" type="submit" className="mt-2">
                Save
              </Button>
            </Form>
          )}
        </Container>
      </section>
    </>
  );
};

export default Notifications;
