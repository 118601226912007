import { useSimplux } from "@simplux/react";
import { CreateApplication, CreateRequestRequest } from "api";
import classNames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { user } from "pages/user/user.module";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

type PropTypes = {
  acquirerId: string;
  onChange: () => void;
};

const RequestApplication = (props: PropTypes) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      international: "",
      domestic: "",
      description: "",
      magstripeNumber: "",
      mifare: "",
    },
  });

  const [type, setType] = useState("magstripe");
  const isAdmin = useSimplux(user.isAdmin);

  const onSubmit = (data: any) => {
    let aid = "";

    if (type === "magstripe") {
    } else if (type === "international") {
      aid = "A" + data.international.toUpperCase();
    } else if (type === "domestic") {
      aid = "D" + data.domestic.toUpperCase();
    } else if (type === "mifare") {
    }

    let client = getApiClient();

    if (isAdmin) {
      let request = new CreateApplication();
      request.acquirerId = props.acquirerId;
      request.applicationId = aid;
      request.description = data.description;

      if (data.magstripeNumber) {
        request.magstripeNumber = data.magstripeNumber;
        request.applicationId = undefined;
      } else if (data.mifare) {
        request.miFareNumber = data.mifare;
      }

      client
        .post(request)
        .then(() => {
          reset({
            international: "",
            domestic: "",
            description: "",
          });
          toast.success("Application ID created");
          props.onChange();
        })
        .catch(() => {
          toast.error("Application ID could not be created");
        });
    } else {
      let request = new CreateRequestRequest();
      request.acquirerId = props.acquirerId;
      request.aid = aid;
      request.description = data.description;

      if (type === "magstripe") {
        request.type = "MagStripeAid";
      } else if (type === "international") {
        request.type = "InternationalChipAid";
      } else if (type === "domestic") {
        request.type = "DomesticChipAid";
      } else {
        request.type = "MiFareChipAid";
      }

      client
        .post(request)
        .then(() => {
          reset({
            international: "",
            domestic: "",
            description: "",
            magstripeNumber: "",
            mifare: "",
          });
          toast.success("Application ID request created");
          props.onChange();
        })
        .catch(() => {
          toast.error("Application ID request could not be created");
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 mt-4">
          <small className="text-uppercase font-weight-bold">
            New Application ID
          </small>
        </div>
        <Row>
          <Col md={3}>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="magstripe"
                name="applicationType"
                type="radio"
                value="magstripe"
                checked={type === "magstripe"}
                onChange={(e) => setType(e.target.value)}
              />
              <label className="custom-control-label" htmlFor="magstripe">
                Magstripe AID&nbsp;
              </label>
            </div>
          </Col>
          <Col md={3}>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="international"
                name="applicationType"
                type="radio"
                value="international"
                checked={type === "international"}
                onChange={(e) => setType(e.target.value)}
              />
              <label className="custom-control-label" htmlFor="international">
                International Chip AID&nbsp;
              </label>
            </div>
          </Col>
          <Col md={3}>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="domestic"
                name="applicationType"
                type="radio"
                value="domestic"
                checked={type === "domestic"}
                onChange={(e) => setType(e.target.value)}
              />
              <label className="custom-control-label" htmlFor="domestic">
                Domestic Chip AID&nbsp;
              </label>
            </div>
          </Col>
          <Col md={3}>
            <div className="custom-control custom-radio">
              <input
                className="custom-control-input"
                id="mifare"
                name="applicationType"
                type="radio"
                value="mifare"
                checked={type === "mifare"}
                onChange={(e) => setType(e.target.value)}
              />
              <label className="custom-control-label" htmlFor="mifare">
                MIFARE AID&nbsp;
              </label>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={6}>
            {type === "international" && (
              <FormGroup
                className={classNames({ "has-danger": errors.international })}
              >
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <strong>A</strong>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    control={control}
                    name="international"
                    rules={{
                      required: true,
                      pattern: /^[a-fA-F0-9]{13,19}$/,
                      validate: (value: string) =>
                        !value.toUpperCase().startsWith("000000157") &&
                        /^\d/.test(value),
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder="13-19 HEX characters"
                        maxLength={19}
                        {...field}
                        invalid={!!errors.international}
                      />
                    )}
                  />
                </InputGroup>
              </FormGroup>
            )}
            {type === "domestic" && (
              <FormGroup
                className={classNames({ "has-danger": errors.domestic })}
              >
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <strong>D</strong>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    control={control}
                    name="domestic"
                    rules={{
                      required: true,
                      pattern: /^[a-fA-F0-9]{13,19}$/,
                      validate: (value: string) => value.startsWith("756"),
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder="13-19 HEX characters"
                        maxLength={19}
                        {...field}
                        invalid={!!errors.domestic}
                      />
                    )}
                  />
                </InputGroup>
              </FormGroup>
            )}
          </Col>
        </Row>
        {type === "magstripe" && isAdmin && (
          <Row>
            <Col md={6}>
              <FormGroup
                className={classNames({ "has-danger": errors.magstripeNumber })}
              >
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <strong>A000000157</strong>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    control={control}
                    name="magstripeNumber"
                    rules={{
                      required: false,
                      pattern: /^[a-fA-F0-9]{4}$/,
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder=""
                        maxLength={4}
                        {...field}
                        invalid={!!errors.magstripeNumber}
                        className="pl-2"
                      />
                    )}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        )}
        {type === "mifare" && isAdmin && (
          <Row>
            <Col md={6}>
              <FormGroup
                className={classNames({ "has-danger": errors.magstripeNumber })}
              >
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <strong>A00000015763</strong>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Controller
                    control={control}
                    name="mifare"
                    rules={{
                      required: true,
                      pattern: /^[a-fA-F0-9]{2}$/,
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder="1 byte hex"
                        maxLength={2}
                        {...field}
                        invalid={!!errors.mifare}
                        className="pl-2"
                      />
                    )}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6}>
            <FormGroup
              className={classNames({ "has-danger": errors.description })}
            >
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    placeholder="Description"
                    {...field}
                    invalid={!!errors.description}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button type="submit" color="success">
              {isAdmin ? "Add" : "Request"}
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default RequestApplication;
