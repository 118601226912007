import { KeyInterface } from "api";
import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

const KeyInputs = ({
  interface1,
  testKey,
  prodKey,
}: {
  interface1: KeyInterface;
  testKey:
    | "coiTest"
    | "miRecTest"
    | "miSubTest"
    | "siInitTest"
    | "beTest"
    | "siConfTest";
  prodKey:
    | "coiProd"
    | "miRecProd"
    | "miSubProd"
    | "siInitProd"
    | "beProd"
    | "siConfProd";
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Row>
        <Col md="6" className="mb-4">
          <div className="mb-1">
            <small className="text-uppercase font-weight-bold">
              <strong>{interface1}</strong> Prod
            </small>
          </div>

          <Row>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&lt; 6.3.0</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.valuePre630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.valuePre630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>= 6.3.0</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.value630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.value630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 7.0.0 2k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.valuePost630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.valuePost630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 2k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.valuePost7402K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.valuePost7402K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 3k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.valuePost7403K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.valuePost7403K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 4k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${prodKey}.valuePost7404K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[prodKey]?.valuePost7404K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6" className="mb-4">
          <div className="mb-1">
            <small className="text-uppercase font-weight-bold">
              <strong>{interface1}</strong> Test
            </small>
          </div>

          <Row>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&lt; 6.3.0</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.valuePre630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.valuePre630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>= 6.3.0</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.value630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.value630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 7.0.0 2k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.valuePost630`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.valuePost630,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 2k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.valuePost7402K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.valuePost7402K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 3k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.valuePost7403K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.valuePost7403K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <InputGroup className="input-group-dark">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&gt;= 8.0.0 4k</InputGroupText>
                  </InputGroupAddon>
                  <input
                    {...register(`${testKey}.valuePost7404K`)}
                    placeholder="Key"
                    type="text"
                    className={classNames("form-control pl-2", {
                      "is-invalid": errors[testKey]?.valuePost7404K,
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default KeyInputs;
