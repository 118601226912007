import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommunicationDto, UpdateCommunications } from "api";
import getApiClient from "common/ApiClientFactory";
import CommunicationTypeSelect from "components/CommunicationTypeSelect";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { array, number, object, string } from "yup";
import { CommunicationsFormValues } from "./Communications";

const schema = object({
  groups: array().of(
    object({
      communications: array().of(
        object({
          host: string().required(),
          port: number().integer().min(1).max(65535).required(),
          type: string().required(),
        }).test("unique", "Must be unique", function (com) {
          if (!this.parent) {
            return false;
          }

          const usedTypes = this.parent.map((m) => m.type);
          const usages = usedTypes.filter((p) => p === com.type);

          return usages.length <= 1;
        })
      ),
    })
  ),
});

const CommunicationsForm = ({
  value,
  belongsTo,
}: {
  value: CommunicationsFormValues;
  belongsTo: string;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CommunicationsFormValues>({
    defaultValues: value,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: CommunicationsFormValues) => {
    let request = new UpdateCommunications({
      belongsTo: belongsTo,
      communications: [],
    });

    data.groups.forEach((group) => {
      group.communications.forEach((c) => {
        let entry = new CommunicationDto({
          belongsTo: belongsTo,
          host: c.host,
          port: c.port,
          type: c.type,
          interface: group.interface,
          system: group.system,
        });

        request.communications.push(entry);
      });
    });

    let api = getApiClient();
    api.put(request).then(() => {
      toast.success("saved");
    });
  };

  const Group = ({ group, groupIndex }) => {
    const { fields, append, remove } = useFieldArray({
      name: `groups.${groupIndex}.communications`,
      control: control,
    });

    const used = fields.map((m) => m.type);

    return (
      <Col md="12" key={`${group.interface}-${group.system}`} className="mb-4">
        <div className="mb-1">
          <small className="text-uppercase font-weight-bold">
            <strong>{group.interface.replace("_", "-")}</strong> {group.system}
          </small>
        </div>
        {fields.map((entry, index) => {
          return (
            <Row key={entry.id}>
              <Col md="3">
                <FormGroup>
                  <Controller
                    control={control}
                    name={`groups.${groupIndex}.communications.${index}.type`}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CommunicationTypeSelect
                        used={used}
                        {...field}
                        invalid={
                          !!errors.groups?.[groupIndex]?.communications?.[index]
                            ?.type
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Controller
                    control={control}
                    name={`groups.${groupIndex}.communications.${index}.host`}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Host"
                        {...field}
                        invalid={
                          !!errors.groups?.[groupIndex]?.communications?.[index]
                            ?.host
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Controller
                    control={control}
                    name={`groups.${groupIndex}.communications.${index}.port`}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Port"
                        {...field}
                        invalid={
                          !!errors.groups?.[groupIndex]?.communications?.[index]
                            ?.port
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="1">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    remove([index]);
                  }}
                  className="mt-2"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Col>
            </Row>
          );
        })}
        <Row>
          <Button
            color="secondary"
            size="sm"
            onClick={() => append({ host: "", port: undefined, type: null })}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Row>
      </Col>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {value.groups.map((group, groupIndex) => (
          <Group group={group} groupIndex={groupIndex} key={groupIndex} />
        ))}
      </Row>
      <Row className="mb-4 mt-4">
        <Col>
          <Button color="primary" type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default CommunicationsForm;
