/* Options:
Date: 2023-07-23 23:42:22
Version: 5.92
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:8088

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

export type KeySystem = "Test" | "Prod";

export type KeyInterface =
  | "COI"
  | "SI_CONF"
  | "SI_INIT"
  | "FE"
  | "MI_SUB"
  | "MI_REC"
  | "BE"
  | "MCI"
  | "MDI"
  | "MI";

export class KeyDto {
  public name: string;
  public valuePre630: string;
  public value630: string;
  public valuePost630: string;
  public system: KeySystem;
  public interface: KeyInterface;
  public host: string;
  public port: number;

  public constructor(init?: Partial<KeyDto>) {
    (Object as any).assign(this, init);
  }
}

export class PagedRequest {
  public page: number;
  public pageSize: number;

  public constructor(init?: Partial<PagedRequest>) {
    (Object as any).assign(this, init);
  }
}

export class OrderedRequest extends PagedRequest {
  public orderBy: string;

  public constructor(init?: Partial<OrderedRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class AcquirerLookupDto {
  public id: string;
  public acquirerId: number;
  public companyName: string;

  public constructor(init?: Partial<AcquirerLookupDto>) {
    (Object as any).assign(this, init);
  }
}

export class TerminalRangeDto {
  public id: string;
  public legacyId: string;
  public terminalRangeId: number;
  public description: string;
  public serviceCenterId: string;
  public requestDate: string;
  public disableDate?: string;
  public serviceCenterNumber?: number;
  public companyId: string;
  public companyName: string;

  public constructor(init?: Partial<TerminalRangeDto>) {
    (Object as any).assign(this, init);
  }
}

export type ChangeType = "Created" | "Updated" | "Deleted";

export type CommunicationType =
  | "NotSpecified"
  | "LteInternetConnection"
  | "InternetConnection"
  | "PrivateNetwork"
  | "PrivateNetworkWithDnsFqhn"
  | "PrivateNetworkWithDns"
  | "InternetConnectionWithTls"
  | "PrivateNetworkWithTls"
  | "PointToPointMigros"
  | "PrivateNetworkPointToPoint";

export class CommunicationListDto {
  public id: string;
  public belongsTo: string;
  public system: KeySystem;
  public interface: KeyInterface;
  public host: string;
  public port: number;
  public type: CommunicationType;
  public company: string;
  public companyId: string;
  public entityType: string;
  public entityId: string;

  public constructor(init?: Partial<CommunicationListDto>) {
    (Object as any).assign(this, init);
  }
}

export class CommunicationDto {
  public id: string;
  public belongsTo: string;
  public system: KeySystem;
  public interface: KeyInterface;
  public host: string;
  public port: number;
  public type: CommunicationType;
  public typeDisplay: string;

  public constructor(init?: Partial<CommunicationDto>) {
    (Object as any).assign(this, init);
  }
}

export type CompanyType = "None" | "Subscriber" | "Supplier" | "Acquirer";

export interface ICompanyInfos {
  name: string;
  street1: string;
  street2: string;
  postCode: number;
  city: string;
  country: string;
  type: CompanyType;
}

export type NonceOffsetType = "Sender" | "Receiver";

export type DataFlowDirection = "MasterToSlave" | "SlaveToMaster";

export class PublicKeyDto {
  public id: string;
  public belongsTo: string;
  public valuePre630: string;
  public value630: string;
  public valuePost630: string;
  public valuePost7402K: string;
  public valuePost7403K: string;
  public valuePost7404K: string;
  public system: KeySystem;
  public interface: KeyInterface;

  public constructor(init?: Partial<PublicKeyDto>) {
    (Object as any).assign(this, init);
  }
}

export type RequestType =
  | "NotSpecified"
  | "Acquirer"
  | "Application"
  | "TerminalSupplier"
  | "PMS"
  | "ServiceCenter"
  | "Company"
  | "TerminalRange"
  | "MerchantRange"
  | "MagStripeAid"
  | "InternationalChipAid"
  | "DomesticChipAid"
  | "MiFareChipAid";

export interface IUserFilter {
  userId: string;
}

export class ContactPersonDto {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public userId: string;

  public constructor(init?: Partial<ContactPersonDto>) {
    (Object as any).assign(this, init);
  }
}

export class CompanyDto implements ICompanyInfos {
  public id: string;
  public name: string;
  public street1: string;
  public street2: string;
  public postCode: number;
  public city: string;
  public country: string;
  public contactPeople: string[];
  public primaryContactPerson: ContactPersonDto;
  public type: CompanyType;

  public constructor(init?: Partial<CompanyDto>) {
    (Object as any).assign(this, init);
  }
}

export class RequesterDto {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;

  public constructor(init?: Partial<RequesterDto>) {
    (Object as any).assign(this, init);
  }
}

export class ServiceCenterDto {
  public id: string;
  public legacyId: string;
  public serviceCenterId: number;
  public description: string;
  public remarks: string;
  public requestDate: string;
  public acceptDate?: string;
  public disableDate?: string;
  public company: CompanyDto;
  public keys: KeyDto[];

  public constructor(init?: Partial<ServiceCenterDto>) {
    (Object as any).assign(this, init);
  }
}

export class AcquirerDto {
  public id: string;
  public acquirerId: number;
  public description: string;
  public remarks: string;
  public requestDate: string;
  public acceptDate: string;
  public disableDate?: string;
  public company: CompanyDto;
  public keys: KeyDto[];

  public constructor(init?: Partial<AcquirerDto>) {
    (Object as any).assign(this, init);
  }
}

export class RequestDto {
  public id: string;
  public type: RequestType;
  public description: string;
  public createdAt: string;
  public company: CompanyDto;
  public contactPerson: ContactPersonDto;
  public requester: RequesterDto;
  public serviceCenter: ServiceCenterDto;
  public acquirer: AcquirerDto;
  public count?: number;
  public done: boolean;
  public approved: boolean;
  public nextId: number;
  public nextIdString: string;
  public merchantRange: string;
  public merchantName: string;
  public aid: string;

  public constructor(init?: Partial<RequestDto>) {
    (Object as any).assign(this, init);
  }
}

export class ServiceCenterLookupDto {
  public id: string;
  public serviceCenterId: number;
  public companyName: string;

  public constructor(init?: Partial<ServiceCenterLookupDto>) {
    (Object as any).assign(this, init);
  }
}

export class UserAuth {
  public id: number;
  public userName: string;
  public email: string;
  public primaryEmail: string;
  public phoneNumber: string;
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public company: string;
  public birthDate?: string;
  public birthDateRaw: string;
  public address: string;
  public address2: string;
  public city: string;
  public state: string;
  public country: string;
  public culture: string;
  public fullName: string;
  public gender: string;
  public language: string;
  public mailAddress: string;
  public nickname: string;
  public postalCode: string;
  public timeZone: string;
  public salt: string;
  public passwordHash: string;
  public digestHa1Hash: string;
  public roles: string[];
  public permissions: string[];
  public createdDate: string;
  public modifiedDate: string;
  public invalidLoginAttempts: number;
  public lastLoginAttempt?: string;
  public lockedDate?: string;
  public recoveryToken: string;
  public refId?: number;
  public refIdStr: string;
  public meta: { [index: string]: string };

  public constructor(init?: Partial<UserAuth>) {
    (Object as any).assign(this, init);
  }
}

export class RavenUserAuth extends UserAuth {
  public key: string;

  public constructor(init?: Partial<RavenUserAuth>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class EmailVerificationDto {
  public correlationId: string;
  public startedAt: string;
  public verifiedAt: string;
  public verified: boolean;

  public constructor(init?: Partial<EmailVerificationDto>) {
    (Object as any).assign(this, init);
  }
}

export class UserDto {
  public key: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phoneNumber: string;
  public roles: string[];
  public lastLoginAttempt?: string;
  public companyIds: string[];
  public verification: EmailVerificationDto;
  public approved: boolean;
  public isAdmin: boolean;
  public isAcquirer: boolean;
  public isServiceCenter: boolean;

  public constructor(init?: Partial<UserDto>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

export class PagedResponse<T> {
  public page: number;
  public pageSize: number;
  public totalRows: number;
  public lastPage: number;
  public rows: T[];

  public constructor(init?: Partial<PagedResponse<T>>) {
    (Object as any).assign(this, init);
  }
}

export class ApplicationDto {
  public id: string;
  public applicationId: string;
  public description: string;
  public remarks: string;
  public primaryContactPersonId: string;
  public requestDate: string;
  public acceptDate: string;
  public disableDate?: string;
  public acquirer: AcquirerDto;

  public constructor(init?: Partial<ApplicationDto>) {
    (Object as any).assign(this, init);
  }
}

export class CreateApplicationResponse {
  public constructor(init?: Partial<CreateApplicationResponse>) {
    (Object as any).assign(this, init);
  }
}

export class CertificateDto {
  public id: string;
  public belongsTo: string;
  public system: KeySystem;
  public interface: KeyInterface;
  public number: number;
  public issuer: string;
  public subject: string;
  public notBefore?: string;
  public notAfter?: string;

  public constructor(init?: Partial<CertificateDto>) {
    (Object as any).assign(this, init);
  }
}

export class KernelDto {
  public id: string;
  public kernelId: string;
  public description: string;
  public company: CompanyDto;

  public constructor(init?: Partial<KernelDto>) {
    (Object as any).assign(this, init);
  }
}

export class MerchantRangeDto {
  public id: string;
  public legacyId: string;
  public merchantRangeId: string;
  public description: string;
  public serviceCenterId: string;
  public requestDate: string;
  public disableDate?: string;
  public companyId: string;
  public companyName: string;
  public serviceCenterNumber?: number;

  public constructor(init?: Partial<MerchantRangeDto>) {
    (Object as any).assign(this, init);
  }
}

export class NonceOffsetDto {
  public id: string;
  public type: NonceOffsetType;
  public name: string;
  public xmlTagAndNamespace: string;
  public encryption: string;
  public format: string;
  public length: number;
  public interface: KeyInterface;
  public description: string;
  public value: string;
  public dataFlowDirection: DataFlowDirection;
  public company: CompanyDto;

  public constructor(init?: Partial<NonceOffsetDto>) {
    (Object as any).assign(this, init);
  }
}

export class PMSDto {
  public id: string;
  public legacyId: string;
  public pmsId: number;
  public description: string;
  public remarks: string;
  public requestDate: string;
  public acceptDate: string;
  public disableDate?: string;
  public company: CompanyDto;
  public keys: KeyDto[];

  public constructor(init?: Partial<PMSDto>) {
    (Object as any).assign(this, init);
  }
}

export class RegisterResponse {
  public firstName: string;
  public lastName: string;
  public userId: string;
  public roles: string[];

  public constructor(init?: Partial<RegisterResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SubscriptionsDto {
  public id: string;
  public userId: string;
  public email: string;
  public acquirer: boolean;
  public application: boolean;
  public serviceCenter: boolean;
  public pms: boolean;
  public merchantRange: boolean;
  public terminalRange: boolean;
  public terminalSupplier: boolean;
  public certificates: boolean;
  public communications: boolean;

  public constructor(init?: Partial<SubscriptionsDto>) {
    (Object as any).assign(this, init);
  }
}

export class TerminalSupplierDto {
  public id: string;
  public terminalSupplierId: number;
  public description: string;
  public serviceCenter: ServiceCenterDto;
  public remarks: string;
  public primaryContactPersonId: string;
  public requestDate: string;
  public acceptDate: string;
  public disableDate?: string;

  public constructor(init?: Partial<TerminalSupplierDto>) {
    (Object as any).assign(this, init);
  }
}

export class CurrentUserDto {
  public firstName: string;
  public lastName: string;
  public userId: string;
  public roles: string[];
  public emailVerified: boolean;
  public approved: boolean;
  public level: CompanyType;
  public hasCompanyAssigned: boolean;
  public email: string;

  public constructor(init?: Partial<CurrentUserDto>) {
    (Object as any).assign(this, init);
  }
}

export class UserDetailDto extends UserDto {
  public companies: CompanyDto[];

  public constructor(init?: Partial<UserDetailDto>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ConvertSessionToTokenResponse {
  // @DataMember(Order=1)
  public meta: { [index: string]: string };

  // @DataMember(Order=2)
  public accessToken: string;

  // @DataMember(Order=3)
  public refreshToken: string;

  // @DataMember(Order=4)
  public responseStatus: ResponseStatus;

  public constructor(init?: Partial<ConvertSessionToTokenResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAccessTokenResponse {
  // @DataMember(Order=1)
  public accessToken: string;

  // @DataMember(Order=2)
  public meta: { [index: string]: string };

  // @DataMember(Order=3)
  public responseStatus: ResponseStatus;

  public constructor(init?: Partial<GetAccessTokenResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/acquirers/{Id}", "GET")
export class GetAcquirerRequest implements IReturn<AcquirerDto> {
  public id: string;

  public constructor(init?: Partial<GetAcquirerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AcquirerDto();
  }
  public getTypeName() {
    return "GetAcquirerRequest";
  }
}

// @Route("/acquirers", "GET")
export class GetAcquirersRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<AcquirerDto>>
{
  public search: string;

  public constructor(init?: Partial<GetAcquirersRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<AcquirerDto>();
  }
  public getTypeName() {
    return "GetAcquirersRequest";
  }
}

// @Route("/lookup/acquirers", "GET")
export class LookupAcquirerRequest
  extends PagedRequest
  implements IReturn<PagedResponse<AcquirerLookupDto>>
{
  public search: string;

  public constructor(init?: Partial<LookupAcquirerRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<AcquirerLookupDto>();
  }
  public getTypeName() {
    return "LookupAcquirerRequest";
  }
}

// @Route("/acquirers", "POST")
export class CreateAcquirer implements IReturn<AcquirerDto> {
  public acquirerId: number;
  public description: string;
  public remarks: string;
  public companyId: string;

  public constructor(init?: Partial<CreateAcquirer>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AcquirerDto();
  }
  public getTypeName() {
    return "CreateAcquirer";
  }
}

// @Route("/acquirers", "PUT")
export class UpdateAcquirerRequest implements IReturnVoid {
  public id: string;
  public acquirerId: number;
  public description: string;
  public remarks: string;
  public companyId: string;

  public constructor(init?: Partial<UpdateAcquirerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateAcquirerRequest";
  }
}

// @Route("/applications/{Id}", "GET")
export class GetApplicationRequest implements IReturn<ApplicationDto> {
  public id: string;

  public constructor(init?: Partial<GetApplicationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ApplicationDto();
  }
  public getTypeName() {
    return "GetApplicationRequest";
  }
}

// @Route("/applications", "GET")
export class GetApplicationsRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<ApplicationDto>>
{
  public search: string;
  public acquirerId: string;

  public constructor(init?: Partial<GetApplicationsRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ApplicationDto>();
  }
  public getTypeName() {
    return "GetApplicationsRequest";
  }
}

// @Route("/applications", "PUT")
export class UpdateApplicationRequest implements IReturnVoid {
  public id: string;
  public applicationId: string;
  public description: string;
  public remarks: string;
  public acquirerId: string;

  public constructor(init?: Partial<UpdateApplicationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateApplicationRequest";
  }
}

// @Route("/applications", "PATCH")
export class PatchApplication implements IReturnVoid {
  public id: string;
  public disable: boolean;

  public constructor(init?: Partial<PatchApplication>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "PatchApplication";
  }
}

// @Route("/applications", "POST")
export class CreateApplication implements IReturnVoid {
  public acquirerId: string;
  public applicationId: string;
  public description: string;
  public magstripeNumber: string;
  public miFareNumber: string;

  public constructor(init?: Partial<CreateApplication>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateApplication";
  }
}

// @Route("/applications", "DELETE")
export class DeleteApplication implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteApplication>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteApplication";
  }
}

// @Route("/approvals/company", "POST")
export class ApproveCompanyRequest implements IReturnVoid {
  public requestId: string;
  public createNewCompany: boolean;
  public existingCompanyId: string;
  public createNewContactPerson: boolean;
  public existingContactPersonId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApproveCompanyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveCompanyRequest";
  }
}

// @Route("/approvals/aid", "POST")
export class ApproveAidRequest implements IReturnVoid {
  public requestId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApproveAidRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveAidRequest";
  }
}

// @Route("/approvals/pms", "POST")
export class ApprovePMSIdRequest implements IReturnVoid {
  public requestId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApprovePMSIdRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApprovePMSIdRequest";
  }
}

// @Route("/approvals/service-center", "POST")
export class ApproveServiceCenterIdRequest implements IReturnVoid {
  public requestId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApproveServiceCenterIdRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveServiceCenterIdRequest";
  }
}

// @Route("/approvals/acquirers", "POST")
export class ApproveAcquirerIdRequest implements IReturnVoid {
  public requestId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApproveAcquirerIdRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveAcquirerIdRequest";
  }
}

// @Route("/approvals/merchant-ranges", "POST")
export class ApproveMerchantRange implements IReturnVoid {
  public requestId: string;
  public approve: boolean;

  public constructor(init?: Partial<ApproveMerchantRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveMerchantRange";
  }
}

// @Route("/approvals/terminal-ranges", "POST")
export class ApproveTerminalRangeRequest implements IReturnVoid {
  public requestId: string;
  public approve: boolean;
  public start?: number;

  public constructor(init?: Partial<ApproveTerminalRangeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ApproveTerminalRangeRequest";
  }
}

// @Route("/certificates/{Id*}", "GET")
export class GetCertificate implements IReturn<CertificateDto> {
  public id: string;

  public constructor(init?: Partial<GetCertificate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CertificateDto();
  }
  public getTypeName() {
    return "GetCertificate";
  }
}

// @Route("/certificates", "GET")
export class GetCertificates implements IReturn<CertificateDto[]> {
  public belongsTo: string;

  public constructor(init?: Partial<GetCertificates>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<CertificateDto>();
  }
  public getTypeName() {
    return "GetCertificates";
  }
}

// @Route("/certificates", "DELETE")
export class DeleteCertificate implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteCertificate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteCertificate";
  }
}

// @Route("/certificates", "PUT")
export class PutCertificate implements IReturn<CertificateDto> {
  public belongsTo: string;
  public system: KeySystem;
  public interface: KeyInterface;
  public number: number;
  public dataBase64: string;

  public constructor(init?: Partial<PutCertificate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CertificateDto();
  }
  public getTypeName() {
    return "PutCertificate";
  }
}

export class TerminalRangeChanged implements IReturnVoid, IPost {
  public terminalRanges: TerminalRangeDto[];
  public changeType: ChangeType;

  public constructor(init?: Partial<TerminalRangeChanged>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "TerminalRangeChanged";
  }
}

export class MerchantRangeChanged {
  public merchantRange: MerchantRangeDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<MerchantRangeChanged>) {
    (Object as any).assign(this, init);
  }
}

export class TerminalSupplierChanged {
  public terminalSupplier: TerminalSupplierDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<TerminalSupplierChanged>) {
    (Object as any).assign(this, init);
  }
}

export class AcquirerChanged {
  public acquirer: AcquirerDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<AcquirerChanged>) {
    (Object as any).assign(this, init);
  }
}

export class ApplicationChanged {
  public application: ApplicationDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<ApplicationChanged>) {
    (Object as any).assign(this, init);
  }
}

export class ServiceCenterChanged {
  public serviceCenter: ServiceCenterDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<ServiceCenterChanged>) {
    (Object as any).assign(this, init);
  }
}

export class PmsChanged {
  public pms: PMSDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<PmsChanged>) {
    (Object as any).assign(this, init);
  }
}

export class CertificateChanged {
  public certificate: CertificateDto;
  public changeType: ChangeType;

  public constructor(init?: Partial<CertificateChanged>) {
    (Object as any).assign(this, init);
  }
}

export class CommunicationChanged {
  public entityType: string;
  public entityId: string;
  public communications: CommunicationDto[];

  public constructor(init?: Partial<CommunicationChanged>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/communciations", "GET")
export class GetCommunications
  extends OrderedRequest
  implements IReturn<PagedResponse<CommunicationListDto>>
{
  public search: string;

  public constructor(init?: Partial<GetCommunications>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<CommunicationListDto>();
  }
  public getTypeName() {
    return "GetCommunications";
  }
}

// @Route("/communications/{BelongsTo}", "GET")
export class GetCommunicationsByOwner implements IReturn<CommunicationDto[]> {
  public belongsTo: string;

  public constructor(init?: Partial<GetCommunicationsByOwner>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<CommunicationDto>();
  }
  public getTypeName() {
    return "GetCommunicationsByOwner";
  }
}

// @Route("/communications", "PUT")
export class UpdateCommunications implements IReturnVoid {
  public belongsTo: string;
  public communications: CommunicationDto[];

  public constructor(init?: Partial<UpdateCommunications>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateCommunications";
  }
}

// @Route("/companies/{Id}", "GET")
export class GetCompanyRequest implements IReturn<CompanyDto> {
  public id: string;

  public constructor(init?: Partial<GetCompanyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CompanyDto();
  }
  public getTypeName() {
    return "GetCompanyRequest";
  }
}

// @Route("/companies", "GET")
export class GetCompaniesRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<CompanyDto>>
{
  public search: string;
  public ids: string[];

  public constructor(init?: Partial<GetCompaniesRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<CompanyDto>();
  }
  public getTypeName() {
    return "GetCompaniesRequest";
  }
}

// @Route("/companies", "POST")
export class CreateCompanyRequest
  implements IReturn<CompanyDto>, ICompanyInfos
{
  public name: string;
  public street1: string;
  public street2: string;
  public postCode: number;
  public city: string;
  public country: string;
  public phone: string;
  public email: string;
  public fax: string;
  public type: CompanyType;

  public constructor(init?: Partial<CreateCompanyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CompanyDto();
  }
  public getTypeName() {
    return "CreateCompanyRequest";
  }
}

// @Route("/companies", "PUT")
export class UpdateCompanyRequest
  implements IReturn<CompanyDto>, ICompanyInfos
{
  public id: string;
  public name: string;
  public street1: string;
  public street2: string;
  public postCode: number;
  public city: string;
  public country: string;
  public phone: string;
  public email: string;
  public fax: string;
  public type: CompanyType;

  public constructor(init?: Partial<UpdateCompanyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CompanyDto();
  }
  public getTypeName() {
    return "UpdateCompanyRequest";
  }
}

// @Route("/users/me/companies", "GET")
export class GetAssignedCompanies implements IReturn<CompanyDto[]> {
  public types: CompanyType[];

  public constructor(init?: Partial<GetAssignedCompanies>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<CompanyDto>();
  }
  public getTypeName() {
    return "GetAssignedCompanies";
  }
}

// @Route("/company-merges", "POST")
export class MergeCompanyRequest implements IReturnVoid {
  public targetCompanyId: string;
  public sourceCompanyId: string;

  public constructor(init?: Partial<MergeCompanyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "MergeCompanyRequest";
  }
}

// @Route("/contact-person-merges", "POST")
export class MergeContactPersonRequest implements IReturnVoid {
  public targetContactPersonId: string;
  public sourceContactPersonId: string;

  public constructor(init?: Partial<MergeContactPersonRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "MergeContactPersonRequest";
  }
}

// @Route("/contact-people/{Id}", "GET")
export class GetContactPersonRequest implements IReturn<ContactPersonDto> {
  public id: string;

  public constructor(init?: Partial<GetContactPersonRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ContactPersonDto();
  }
  public getTypeName() {
    return "GetContactPersonRequest";
  }
}

// @Route("/assigned-contact-people", "GET")
export class GetContactPeopleFromAssignedCompany
  extends PagedRequest
  implements IReturn<PagedResponse<ContactPersonDto>>
{
  public serviceCenterId: string;
  public acquirerId: string;

  public constructor(init?: Partial<GetContactPeopleFromAssignedCompany>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ContactPersonDto>();
  }
  public getTypeName() {
    return "GetContactPeopleFromAssignedCompany";
  }
}

// @Route("/contact-people", "GET")
export class GetContactPeopleFromTargetRequest
  implements IReturn<ContactPersonDto[]>
{
  public targetId: string;

  public constructor(init?: Partial<GetContactPeopleFromTargetRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<ContactPersonDto>();
  }
  public getTypeName() {
    return "GetContactPeopleFromTargetRequest";
  }
}

// @Route("/contact-people", "GET")
export class GetContactPeopleRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<ContactPersonDto>>
{
  public search: string;

  public constructor(init?: Partial<GetContactPeopleRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ContactPersonDto>();
  }
  public getTypeName() {
    return "GetContactPeopleRequest";
  }
}

// @Route("/contact-people", "POST")
export class CreateContactPerson implements IReturnVoid {
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public targetId: string;

  public constructor(init?: Partial<CreateContactPerson>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateContactPerson";
  }
}

// @Route("/contact-people-assignments", "POST")
export class AddContactPersonRequest implements IReturnVoid {
  public id: string;
  public contactPersonId: string;

  public constructor(init?: Partial<AddContactPersonRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "AddContactPersonRequest";
  }
}

// @Route("/contact-people", "DELETE")
export class DeleteContactPerson implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteContactPerson>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteContactPerson";
  }
}

// @Route("/targets/contact-people", "DELETE")
export class DeleteContactPersonFromTargetRequest implements IReturnVoid {
  public id: string;
  public contactPersonId: string;

  public constructor(init?: Partial<DeleteContactPersonFromTargetRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteContactPersonFromTargetRequest";
  }
}

// @Route("/primary-contact-person", "PUT")
export class SetPrimaryContactPerson implements IReturnVoid {
  public contactPersonId: string;
  public targetId: string;

  public constructor(init?: Partial<SetPrimaryContactPerson>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "SetPrimaryContactPerson";
  }
}

// @Route("/contact-people", "PUT")
export class UpdateContactPersonRequest implements IReturnVoid {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;

  public constructor(init?: Partial<UpdateContactPersonRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateContactPersonRequest";
  }
}

// @Route("/contact-people/{Id}/similar")
export class GetSimilarContactPeopleRequest
  extends PagedRequest
  implements IReturn<PagedResponse<ContactPersonDto>>
{
  public id: string;

  public constructor(init?: Partial<GetSimilarContactPeopleRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ContactPersonDto>();
  }
  public getTypeName() {
    return "GetSimilarContactPeopleRequest";
  }
}

// @Route("/disables", "POST")
export class DisableRequest implements IReturnVoid {
  public id: string;
  public disable: boolean;

  public constructor(init?: Partial<DisableRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DisableRequest";
  }
}

// @Route("/exports/public-keys", "GET")
export class GetPublicKey implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetPublicKey>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetPublicKey";
  }
}

// @Route("/exports/communication", "GET")
export class GetCommunication implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetCommunication>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetCommunication";
  }
}

// @Route("/exports/id-master", "GET")
export class GetIdMaster implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetIdMaster>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetIdMaster";
  }
}

// @Route("/exports/companies", "GET")
export class GetCompanyExport implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetCompanyExport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetCompanyExport";
  }
}

// @Route("/exports/users", "GET")
export class GetUserExport implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetUserExport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetUserExport";
  }
}

// @Route("/exports/certificates", "GET")
export class GetCertificatesExport implements IReturn<Uint8Array> {
  public constructor(init?: Partial<GetCertificatesExport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return "GetCertificatesExport";
  }
}

// @Route("/kernels/{Id}", "GET")
export class GetKernelRequest implements IReturn<KernelDto> {
  public id: string;

  public constructor(init?: Partial<GetKernelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new KernelDto();
  }
  public getTypeName() {
    return "GetKernelRequest";
  }
}

// @Route("/kernels", "GET")
export class GetKernelsRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<KernelDto>>
{
  public search: string;

  public constructor(init?: Partial<GetKernelsRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<KernelDto>();
  }
  public getTypeName() {
    return "GetKernelsRequest";
  }
}

// @Route("/kernels/{Id}", "PUT")
export class UpdateKernelRequest implements IReturnVoid {
  public id: string;
  public kernelId: string;
  public companyId: string;
  public description: string;

  public constructor(init?: Partial<UpdateKernelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateKernelRequest";
  }
}

// @Route("/kernels/{Id}", "POST")
export class CreateKernelRequest implements IReturnVoid {
  public id: string;
  public kernelId: string;
  public companyId: string;
  public description: string;

  public constructor(init?: Partial<CreateKernelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateKernelRequest";
  }
}

// @Route("/kernels/{Id}", "DELETE")
export class DeleteKernelRequest implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteKernelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteKernelRequest";
  }
}

// @Route("/merchant-ranges/{Id}", "GET")
export class GetMerchantRange implements IReturn<MerchantRangeDto> {
  public id: string;

  public constructor(init?: Partial<GetMerchantRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MerchantRangeDto();
  }
  public getTypeName() {
    return "GetMerchantRange";
  }
}

// @Route("/merchant-ranges", "GET")
export class GetMerchantRangesRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<MerchantRangeDto>>
{
  public search: string;
  public serviceCenterId: string;

  public constructor(init?: Partial<GetMerchantRangesRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<MerchantRangeDto>();
  }
  public getTypeName() {
    return "GetMerchantRangesRequest";
  }
}

// @Route("/merchant-ranges", "POST")
export class CreateMerchantRange implements IReturnVoid {
  public serviceCenterId: string;
  public merchantRangeId: string;
  public name: string;

  public constructor(init?: Partial<CreateMerchantRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateMerchantRange";
  }
}

// @Route("/merchant-ranges/{Id}", "PUT")
export class UpdateMerchantRange implements IReturnVoid {
  public id: string;
  public description: string;
  public merchantRangeId: string;

  public constructor(init?: Partial<UpdateMerchantRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateMerchantRange";
  }
}

// @Route("/merchant-ranges", "DELETE")
export class DeleteMerchantRange implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteMerchantRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteMerchantRange";
  }
}

// @Route("/merchant-ranges/state", "PUT")
export class UpdateMerchantRangeState implements IReturnVoid {
  public id: string;
  public disable: boolean;

  public constructor(init?: Partial<UpdateMerchantRangeState>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateMerchantRangeState";
  }
}

// @Route("/nonce-offsets", "GET")
export class GetNonceOffset implements IReturn<NonceOffsetDto> {
  public id: string;

  public constructor(init?: Partial<GetNonceOffset>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new NonceOffsetDto();
  }
  public getTypeName() {
    return "GetNonceOffset";
  }
}

// @Route("/nonce-offsets", "GET")
export class GetNonceOffsets
  extends OrderedRequest
  implements IReturn<PagedResponse<NonceOffsetDto>>
{
  public search: string;

  public constructor(init?: Partial<GetNonceOffsets>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<NonceOffsetDto>();
  }
  public getTypeName() {
    return "GetNonceOffsets";
  }
}

// @Route("/nonce-offsets", "POST")
export class CreateNonceOffset implements IReturnVoid {
  public type: NonceOffsetType;
  public name: string;
  public xmlTagAndNamespace: string;
  public encryption: string;
  public format: string;
  public length: number;
  public interface: KeyInterface;
  public description: string;
  public value: string;
  public dataFlowDirection: DataFlowDirection;
  public companyId: string;

  public constructor(init?: Partial<CreateNonceOffset>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateNonceOffset";
  }
}

// @Route("/nonce-offsets", "PUT")
export class UpdateNonceOffset implements IReturnVoid {
  public id: string;
  public type: NonceOffsetType;
  public name: string;
  public xmlTagAndNamespace: string;
  public encryption: string;
  public format: string;
  public length: number;
  public interface: KeyInterface;
  public description: string;
  public value: string;
  public dataFlowDirection: DataFlowDirection;
  public companyId: string;

  public constructor(init?: Partial<UpdateNonceOffset>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateNonceOffset";
  }
}

// @Route("/nonce-offsets/{Id}", "GET")
export class DeleteNonceOffset implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteNonceOffset>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteNonceOffset";
  }
}

// @Route("/pms/{Id}", "GET")
export class GetPMSRequest implements IReturn<PMSDto> {
  public id: string;

  public constructor(init?: Partial<GetPMSRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PMSDto();
  }
  public getTypeName() {
    return "GetPMSRequest";
  }
}

// @Route("/pms", "GET")
export class GetPMSsRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<PMSDto>>
{
  public search: string;

  public constructor(init?: Partial<GetPMSsRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<PMSDto>();
  }
  public getTypeName() {
    return "GetPMSsRequest";
  }
}

// @Route("/pms", "POST")
export class CreatePms implements IReturn<PMSDto> {
  public pmsId: number;
  public description: string;
  public remarks: string;
  public companyId: string;

  public constructor(init?: Partial<CreatePms>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PMSDto();
  }
  public getTypeName() {
    return "CreatePms";
  }
}

// @Route("/pms", "PUT")
export class UpdatePMSRequest implements IReturnVoid {
  public id: string;
  public pmsId: number;
  public description: string;
  public remarks: string;
  public companyId: string;

  public constructor(init?: Partial<UpdatePMSRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdatePMSRequest";
  }
}

// @Route("/public-keys/", "GET")
export class GetPublicKeys implements IReturn<PublicKeyDto[]> {
  public targetId: string;

  public constructor(init?: Partial<GetPublicKeys>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PublicKeyDto>();
  }
  public getTypeName() {
    return "GetPublicKeys";
  }
}

// @Route("/public-keys/", "PUT")
export class UpdatePublicKey implements IReturnVoid {
  public belongsTo: string;
  public valuePre630: string;
  public value630: string;
  public valuePost630: string;
  public valuePost7402K: string;
  public valuePost7403K: string;
  public valuePost7404K: string;
  public system: KeySystem;
  public interface: KeyInterface;

  public constructor(init?: Partial<UpdatePublicKey>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdatePublicKey";
  }
}

// @Route("/register", "POST")
export class RegisterRequest implements IReturn<RegisterResponse> {
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public password: string;

  public constructor(init?: Partial<RegisterRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new RegisterResponse();
  }
  public getTypeName() {
    return "RegisterRequest";
  }
}

// @Route("/requests", "POST")
export class CreateRequestRequest implements IReturnVoid {
  public type: RequestType;
  public description: string;
  public company: CompanyDto;
  public contactPerson: ContactPersonDto;
  public acquirerId: string;
  public serviceCenterId: string;
  public count?: number;
  public merchantRange: string;
  public merchantName: string;
  public aid: string;

  public constructor(init?: Partial<CreateRequestRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateRequestRequest";
  }
}

// @Route("/requests", "GET")
export class GetRequestsRequest
  extends PagedRequest
  implements IReturn<PagedResponse<RequestDto>>, IUserFilter
{
  public filterForOpen: boolean;
  public search: string;
  public userId: string;

  public constructor(init?: Partial<GetRequestsRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<RequestDto>();
  }
  public getTypeName() {
    return "GetRequestsRequest";
  }
}

// @Route("/servicecenters/{Id}", "GET")
export class GetServiceCenterRequest implements IReturn<ServiceCenterDto> {
  public id: string;

  public constructor(init?: Partial<GetServiceCenterRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ServiceCenterDto();
  }
  public getTypeName() {
    return "GetServiceCenterRequest";
  }
}

// @Route("/servicecenters", "GET")
export class GetServiceCentersRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<ServiceCenterDto>>
{
  public search: string;

  public constructor(init?: Partial<GetServiceCentersRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ServiceCenterDto>();
  }
  public getTypeName() {
    return "GetServiceCentersRequest";
  }
}

// @Route("/lookup/servicecenters", "GET")
export class LookupServiceCentersRequest
  extends PagedRequest
  implements IReturn<PagedResponse<ServiceCenterLookupDto>>
{
  public search: string;

  public constructor(init?: Partial<LookupServiceCentersRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<ServiceCenterLookupDto>();
  }
  public getTypeName() {
    return "LookupServiceCentersRequest";
  }
}

// @Route("/servicecenters", "POST")
export class CreateServiceCenter implements IReturn<ServiceCenterDto> {
  public id: string;
  public serviceCenterId: number;
  public description: string;
  public remarks: string;
  public companyId: string;

  public constructor(init?: Partial<CreateServiceCenter>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ServiceCenterDto();
  }
  public getTypeName() {
    return "CreateServiceCenter";
  }
}

// @Route("/servicecenters/{Id}", "PUT")
export class UpdateServiceCenterRequest implements IReturnVoid {
  public id: string;
  public serviceCenterId: number;
  public description: string;
  public remarks: string;
  public requestDate: string;
  public acceptDate: string;
  public disableDate?: string;
  public companyId: string;

  public constructor(init?: Partial<UpdateServiceCenterRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateServiceCenterRequest";
  }
}

export class UnknownTypes {
  public user: RavenUserAuth[];

  public constructor(init?: Partial<UnknownTypes>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/subscriptions", "GET")
export class GetMySubscriptions implements IReturn<SubscriptionsDto> {
  public constructor(init?: Partial<GetMySubscriptions>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SubscriptionsDto();
  }
  public getTypeName() {
    return "GetMySubscriptions";
  }
}

// @Route("/subscriptions", "PUT")
export class UpdateMySubscriptions implements IReturn<SubscriptionsDto> {
  public email: string;
  public acquirer: boolean;
  public application: boolean;
  public serviceCenter: boolean;
  public pms: boolean;
  public merchantRange: boolean;
  public terminalRange: boolean;
  public terminalSupplier: boolean;
  public certificates: boolean;
  public communications: boolean;

  public constructor(init?: Partial<UpdateMySubscriptions>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SubscriptionsDto();
  }
  public getTypeName() {
    return "UpdateMySubscriptions";
  }
}

// @Route("/terminal-ranges/{Id}", "GET")
export class GetTerminalRangeRequest implements IReturn<TerminalRangeDto> {
  public id: string;

  public constructor(init?: Partial<GetTerminalRangeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TerminalRangeDto();
  }
  public getTypeName() {
    return "GetTerminalRangeRequest";
  }
}

// @Route("/terminal-ranges", "GET")
export class GetTerminalRangesRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<TerminalRangeDto>>
{
  public search: string;
  public serviceCenterId: string;

  public constructor(init?: Partial<GetTerminalRangesRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<TerminalRangeDto>();
  }
  public getTypeName() {
    return "GetTerminalRangesRequest";
  }
}

// @Route("/terminal-ranges", "POST")
export class CreateTerminalRange implements IReturnVoid {
  public serviceCenterId: string;
  public description: string;
  public count: number;

  public constructor(init?: Partial<CreateTerminalRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateTerminalRange";
  }
}

// @Route("/terminal-ranges/{Id}", "PUT")
export class UpdateTerminalRange implements IReturnVoid {
  public id: string;
  public description: string;
  public terminalRangeId: number;

  public constructor(init?: Partial<UpdateTerminalRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateTerminalRange";
  }
}

// @Route("/terminal-ranges/state", "PUT")
export class UpdateTerminalRangeState implements IReturnVoid {
  public id: string;
  public disable: boolean;

  public constructor(init?: Partial<UpdateTerminalRangeState>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateTerminalRangeState";
  }
}

// @Route("/terminal-ranges", "DELETE")
export class DeleteTerminalRange implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteTerminalRange>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteTerminalRange";
  }
}

// @Route("/terminal-suppliers/{Id}", "GET")
export class GetTerminalSupplierRequest
  implements IReturn<TerminalSupplierDto>
{
  public id: string;

  public constructor(init?: Partial<GetTerminalSupplierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TerminalSupplierDto();
  }
  public getTypeName() {
    return "GetTerminalSupplierRequest";
  }
}

// @Route("/terminal-suppliers", "GET")
export class GetTerminalSuppliersRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<TerminalSupplierDto>>
{
  public search: string;
  public serviceCenterId: string;

  public constructor(init?: Partial<GetTerminalSuppliersRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<TerminalSupplierDto>();
  }
  public getTypeName() {
    return "GetTerminalSuppliersRequest";
  }
}

// @Route("/terminal-suppliers", "PUT")
export class UpdateTerminalSupplierRequest implements IReturnVoid {
  public id: string;
  public terminalSupplierId: number;
  public description: string;
  public remarks: string;
  public serviceCenterId: string;

  public constructor(init?: Partial<UpdateTerminalSupplierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateTerminalSupplierRequest";
  }
}

// @Route("/terminal-suppliers", "PATCH")
export class PatchTerminalSupplier implements IReturnVoid {
  public id: string;
  public disable: boolean;

  public constructor(init?: Partial<PatchTerminalSupplier>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "PatchTerminalSupplier";
  }
}

// @Route("/terminal-suppliers", "POST")
export class CreateTerminalSupplier implements IReturnVoid {
  public serviceCenterId: string;
  public description: string;

  public constructor(init?: Partial<CreateTerminalSupplier>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "CreateTerminalSupplier";
  }
}

// @Route("/terminal-suppliers", "DELETE")
export class DeleteTerminalSupplier implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteTerminalSupplier>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteTerminalSupplier";
  }
}

// @Route("/users/current", "GET")
export class GetCurrentUserRequest implements IReturn<CurrentUserDto> {
  public constructor(init?: Partial<GetCurrentUserRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new CurrentUserDto();
  }
  public getTypeName() {
    return "GetCurrentUserRequest";
  }
}

// @Route("/users/{Id}", "GET")
export class GetUserRequest implements IReturn<UserDetailDto> {
  public id: string;

  public constructor(init?: Partial<GetUserRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UserDetailDto();
  }
  public getTypeName() {
    return "GetUserRequest";
  }
}

// @Route("/users", "GET")
export class GetUsersRequest
  extends OrderedRequest
  implements IReturn<PagedResponse<UserDto>>
{
  public search: string;
  public filterPendingApproval: boolean;

  public constructor(init?: Partial<GetUsersRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PagedResponse<UserDto>();
  }
  public getTypeName() {
    return "GetUsersRequest";
  }
}

// @Route("/email-verifications/", "POST")
export class VerifyEmailRequest implements IReturnVoid {
  public correlationId: string;

  public constructor(init?: Partial<VerifyEmailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "VerifyEmailRequest";
  }
}

// @Route("/users/{Id}", "POST PUT")
export class UpdateUserRequest implements IReturnVoid {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phoneNumber: string;
  public roles: string[];
  public companyIds: string[];
  public changedPassword: string;

  public constructor(init?: Partial<UpdateUserRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateUserRequest";
  }
}

// @Route("/users/{Id}", "DELETE")
export class DeleteUser implements IReturnVoid {
  public id: string;

  public constructor(init?: Partial<DeleteUser>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "DeleteUser";
  }
}

// @Route("/users/{Id}/companies", "PUT")
export class UpdateUserCompanies implements IReturnVoid {
  public id: string;
  public companyIds: string[];

  public constructor(init?: Partial<UpdateUserCompanies>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "UpdateUserCompanies";
  }
}

// @Route("/users/{Id}", "PATCH")
export class PatchUserRequest implements IReturnVoid {
  public id: string;
  public approval?: boolean;

  public constructor(init?: Partial<PatchUserRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "PatchUserRequest";
  }
}

// @Route("/users/password-reset", "POST")
export class RequestPasswordReset implements IReturnVoid {
  public email: string;

  public constructor(init?: Partial<RequestPasswordReset>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "RequestPasswordReset";
  }
}

// @Route("/passwords", "POST")
export class ResetPassword implements IReturnVoid {
  public newPassword: string;
  public token: string;

  public constructor(init?: Partial<ResetPassword>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {}
  public getTypeName() {
    return "ResetPassword";
  }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=16)
  public useTokenCookie?: boolean;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return "Authenticate";
  }
}

// @Route("/session-to-token")
// @DataContract
export class ConvertSessionToToken
  implements IReturn<ConvertSessionToTokenResponse>, IPost
{
  // @DataMember(Order=1)
  public preserveSession: boolean;

  // @DataMember(Order=2)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ConvertSessionToToken>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ConvertSessionToTokenResponse();
  }
  public getTypeName() {
    return "ConvertSessionToToken";
  }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost {
  // @DataMember(Order=1)
  public refreshToken: string;

  // @DataMember(Order=2)
  public useTokenCookie?: boolean;

  // @DataMember(Order=3)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<GetAccessToken>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAccessTokenResponse();
  }
  public getTypeName() {
    return "GetAccessToken";
  }
}
