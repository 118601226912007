import React from "react";
import Hero from "../../../components/Hero";
import { Container, Row, Col, FormGroup, Input, Button } from "reactstrap";
import { CreateRequestRequest, CompanyDto } from "api";
import getApiClient from "common/ApiClientFactory";
import { useSimplux } from "@simplux/react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import { user } from "pages/user/user.module";
import classNames from "classnames";
import NotAllowedToRegister from "../NotAllowedToRegister";
import { ErrorMessage } from "@hookform/error-message";
import CompanySelect from "components/CompanySelect";

const RequestAcquirerId = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const emailVerified = useSimplux(user.emailVerified);
  const approved = useSimplux(user.approved);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const history = useHistory();

  const onSubmit = (data: FormData) => {
    let request = new CreateRequestRequest();
    request.type = "Acquirer";
    request.description = data.description;
    request.company = data.company;

    const client = getApiClient();
    client
      .post(request)
      .then(() => {
        history.push("/request/complete");
      })
      .catch(() => toast.error("Error sending request"));
  };

  if (!isLoggedIn || !emailVerified || !approved) {
    return (
      <NotAllowedToRegister
        isLoggedIn={isLoggedIn}
        emailVerified={emailVerified}
        approved={approved}
      />
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4 mb-dropdown">
        <Container className="mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="h4 text-success font-weight-bold mb-4">
              Request Acquirer ID
            </h3>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Details</small>
            </div>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup
                  className={classNames({ "has-danger": errors.description })}
                >
                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Description"
                        type="text"
                        name="description"
                        {...field}
                        invalid={!!errors.description}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Company</small>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    control={control}
                    name="company"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CompanySelect {...field} myCompanies />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="company"
                    message="Please select a company"
                    as={<span></span>}
                    className="text-danger"
                  />
                  <div>
                    If you're company is not listed here, please first request
                    one <Link to="/request/company">here</Link>.
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <Button color="success" size="lg" type="submit">
                  Request ID
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

type FormData = {
  description: string;
  company: CompanyDto;
};

export default RequestAcquirerId;
