import React from "react";
import { Badge } from "reactstrap";
import confirm from "reactstrap-confirm";
import { updateApproval } from "../../../common/userFunctions";
import { toast } from "react-toastify";

const ApprovalBadge = ({ isApproved, userId }) => {
  const approve = async () => {
    let confirmed = await confirm({
      title: "Approval",
      message: "Please confirm approval",
      confirmText: "confirm",
    });

    if (confirmed) {
      updateApproval(userId, true);
      toast.success("approved");
    }
  };

  const disapproval = async () => {
    let confirmed = await confirm({
      title: "Disapproval",
      message: "Please confirm disapproval",
      confirmText: "confirm",
    });

    if (confirmed) {
      updateApproval(userId, false);
      toast.success("disapproved");
    }
  };

  if (isApproved) {
    return (
      <Badge color="success" onClick={disapproval} className="pointer">
        approved
      </Badge>
    );
  }

  return (
    <Badge color="warning" onClick={approve} className="pointer">
      pending
    </Badge>
  );
};

export default ApprovalBadge;
