import React, { useState } from "react";
import Hero from "../../../components/Hero";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  Label,
  InputGroupAddon,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import {
  CreateRequestRequest,
  ContactPersonDto,
  LookupAcquirerRequest,
  AcquirerLookupDto,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { useSimplux } from "@simplux/react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { user } from "pages/user/user.module";
import SelectContactPersonTable from "pages/request-forms/SelectContactPersonTable";
import classNames from "classnames";
import NotAllowedToRegister from "../NotAllowedToRegister";
import { ErrorMessage } from "@hookform/error-message";

const createOptionFromDto = (dto: AcquirerLookupDto) => {
  if (!dto || !dto.id) {
    return undefined;
  }

  return {
    value: dto.id,
    label: `${dto.acquirerId} ${dto.companyName || ""}`,
    dto: dto,
  };
};

const lookupAcquirers = (inputValue: string) => {
  return new Promise((resolve) => {
    let request = new LookupAcquirerRequest();
    request.search = inputValue;
    request.pageSize = 10000;
    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.rows.map((dto) => {
        return createOptionFromDto(dto);
      });

      resolve(options);
    });
  });
};

const RequestApplicationId = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const emailVerified = useSimplux(user.emailVerified);
  const approved = useSimplux(user.approved);

  const [createNewContactPerson, setCreateNewContactPerson] = useState(
    !isLoggedIn
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const history = useHistory();

  const acquirer = watch("acquirer");

  const onSubmit = (data: FormData) => {
    let request = new CreateRequestRequest();
    request.type = "Application";
    request.description = data.description;
    request.acquirerId = acquirer.value;

    request.contactPerson = new ContactPersonDto();

    if (createNewContactPerson) {
      request.contactPerson.firstName = data.firstName;
      request.contactPerson.lastName = data.lastName;
      request.contactPerson.email = data.email;
      request.contactPerson.phone = data.phone;
    } else {
      request.contactPerson.id = data.contactPersonId;
    }

    const client = getApiClient();
    client
      .post(request)
      .then(() => {
        history.push("/request/complete");
      })
      .catch(() => toast.error("Error sending request"));
  };

  if (isLoggedIn && (!emailVerified || !approved)) {
    return (
      <NotAllowedToRegister
        isLoggedIn={isLoggedIn}
        emailVerified={emailVerified}
        approved={approved}
      />
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4 mb-dropdown">
        <Container className="mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="h4 text-success font-weight-bold mb-4">
              Request Application ID
            </h3>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Data</small>
            </div>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup
                  className={classNames({ "has-danger": errors.description })}
                >
                  <Input
                    placeholder="Description"
                    type="text"
                    name="description"
                    {...register("description", { required: true })}
                    className={classNames({ "is-invalid": errors.description })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Acquirer
              </small>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    name="acquirer"
                    render={({ field }) => (
                      <AsyncSelect
                        loadOptions={lookupAcquirers}
                        isClearable
                        name="acquirer"
                        placeholder="Select acquirer..."
                        control={control}
                        rules={{ required: true }}
                        defaultOptions
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="acquirer"
                    message="Please select an acquirer"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Contact Person
              </small>
            </div>
            <Row>
              <Col md="6">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="createNewContactPerson"
                      defaultChecked={createNewContactPerson}
                      onChange={() => setCreateNewContactPerson(true)}
                    />
                    Create new contact person
                  </Label>
                </FormGroup>
              </Col>
              {isLoggedIn && (
                <Col md="6">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="createNewContactPerson"
                        defaultChecked={!createNewContactPerson}
                        onChange={() => setCreateNewContactPerson(false)}
                      />
                      Choose existing
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>

            {createNewContactPerson && (
              <>
                <Row>
                  <Col>
                    <FormGroup
                      className={classNames({ "has-danger": errors.firstName })}
                    >
                      <Input
                        placeholder="First name"
                        type="text"
                        name="firstName"
                        {...register("firstName", { required: true })}
                        className={classNames({
                          "is-invalid": errors.firstName,
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      className={classNames({ "has-danger": errors.lastName })}
                    >
                      <Input
                        placeholder="Last name"
                        type="text"
                        {...register("lastName", { required: true })}
                        className={classNames({
                          "is-invalid": errors.lastName,
                        })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup
                      className={classNames({ "has-danger": errors.email })}
                    >
                      <InputGroup
                        className={classNames("mb-4", {
                          "is-invalid": errors.email,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-Mail"
                          type="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      className={classNames({ "has-danger": errors.phone })}
                    >
                      <InputGroup
                        className={classNames("mb-4", {
                          "is-invalid": errors.phone,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-phone"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Phone"
                          type="text"
                          name="phone"
                          {...register("phone", { required: true })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {createNewContactPerson === false && (
              <>
                <Row>
                  <Col>
                    <Controller
                      name="contactPersonId"
                      render={({ field }) => (
                        <SelectContactPersonTable
                          name="contactPersonId"
                          acquirerId={acquirer?.value}
                          {...field}
                        />
                      )}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="contactPersonId"
                      message="Please make a selection"
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col lg="6" md="6">
                <Button color="success" size="lg" type="submit">
                  Request ID
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

type FormData = {
  description: string;
  createNewContactPerson: boolean;
  acquirer: AcquirerLookupDto;
  contactPersonId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export default RequestApplicationId;
