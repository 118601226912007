import React from "react";
import { CompanyDto } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";

type PropTypes = {
  dto: CompanyDto;
};

const CompanyDisplay = (props: PropTypes) => {
  return (
    <>
      <strong>
        <FontAwesomeIcon icon={faIndustry} /> Company
      </strong>
      <br />
      {props.dto.name}
      <br />
      {props.dto.street1} {props.dto.street2}
      <br />
      {props.dto.postCode} {props.dto.city}
    </>
  );
};

export default CompanyDisplay;
