import React, { forwardRef } from "react";
import { CompanyDto, GetAssignedCompanies, GetCompaniesRequest } from "api";
import AsyncSelect from "react-select/async";
import getApiClient from "common/ApiClientFactory";

type CompanySelectProps = {
  onChange: (selection: CompanyDto) => void;
  value?: CompanyDto;
  myCompanies?: boolean;
};

const CompanySelect = forwardRef<any, CompanySelectProps>((props, ref) => {
  const handleSelection = (selection: any) => {
    if (!selection) {
      props.onChange(null);
    } else {
      props.onChange(selection.dto);
    }
  };

  return (
    <AsyncSelect
      defaultOptions
      loadOptions={props.myCompanies ? lookupMyCompanies : lookupCompanies}
      onChange={handleSelection}
      isClearable
      placeholder="Select company..."
      ref={ref}
      value={createOptionFromDto(props.value)}
    />
  );
});

const lookupCompanies = (inputValue: string) => {
  return new Promise((resolve) => {
    let request = new GetCompaniesRequest();
    request.search = inputValue;
    request.pageSize = 25;
    request.orderBy = "Name";

    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.rows.map((company) => {
        return createOptionFromDto(company);
      });

      resolve(options);
    });
  });
};

const lookupMyCompanies = () => {
  return new Promise((resolve) => {
    let request = new GetAssignedCompanies();
    request.types = ["Supplier", "Acquirer"];

    let client = getApiClient();

    client.get(request).then((response) => {
      let options = response.map((dto) => {
        return createOptionFromDto(dto);
      });

      resolve(options);
    });
  });
};

const createOptionFromDto = (dto: CompanyDto) => {
  if (!dto || !dto.id) {
    return undefined;
  }

  return {
    value: dto.id,
    label: `${dto.name}, ${dto.street1}, ${dto.postCode} ${dto.city}`,
    dto: dto,
  };
};

export default CompanySelect;
