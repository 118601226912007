import React from "react";
import { RequestDto, ApprovePMSIdRequest } from "api";
import { Col, Container, Row, Button, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { toDateTime } from "common/date";
import getApiClient from "common/ApiClientFactory";
import { toast } from "react-toastify";
import ApprovalState from "./ApprovalState";
import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";
import { to11 } from "common/utils";

type PMSRequestProps = {
  request: RequestDto;
  dataChanged: () => void;
};

const PMSRequest = (props: PMSRequestProps) => {
  const isAdmin = useSimplux(user.isAdmin);

  const dispatch = (approve: boolean) => {
    let request = new ApprovePMSIdRequest();
    request.approve = approve;
    request.requestId = props.request.id;

    let client = getApiClient();
    client.post(request).then(() => {
      if (approve) {
        toast.success("approved");
      } else {
        toast.success("rejected");
      }

      props.dataChanged();
    });
  };

  return (
    <>
      <Row className="mb-4">
        <Col lg="8">
          <div className="col-lg-12">
            <div className=" shadow border-0 card">
              <div className="py-5 card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="text-primary text-uppercase">
                      <i className="ni ni-shop"></i> PMS ID
                    </h6>
                  </div>
                  <div>
                    {props.request.done && (
                      <ApprovalState approved={props.request.approved} />
                    )}
                    {toDateTime(props.request.createdAt)}
                  </div>
                </div>
                <Container fluid>
                  <Row>
                    <Col md="6">
                      <address>
                        <strong>Company</strong>
                        <br />
                        {props.request.company.name}
                        <br />
                        {props.request.company.street1}
                        <br />
                        {props.request.company.street2}
                        {props.request.company.street2 && <br />}
                        {props.request.company.postCode}{" "}
                        {props.request.company.city}
                        <br />
                        {props.request.company.country}
                        <br />
                      </address>
                      {props.request.description && (
                        <>
                          <strong>Description: </strong>{" "}
                          {props.request.description}
                        </>
                      )}
                    </Col>
                    <Col md="6">
                      <strong>Requester</strong>
                      <br />
                      {props.request.requester.firstName}{" "}
                      {props.request.requester.lastName}
                      <br />
                      <FontAwesomeIcon icon={faPhone} />{" "}
                      {props.request.requester.phone}
                      <br />
                      <FontAwesomeIcon icon={faMailBulk} />{" "}
                      {props.request.requester.email}
                      <br />
                    </Col>
                  </Row>
                  {!props.request.done && isAdmin && (
                    <>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            color="success"
                            onClick={() => dispatch(true)}
                          >
                            Approve
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => dispatch(false)}
                          >
                            Reject
                          </Button>
                          <Badge
                            color="primary"
                            className="float-right text-huge"
                          >
                            {to11(props.request.nextId)}
                          </Badge>
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PMSRequest;
