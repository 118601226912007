import React from "react";
import Hero from "components/Hero";
import ContactPeopleTable from "./ContactPeopleTable";

const ContactPeople = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <ContactPeopleTable />
      </section>
    </>
  );
};

export default ContactPeople;
