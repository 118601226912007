import React from "react";
import Hero from "../../components/Hero";
import { Row, Col, Alert, Button } from "reactstrap";
import { useSimplux } from "@simplux/react";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import { verifyEmail } from "../../common/userFunctions";
import BarLoader from "react-spinners/BarLoader";
import { emailverification } from "./emailverification.module";

const VerifyEmail = () => {
  const { id } = useParams<any>();

  const emailVerficationError = useSimplux(
    emailverification.emailVerficationError
  );
  const emailVerificationSuccess = useSimplux(
    emailverification.emailVerificationSuccess
  );

  const { promiseInProgress } = usePromiseTracker();

  const verifyClick = () => {
    if (promiseInProgress) {
      return;
    }

    trackPromise(
      verifyEmail(id)
        .then(() => emailverification.setSuccess())
        .catch(() => emailverification.setError())
    );
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <div className="d-flex justify-content-between">
          <h3>E-Mail verification</h3>
        </div>
        <Row>
          <Col lg="12">
            <p className="lead mb-3">
              Thank you for verifying your E-Mail address. Please click the
              following button to complete the process.
            </p>
            <Button
              color="primary"
              size="lg"
              type="button"
              onClick={verifyClick}
            >
              Verify E-Mail
            </Button>
            <BarLoader color="rgb(54, 215, 183)" loading={promiseInProgress} />

            {emailVerificationSuccess && (
              <Alert color="success mt-2">
                <span className="alert-inner--icon">
                  <i className="ni ni-check-bold" />
                </span>
                <span className="alert-inner--text">
                  <strong>Verified!</strong> Your E-Mail has been verified.
                  After approval by an administrator you will be able to
                  register Acquirer IDs, PMS IDs and Service Center IDs.
                </span>
              </Alert>
            )}

            {emailVerficationError && (
              <Alert color="danger mt-2">
                <span className="alert-inner--icon">
                  <i className="fa fa-exclamation-triangle" />
                </span>
                <span className="alert-inner--text">
                  <strong>Verification failed</strong> Please use the link
                  provided by E-Mail.
                </span>
              </Alert>
            )}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default VerifyEmail;
