import React from "react";
import Hero from "../../../components/Hero";
import ServiceCenterTable from "./ServiceCenterTable";

function ServiceCenters() {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <ServiceCenterTable />
      </section>
    </>
  );
}

export default ServiceCenters;
