import { useSimplux } from "@simplux/react";
import {
  GetTerminalRangeRequest,
  TerminalRangeDto,
  UpdateTerminalRange,
} from "api";
import getApiClient from "common/ApiClientFactory";
import Hero from "components/Hero";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, FormGroup, Input, Row } from "reactstrap";

const TerminalRangeDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  const { id, serviceCenterId } = useParams<any>();
  const [dto, setDto] = useState<TerminalRangeDto>();

  const { handleSubmit, control, reset } = useForm<TerminalRangeDto>();

  const onSubmit = async (data) => {
    let client = getApiClient();
    let request = new UpdateTerminalRange();

    request.id = id;
    request.description = data.description;
    request.terminalRangeId = data.terminalRangeId;

    client
      .put(request)
      .then(() => {
        toast.success("Terminal Range updated");
      })
      .catch(() => {
        toast.error("Error");
      });
  };

  useEffect(() => {
    async function async() {
      const client = getApiClient();
      let request = new GetTerminalRangeRequest();

      request.id = id;
      let dto = await client.get(request);

      reset(dto);
      setDto(dto);

      console.log("fetched ", dto);
    }

    async();
  }, [id, reset]);

  const backLink = serviceCenterId
    ? `/service-centers/${serviceCenterId}`
    : "/search";

  return (
    <>
      <Hero />
      <section className="section mb-dropdown m-4">
        {dto && (
          <Container className="mb-5">
            <h3>
              <Link to={backLink}>
                <i className="ni ni-bold-left"></i>
              </Link>
              Terminal Range{" "}
              <small className="text-primary">{dto.terminalRangeId}</small>
            </h3>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Details
                  </small>
                </div>
                {isAdmin && (
                  <Row>
                    <Col lg="6" sm="12">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="terminalRangeId"
                          render={({ field }) => (
                            <Input
                              placeholder="Terminal Range ID"
                              type="number"
                              min={1}
                              max={9999}
                              maxLength={4}
                              {...field}
                              rules={{ required: true, max: 9999 }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Controller
                        control={control}
                        name="description"
                        render={({ field }) => (
                          <Input
                            placeholder="Description"
                            type="text"
                            name="description"
                            rules={{ required: true }}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4 mt-2">
                  <Col>
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            </>
          </Container>
        )}
      </section>
    </>
  );
};

export default TerminalRangeDetail;
