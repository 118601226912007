import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CompanyDto,
  CreateNonceOffset,
  DataFlowDirection,
  DeleteNonceOffset,
  GetNonceOffset,
  KeyInterface,
  NonceOffsetDto,
  NonceOffsetType,
  UpdateNonceOffset,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix } from "common/utils";
import CompanySelect from "components/CompanySelect";
import Hero from "components/Hero";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import { number, object, string, z } from "zod";

const NonceOffsetDetail = () => {
  let { id } = useParams<any>();

  const request = new GetNonceOffset({
    id: `NonceOffsets/${id}`,
  });

  const { data, isLoading } = useQuery(
    [request, "nonce_detail"],
    () => {
      return getApiClient().get(request);
    },
    { enabled: id !== "new" }
  );

  if (isLoading) {
    return null;
  }

  return <NonceOffsetForm data={data ?? new NonceOffsetDto()} />;
};

const schema = object({
  type: string().min(1, { message: "required" }),
  name: string().min(1, { message: "required" }),
  xmlTagAndNamespace: string().min(1, { message: "required" }),
  encryption: string(),
  format: string().min(1, { message: "required" }),
  length: number().gte(0),
  interface: string().min(1, { message: "required" }),
  description: string(),
  value: string()
    .min(2, { message: "required" })
    .max(2)
    .refine(
      (value) => {
        let number = parseInt(value, 16);

        return number >= 160 && number <= 255;
      },
      { message: "value must be between A0-FF" }
    ),
  dataFlowDirection: string().min(1, { message: "required" }),
  company: object({
    id: string(),
  }).required(),
});

type FormData = z.infer<typeof schema>;

const NonceOffsetForm = ({ data }: { data: NonceOffsetDto }) => {
  const methods = useForm<FormData>({
    defaultValues: data,
    resolver: zodResolver(schema),
  });

  const { handleSubmit, register, control } = methods;

  const history = useHistory();
  const queryClient = useQueryClient();

  const onSubmit = async (d: FormData) => {
    let client = getApiClient();

    if (!data.id) {
      let request = new CreateNonceOffset({
        type: d.type as NonceOffsetType,
        name: d.name,
        xmlTagAndNamespace: d.xmlTagAndNamespace,
        format: d.format,
        encryption: d.encryption,
        length: d.length,
        interface: d.interface as KeyInterface,
        dataFlowDirection: d.dataFlowDirection as DataFlowDirection,
        value: d.value,
        description: d.description,
        companyId: d.company.id,
      });

      await client.post(request);

      toast.success("Nonce offset created");
      history.push("/admin/nonce-offsets");
    } else {
      let request = new UpdateNonceOffset({
        id: data.id,
        type: d.type as NonceOffsetType,
        name: d.name,
        xmlTagAndNamespace: d.xmlTagAndNamespace,
        format: d.format,
        encryption: d.encryption,
        length: d.length,
        interface: d.interface as KeyInterface,
        dataFlowDirection: d.dataFlowDirection as DataFlowDirection,
        value: d.value,
        description: d.description,
        companyId: d.company.id,
      });

      await client.put(request);

      toast.success("Nonce offset updated");
      queryClient.clear();
      history.push("/admin/nonce-offsets");
    }
  };

  const onDeleteClick = async () => {
    let confirmed = await confirm({
      title: "Confirmation",
      message: "Do you really want to delete this nonce offset?",
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DeleteNonceOffset();
      request.id = removeCollectionPrefix(data.id);

      let client = getApiClient();
      await client.delete(request);
      toast.success("Nonce offset deleted");
      history.push("/admin/nonce-offsets");
    }
  };

  return (
    <>
      <Hero />
      <section className="section mb-dropdown m-4">
        <Container className="mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between">
              <div>
                <h3>
                  Nonce Offset{" "}
                  <small className="text-primary">{data.name}</small>
                </h3>
              </div>
              <div>
                {data.id && (
                  <Button color="danger" onClick={onDeleteClick}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </div>
            </div>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Details</small>
            </div>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Input type="select" id="type" {...field}>
                        <option></option>
                        <option value="Sender">Sender</option>
                        <option value="Receiver">Receiver</option>
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="name">Name</Label>
                  <input
                    {...register("name")}
                    type="text"
                    id="name"
                    className="form-control pl-2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="xmlTagAndNamespace">XML Tag and Namespace</Label>
                  <input
                    {...register("xmlTagAndNamespace")}
                    type="text"
                    id="value"
                    className="form-control pl-2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="format">Format</Label>
                  <input
                    {...register("format")}
                    type="text"
                    id="format"
                    className="form-control pl-2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Label for="format">Encryption</Label>
                  <Controller
                    name="encryption"
                    control={control}
                    render={({ field }) => (
                      <Input type="select" id="encryption" {...field}>
                        <option></option>
                        <option>ea</option>
                        <option>eb</option>
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="length">Length</Label>
                  <input
                    {...register("length", { valueAsNumber: true })}
                    type="number"
                    id="length"
                    className="form-control pl-2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Label for="format">Interface</Label>
                  <Controller
                    name="interface"
                    control={control}
                    render={({ field }) => (
                      <Input type="select" id="interface" {...field}>
                        <option></option>
                        <option>BE</option>
                        <option>COI</option>
                        <option>FE</option>
                        <option>MCI</option>
                        <option>MDI</option>
                        <option>MI</option>
                        <option>SI_CONF</option>
                        <option>SI_INIT</option>
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Label for="dataFlowDirection">Direction of data flow</Label>
                  <Controller
                    name="dataFlowDirection"
                    control={control}
                    render={({ field }) => (
                      <Input type="select" id="dataFlowDirection" {...field}>
                        <option></option>
                        <option value="MasterToSlave">
                          Master-{">"}Slave (Nonce Variant Encryption Send)
                        </option>
                        <option value="SlaveToMaster">
                          Slave-{">"}Master (Nonce Variant Encryption Receive)
                        </option>
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="value">Offset Value</Label>
                  <InputGroup className="input-group-dark">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>0x</InputGroupText>
                    </InputGroupAddon>
                    <input
                      {...register("value")}
                      type="text"
                      id="value"
                      className="form-control pl-2"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="description">Description</Label>
                  <input
                    {...register("description")}
                    type="text"
                    id="description"
                    className="form-control pl-2"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Assigned Company
              </small>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    control={control}
                    name="company"
                    render={({ field }) => (
                      <>
                        <CompanySelect
                          onChange={field.onChange}
                          ref={field.ref}
                          value={field.value as CompanyDto}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button type="submit" color="primary">
                  {data.id ? "Save" : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

export default NonceOffsetDetail;
