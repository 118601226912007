import React from "react";
import Hero from "../../../components/Hero";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import { CreateRequestRequest, CompanyDto, ContactPersonDto } from "api";
import getApiClient from "common/ApiClientFactory";
import { useSimplux } from "@simplux/react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { user } from "pages/user/user.module";
import classNames from "classnames";
import NotAllowedToRegister from "../NotAllowedToRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const RequestCompany = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const emailVerified = useSimplux(user.emailVerified);
  const approved = useSimplux(user.approved);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      street1: "",
      street2: "",
      postCode: undefined,
      city: "",
      country: "",
      phone: "",
      email: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonPhone: "",
      contactPersonEmail: "",
    },
  });
  const history = useHistory();

  const onSubmit = (data: FormData) => {
    let request = new CreateRequestRequest();
    request.type = "Company";
    request.company = new CompanyDto();
    request.company.name = data.name;
    request.company.street1 = data.street1;
    request.company.street2 = data.street2;
    request.company.postCode = data.postCode;
    request.company.city = data.city;
    request.company.country = data.country;
    request.contactPerson = new ContactPersonDto();
    request.contactPerson.firstName = data.contactPersonFirstName;
    request.contactPerson.lastName = data.contactPersonLastName;
    request.contactPerson.phone = data.contactPersonPhone;
    request.contactPerson.email = data.contactPersonEmail;

    const client = getApiClient();
    client
      .post(request)
      .then(() => {
        history.push("/request/complete");
      })
      .catch(() => toast.error("Error sending request"));
  };

  if (!isLoggedIn || !emailVerified || !approved) {
    return (
      <NotAllowedToRegister
        isLoggedIn={isLoggedIn}
        emailVerified={emailVerified}
        approved={approved}
      />
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4 mb-dropdown">
        <Container className="mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="h4 text-success font-weight-bold mb-4">
              Request Company
            </h3>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Company Information
              </small>
            </div>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Name"
                        type="text"
                        {...field}
                        invalid={!!errors.name}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="street1"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Address 1"
                        type="text"
                        {...field}
                        invalid={!!errors.street1}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="street2"
                    render={({ field }) => (
                      <Input placeholder="Address 2" type="text" {...field} />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2" sm="4">
                <FormGroup>
                  <Controller
                    control={control}
                    name="postCode"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Post Code"
                        type="number"
                        {...field}
                        invalid={!!errors.postCode}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="8">
                <FormGroup>
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="City"
                        type="text"
                        name="city"
                        {...field}
                        invalid={!!errors.city}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <FormGroup
                  className={classNames({ "has-danger": errors.country })}
                >
                  <Controller
                    control={control}
                    name="country"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Country"
                        type="text"
                        name="country"
                        {...field}
                        invalid={!!errors.country}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Contact Person
              </small>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    control={control}
                    name="contactPersonFirstName"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="First name"
                        type="text"
                        name="contactPersonFirstName"
                        {...field}
                        invalid={!!errors.contactPersonFirstName}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Controller
                    control={control}
                    name="contactPersonLastName"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Last name"
                        type="text"
                        name="contactPersonLastName"
                        {...field}
                        invalid={!!errors.contactPersonLastName}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup
                    className={classNames("mb-4", {
                      "is-invalid": errors.contactPersonEmail,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      name="contactPersonEmail"
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          placeholder="E-Mail"
                          type="email"
                          name="contactPersonEmail"
                          {...field}
                          invalid={!!errors.contactPersonEmail}
                        />
                      )}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup
                    className={classNames("mb-4", {
                      "is-invalid": errors.contactPersonPhone,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Controller
                      control={control}
                      name="contactPersonPhone"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Phone"
                          type="text"
                          name="contactPersonPhone"
                          {...field}
                          invalid={!!errors.contactPersonPhone}
                        />
                      )}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6">
                <Button color="success" size="lg" type="submit">
                  Request
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

type FormData = {
  name: string;
  street1: string;
  street2: string;
  postCode: number;
  city: string;
  country: string;
  phone: string;
  email: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
};

export default RequestCompany;
