import { useSimplux } from "@simplux/react";
import { GetCurrentUserRequest } from "api";
import "App.scss";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/vendor/nucleo/css/nucleo.css";
import getApiClient from "common/ApiClientFactory";
import TopNavbar from "components/layout/TopNavbar";
import StartupApp from "components/StartupApp";
import AcquirerDetail from "pages/admin/acquirer/AcquirerDetail";
import Acquirers from "pages/admin/acquirer/Acquirers";
import ApplicationDetail from "pages/admin/applications/ApplicationDetail";
import Applications from "pages/admin/applications/Applications";
import Companies from "pages/admin/company/Companies";
import CompanyDetail from "pages/admin/company/CompanyDetail";
import ContactPeople from "pages/admin/contact-people/ContactPeople";
import ContactPersonDetail from "pages/admin/contact-people/ContactPersonDetail";
import KernelDetail from "pages/admin/kernels/KernelDetail";
import Kernels from "pages/admin/kernels/Kernels";
import MerchantRangeDetail from "pages/admin/MerchantRanges/MerchantRangeDetail";
import MerchantRanges from "pages/admin/MerchantRanges/MerchantRanges";
import NonceOffsetDetail from "pages/admin/nonce-offsets/NonceOffsetDetail";
import NonceOffsets from "pages/admin/nonce-offsets/NonceOffsets";
import PMS from "pages/admin/pms/PMS";
import PMSDetail from "pages/admin/pms/PMSDetail";
import Requests from "pages/admin/requests/Requests";
import Search from "pages/admin/search/Search";
import ServiceCenterDetail from "pages/admin/service-center/ServiceCenterDetail";
import ServiceCenters from "pages/admin/service-center/ServiceCenters";
import TerminalSupplierDetail from "pages/admin/terminal-suppliers/TerminalSupplierDetails";
import TerminalSuppliers from "pages/admin/terminal-suppliers/TerminalSuppliers";
import TerminalRangeDetail from "pages/admin/TerminalRanges/TerminalRangeDetail";
import UserDetail from "pages/admin/user-management/UserDetail";
import UserManagement from "pages/admin/user-management/UserManagement";
import Downloads from "pages/Downloads";
import ForgotPassword from "pages/ForgotPassword";
import MyAcquirers from "pages/MyAcquirers";
import MyCompanies from "pages/MyCompanies";
import MyPMS from "pages/MyPMS";
import MyServiceCenters from "pages/MyServiceCenters";
import MyTerminalSuppliers from "pages/MyTerminalSuppliers";
import Notifications from "pages/Notifications";
import PasswordReset from "pages/PasswordReset";
import Register from "pages/registration/Register";
import RegisterComplete from "pages/registration/RegisterComplete";
import RequestAcquirerId from "pages/request-forms/acquirer/RequestAcquirerId";
import RequestApplicationId from "pages/request-forms/application/RequestApplicationId";
import RequestCompany from "pages/request-forms/company/RequestCompany";
import RequestPmsId from "pages/request-forms/pms/RequestPmsId";
import RequestComplete from "pages/request-forms/RequestComplete";
import RequestServiceCenterId from "pages/request-forms/service-center/RequestServiceCenterId";
import RequestTerminalSupplierId from "pages/request-forms/terminal-supplier/RequestTerminalSupplierId";
import Login from "pages/user/Login";
import { user } from "pages/user/user.module";
import VerifyEmail from "pages/user/VerifiyEmail";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  toast.configure({ closeButton: false, autoClose: 2000 });

  const isInitialized = useSimplux(user.isInitialized);
  const isLoggedIn = useSimplux(user.isLoggedIn);

  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      let client = getApiClient();
      let request = new GetCurrentUserRequest();

      let response = await client.get(request);

      if (response.userId) {
        user.setIsLoggedIn(
          response.firstName,
          response.lastName,
          response.userId,
          response.roles,
          response.emailVerified,
          response.approved,
          response.level,
          response.hasCompanyAssigned
        );
      } else {
        user.setIsNotLoggedIn();

        if (
          window.location.href.indexOf("reset-password") !== -1 ||
          window.location.href.indexOf("register") !== -1 ||
          window.location.href.indexOf("verify-email") !== -1 ||
          window.location.href.indexOf("forgot-password") !== -1
        ) {
        } else {
          history.push("/login");
        }
      }
    }

    if (!isInitialized) {
      loadUser();
    }
  }, [isInitialized, history]);

  return (
    <>
      {isInitialized && isLoggedIn && <TopNavbar />}
      {isInitialized && (
        <Switch>
          {/* PUBLIC routes */}
          <Route path="/" exact render={() => <Requests />} />
          <Route path="/register" exact render={() => <Register />} />
          <Route
            path="/register/complete"
            exact
            render={() => <RegisterComplete />}
          />
          <Route
            path="/verify-email/:id"
            exact
            render={() => <VerifyEmail />}
          />
          <Route path="/login" exact render={() => <Login />} />
          <Route path="/companies" exact render={() => <MyCompanies />} />
          <Route path="/companies/:id" exact render={() => <CompanyDetail />} />
          <Route
            path="/companies/:companyId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route
            path="/service-centers"
            exact
            render={() => <MyServiceCenters />}
          />
          <Route
            path="/service-centers/:id"
            exact
            render={() => <ServiceCenterDetail />}
          />
          <Route
            path="/terminal-suppliers"
            exact
            render={() => <MyTerminalSuppliers />}
          />
          <Route
            path="/service-centers/:serviceCenterId/terminal-suppliers/:id"
            exact
            render={() => <TerminalSupplierDetail />}
          />
          <Route
            path="/service-centers/:serviceCenterId/merchant-ranges/:id"
            exact
            render={() => <MerchantRangeDetail />}
          />
          <Route
            path="/service-centers/:serviceCenterId/terminal-ranges/:id"
            exact
            render={() => <TerminalRangeDetail />}
          />
          <Route
            path="/terminal-suppliers/:terminalSupplierId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route path="/acquirers" exact render={() => <MyAcquirers />} />
          <Route
            path="/acquirers/:id"
            exact
            render={() => <AcquirerDetail />}
          />
          <Route
            path="/acquirers/:acquirerId/applications/:id"
            exact
            render={() => <ApplicationDetail />}
          />
          <Route
            path="/acquirers/:acquirerId/applications/:applicationId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route path="/pms" exact render={() => <MyPMS />} />
          <Route path="/pms/:id" exact render={() => <PMSDetail />} />
          <Route
            path="/request/acquirer-id"
            exact
            render={() => <RequestAcquirerId />}
          />
          <Route
            path="/request/terminal-supplier-id"
            exact
            render={() => <RequestTerminalSupplierId />}
          />
          <Route
            path="/request/application-id"
            exact
            render={() => <RequestApplicationId />}
          />
          <Route
            path="/request/service-center-id"
            exact
            render={() => <RequestServiceCenterId />}
          />
          <Route path="/request/pms-id" exact render={() => <RequestPmsId />} />
          <Route
            path="/request/company"
            exact
            render={() => <RequestCompany />}
          />
          <Route
            path="/request/complete"
            exact
            render={() => <RequestComplete />}
          />
          <Route path="/requests" exact render={() => <Requests />} />
          <Route path="/downloads" exact render={() => <Downloads />} />
          <Route
            path="/forgot-password"
            exact
            render={() => <ForgotPassword />}
          />
          <Route
            path="/reset-password/:token"
            exact
            render={() => <PasswordReset />}
          />
          <Route path="/notifications" exact render={() => <Notifications />} />

          {/* ADMIN routes */}
          <Route path="/admin/requests" exact render={() => <Requests />} />
          <Route path="/admin/companies" exact render={() => <Companies />} />
          <Route
            path="/admin/companies/:id"
            exact
            render={() => <CompanyDetail />}
          />
          <Route
            path="/admin/terminal-suppliers"
            exact
            render={() => <TerminalSuppliers />}
          />
          <Route
            path="/terminal-suppliers/:id"
            exact
            render={() => <TerminalSupplierDetail />}
          />
          <Route
            path="/admin/terminal-suppliers/:terminalSupplierId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route
            path="/admin/service-centers"
            exact
            render={() => <ServiceCenters />}
          />
          <Route path="/admin/acquirers" exact render={() => <Acquirers />} />
          <Route
            path="/admin/acquirers/:id"
            exact
            render={() => <AcquirerDetail />}
          />
          <Route
            path="/admin/applications"
            exact
            render={() => <Applications />}
          />
          <Route
            path="/search/applications/:id"
            exact
            render={() => <ApplicationDetail />}
          />
          <Route
            path="/admin/applications/:applicationId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route
            path="/admin/contact-people"
            exact
            render={() => <ContactPeople />}
          />
          <Route
            path="/admin/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route
            path="/admin/companies/:companyId/contact-people/:id"
            exact
            render={() => <ContactPersonDetail />}
          />
          <Route path="/admin/pms" exact render={() => <PMS />} />
          <Route path="/admin/pms/:id" exact render={() => <PMSDetail />} />
          <Route path="/admin/kernels" exact render={() => <Kernels />} />
          <Route
            path="/admin/kernels/:id"
            exact
            render={() => <KernelDetail />}
          />
          <Route
            path="/admin/nonce-offsets"
            exact
            render={() => <NonceOffsets />}
          />
          <Route
            path="/admin/nonce-offsets/:id"
            exact
            render={() => <NonceOffsetDetail />}
          />
          <Route
            path="/admin/user-management"
            exact
            render={() => <UserManagement />}
          />
          <Route path="/users/:id" exact render={() => <UserDetail />} />
          <Route
            path="/admin/merchant-ranges"
            exact
            render={() => <MerchantRanges />}
          />
          <Route path="/search" exact render={() => <Search />} />
          <Route
            path="/search/merchant-ranges/:id"
            exact
            render={() => <MerchantRangeDetail />}
          />
          <Route
            path="/search/terminal-ranges/:id"
            exact
            render={() => <TerminalRangeDetail />}
          />
          <Route
            path="/search/terminal-suppliers/:id"
            exact
            render={() => <TerminalSupplierDetail />}
          />
          <Route
            path="/search/companies/:id"
            exact
            render={() => <CompanyDetail />}
          />
          <Route
            path="/search/:searchType/:id"
            exact
            render={() => <AcquirerDetail />}
          />

          <Redirect to="/" />
        </Switch>
      )}
      {!isInitialized && <StartupApp />}
    </>
  );
}

export default App;
