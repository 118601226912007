import React, { useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import Hero from "components/Hero";
import { Controller, useForm } from "react-hook-form";
import { ResetPassword } from "api";
import getApiClient from "common/ApiClientFactory";
import { useParams } from "react-router-dom";

const PasswordReset = () => {
  const { token } = useParams<any>();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data: any) => {
    let request = new ResetPassword();
    request.newPassword = data.password;
    request.token = token;

    let client = getApiClient();
    client.post(request).then(() => {
      setSubmitted(true);
    });
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="mb-3">
            <small className="text-uppercase font-weight-bold">
              Reset password
            </small>
          </div>
          {!submitted && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: "You must specify a password",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            {...field}
                          />
                        )}
                      />
                      {errors.password && <p>{errors.password.message}</p>}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password2"
                        rules={{
                          required: "Required",
                          validate: (value) =>
                            value === password.current ||
                            "The passwords do not match",
                        }}
                        render={({ field }) => (
                          <Input
                            placeholder="Password repeat"
                            type="password"
                            autoComplete="off"
                            {...field}
                          />
                        )}
                      />

                      {errors.password2 && <p>{errors.password2.message}</p>}
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary">
                    Set password
                  </Button>
                </Col>
              </Row>
            </form>
          )}
          {submitted && (
            <>
              <Row>
                <Col>Password changed</Col>
              </Row>
            </>
          )}
        </Container>
      </section>
    </>
  );
};

export default PasswordReset;
