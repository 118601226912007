import React from "react";
import Hero from "components/Hero";
import CompanyTable from "./CompanyTable";

const Companies = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <CompanyTable />
      </section>
    </>
  );
};

export default Companies;
