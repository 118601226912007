import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Input, Button } from "reactstrap";
import Hero from "components/Hero";
import { Controller, useForm } from "react-hook-form";
import { RequestPasswordReset } from "api";
import getApiClient from "common/ApiClientFactory";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { email: "" } });

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data: any) => {
    let request = new RequestPasswordReset();
    request.email = data.email;

    let client = getApiClient();
    client
      .post(request)
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        toast.error("Error");
      });
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="mb-3">
            <small className="text-uppercase font-weight-bold">
              Forgot password
            </small>
          </div>
          {!submitted && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="email"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          md={6}
                          placeholder="E-Mail"
                          type="email"
                          {...field}
                          invalid={!!errors.email}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary">
                    Send recovery link
                  </Button>
                </Col>
              </Row>
            </form>
          )}
          {submitted && (
            <>
              <Row>
                <Col>
                  Please check your E-Mail inbox to complete the password
                  recovery process.
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>
    </>
  );
};

export default ForgotPassword;
