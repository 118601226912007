import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
  Table,
  InputGroupAddon,
} from "reactstrap";
import {
  GetContactPeopleRequest,
  ContactPersonDto,
  MergeContactPersonRequest,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { removeCollectionPrefix } from "common/utils";

type ContactPersonMergerProps = {
  targetContactPersonId: string;
};

const ContactPersonMerger = (props: ContactPersonMergerProps) => {
  const [dtos, setDto] = useState<ContactPersonDto[]>();
  const [search, setSearch] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetContactPeopleRequest();
      request.page = 1;
      request.pageSize = 10;
      request.search = search;
      request.orderBy = "Name";
      let response = await client.get(request);

      setDto(
        response.rows.filter(
          (p) =>
            p.id.toLowerCase() !== props.targetContactPersonId.toLowerCase()
        )
      );
    }

    if (search) {
      async();
    } else {
      setDto(new Array<ContactPersonDto>());
    }
  }, [search, props.targetContactPersonId]);

  const merge = (sourceContactPersonId: string) => {
    let request = new MergeContactPersonRequest();
    request.sourceContactPersonId = sourceContactPersonId;
    request.targetContactPersonId = props.targetContactPersonId;

    let client = getApiClient();
    setIsLoading(true);
    client.post(request).then(() => {
      setDto(dtos.filter((p) => p.id !== sourceContactPersonId));
      toast.success("merged");
      setIsLoading(false);
    });
  };

  return (
    <>
      <div className="mb-3 mt-4">
        <small className="text-uppercase font-weight-bold">
          Contact people merging
        </small>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Type to find duplicates..."
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      {dtos && dtos.length > 0 && (
        <Table className="align-items-center table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {dtos.map((dto) => {
              return (
                <tr key={dto.id}>
                  <td>
                    <Link
                      target="_blank"
                      to={`/admin/contact-people/${removeCollectionPrefix(
                        dto.id
                      )}`}
                    >
                      {dto.firstName}
                    </Link>
                  </td>
                  <td>{dto.lastName}</td>
                  <td>{dto.email}</td>
                  <td>{dto.phone}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => merge(dto.id)}
                      disabled={isLoading}
                    >
                      replace with current
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ContactPersonMerger;
