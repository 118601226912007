import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import getApiClient from "common/ApiClientFactory";
import { GetUsersRequest } from "api";

const PendingUsersInfo = () => {
  const [pendingUsers, setPendingUsers] = useState(false);

  useEffect(() => {
    let client = getApiClient();
    let request = new GetUsersRequest();
    request.filterPendingApproval = true;
    request.pageSize = 1;

    client.get(request).then((response) => {
      if (response.totalRows > 0) {
        setPendingUsers(true);
      }
    });
  }, [pendingUsers]);

  return (
    <>
      {pendingUsers && (
        <Alert color="info">
          <strong>Pending Users</strong> waiting for approval.
        </Alert>
      )}
    </>
  );
};

export default PendingUsersInfo;
