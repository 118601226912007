import { yupResolver } from "@hookform/resolvers/yup";
import { GetPublicKeys, UpdatePublicKey } from "api";
import getApiClient from "common/ApiClientFactory";
import KeyInputs from "components/KeyInputs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { dtoToFormData, keyShape } from "validation/key";
import { InferType, object } from "yup";

const formSchema = object({
  beTest: object(keyShape),
  beProd: object(keyShape),
});

type FormData = InferType<typeof formSchema>;

const PmsKeys = ({ pmsId }: { pmsId: string }) => {
  const request = new GetPublicKeys({ targetId: pmsId });

  const { data, isLoading } = useQuery(
    [request],
    () => {
      return getApiClient().get(request);
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  if (isLoading) {
    return null;
  }

  const coiTest = data.find((p) => p.system === "Test" && p.interface === "BE");
  const coiProd = data.find((p) => p.system === "Prod" && p.interface === "BE");

  const formData = {
    beTest: dtoToFormData(coiTest),
    beProd: dtoToFormData(coiProd),
  } as FormData;

  return <PmsKeysForm pmsId={pmsId} keys={formData} />;
};

const PmsKeysForm = ({ pmsId, keys }: { pmsId: string; keys: FormData }) => {
  const methods = useForm<FormData>({
    defaultValues: keys,
    mode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: FormData) => {
    let api = getApiClient();

    let requests = [
      new UpdatePublicKey({
        belongsTo: pmsId,
        interface: "BE",
        system: "Test",
        valuePre630: data.beTest.valuePre630,
        value630: data.beTest.value630,
        valuePost630: data.beTest.valuePost630,
        valuePost7402K: data.beTest.valuePost7402K,
        valuePost7403K: data.beTest.valuePost7403K,
        valuePost7404K: data.beTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: pmsId,
        interface: "BE",
        system: "Prod",
        valuePre630: data.beProd.valuePre630,
        value630: data.beProd.value630,
        valuePost630: data.beProd.valuePost630,
        valuePost7402K: data.beProd.valuePost7402K,
        valuePost7403K: data.beProd.valuePost7403K,
        valuePost7404K: data.beProd.valuePost7404K,
      }),
    ];

    let promises = requests.map((m) => api.put(m));
    Promise.all(promises).then(() => toast.success("Keys updated"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <KeyInputs interface1="BE" testKey="beTest" prodKey="beProd" />
        <Row className="mb-4 mt-4">
          <Col>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

// const KeyInputs = ({
//   interface1,
//   testKey,
//   prodKey,
// }: {
//   interface1: KeyInterface;
//   testKey: "beTest";
//   prodKey: "beProd";
// }) => {
//   const {
//     register,
//     formState: { errors },
//   } = useFormContext<FormData>();

//   return (
//     <>
//       <Row>
//         <Col md="6" className="mb-4">
//           <div className="mb-1">
//             <small className="text-uppercase font-weight-bold">
//               <strong>{interface1}</strong> Prod
//             </small>
//           </div>

//           <Row>
//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&lt; 6.3.0</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${prodKey}.valuePre630`)}
//                     placeholder="Key"
//                     type="text"
//                     className={classNames("form-control pl-2", {
//                       "is-invalid": errors[prodKey]?.valuePre630,
//                     })}
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>= 6.3.0</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${prodKey}.value630`)}
//                     placeholder="Key"
//                     type="text"
//                     className={classNames("form-control pl-2", {
//                       "is-invalid": errors[prodKey]?.value630,
//                     })}
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 6.3.0 2k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${prodKey}.valuePost630`)}
//                     placeholder="Key"
//                     type="text"
//                     className={classNames("form-control pl-2", {
//                       "is-invalid": errors[prodKey]?.valuePost630,
//                     })}
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 7.4.0 3k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${prodKey}.valuePost7403K`)}
//                     placeholder="Key"
//                     type="text"
//                     className={classNames("form-control pl-2", {
//                       "is-invalid": errors[prodKey]?.valuePost7403K,
//                     })}
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>
//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 7.4.0 4k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${prodKey}.valuePost7404K`)}
//                     placeholder="Key"
//                     type="text"
//                     className={classNames("form-control pl-2", {
//                       "is-invalid": errors[prodKey]?.valuePost7404K,
//                     })}
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>
//           </Row>
//         </Col>
//         <Col md="6" className="mb-4">
//           <div className="mb-1">
//             <small className="text-uppercase font-weight-bold">
//               <strong>{interface1}</strong> Test
//             </small>
//           </div>

//           <Row>
//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&lt; 6.3.0</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${testKey}.valuePre630`)}
//                     placeholder="Key"
//                     type="text"
//                     className="form-control pl-2"
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>= 6.3.0</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${testKey}.value630`)}
//                     placeholder="Key"
//                     type="text"
//                     className="form-control pl-2"
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 6.3.0 2k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${testKey}.valuePost630`)}
//                     placeholder="Key"
//                     type="text"
//                     className="form-control pl-2"
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>

//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 7.4.0 3k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${testKey}.valuePost7403K`)}
//                     placeholder="Key"
//                     type="text"
//                     className="form-control pl-2"
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>
//             <Col md="12">
//               <FormGroup>
//                 <InputGroup className="input-group-dark">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>&gt; 7.4.0 4k</InputGroupText>
//                   </InputGroupAddon>
//                   <input
//                     {...register(`${testKey}.valuePost7404K`)}
//                     placeholder="Key"
//                     type="text"
//                     className="form-control pl-2"
//                   />
//                 </InputGroup>
//               </FormGroup>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// };

export default PmsKeys;
