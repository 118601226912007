import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { GetMerchantRangesRequest } from "api";
import getApiClient from "common/ApiClientFactory";
import OrderByToggle from "components/OrderByToggle";
import Pagination from "components/pagination/paginationInternal";
import { copyToClipboard } from "common/utils";
import { merchantRanges } from "./merchant-ranges.module";
import { toDateTime } from "common/date";

const MerchantRangesTable = () => {
  const rows = useSimplux(merchantRanges.rows);
  const page = useSimplux(merchantRanges.page);
  const pageSize = useSimplux(merchantRanges.pageSize);
  const search = useSimplux(merchantRanges.search);
  const orderBy = useSimplux(merchantRanges.orderBy);
  const total = useSimplux(merchantRanges.total);
  const pages = useSimplux(merchantRanges.pages);

  useEffect(() => {
    fetchData(page, pageSize, search, orderBy);
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Merchant Ranges</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) =>
                  merchantRanges.setSearch(event.target.value)
                }
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            defaultValue={pageSize}
            onChange={(event) =>
              merchantRanges.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm table-hover merchantRanges">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="MerchantRangeId"
                text="Merchant Range ID"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="MerchantName"
                text="Merchant Name"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="CompanyName"
                text="Company"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="ServiceCenterNumber"
                text="Service Center ID"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="RequestDate"
                text="Request Date"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="DisableDate"
                text="Disable Date"
                orderBy={orderBy}
                onChange={(orderBy) => merchantRanges.setOrderBy(orderBy)}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto) => {
            return (
              <tr key={dto.id}>
                <td>
                  <Badge
                    color="primary"
                    className="pointer"
                    onClick={() =>
                      copyToClipboard(dto.merchantRangeId?.toString())
                    }
                  >
                    {dto.merchantRangeId}
                  </Badge>
                </td>
                <td>{dto.description}</td>
                <td>{dto.companyName}</td>
                <td>{dto.serviceCenterNumber}</td>
                <td>{toDateTime(dto.requestDate)}</td>
                <td>{toDateTime(dto.disableDate)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => merchantRanges.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  orderBy: string
) {
  let client = getApiClient();
  let request = new GetMerchantRangesRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = orderBy;

  let response = await client.get(request);

  merchantRanges.fromResponse(response);
}

export default MerchantRangesTable;
