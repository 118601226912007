import React from "react";
import { Spinner } from "reactstrap";

const StartupApp = () => {
  return (
    <div className="loading-block pb-5">
      <div className="text-center">
        <img
          width="200"
          alt="logo"
          src={require("assets/img/brand/logo.png")}
          className="mb-4"
        />
      </div>
      <div className="text-center">
        <Spinner size="sm" color="primary" /> Loading application...
      </div>
    </div>
  );
};

export default StartupApp;
