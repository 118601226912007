import { CommunicationType } from "api";
import React, { forwardRef } from "react";
import { Input } from "reactstrap";

type CommunicationTypeSelectProps = {
  onChange: (value: CommunicationType | null | undefined) => void;
  value: CommunicationType | undefined | null;
  invalid?: boolean;
  used: CommunicationType[];
};

const CommunicationTypeSelect = forwardRef<any, CommunicationTypeSelectProps>(
  ({ onChange, value, invalid, used }, ref) => {
    const options = [
      { value: "LteInternetConnection", text: "03 LTE - internet connection" },
      { value: "InternetConnection", text: "04 Internet connection" },
      { value: "PrivateNetwork", text: "05 Private network" },
      {
        value: "PrivateNetworkWithDnsFqhn",
        text: "06 Private network with DNS (FQHN)",
      },
      {
        value: "PrivateNetworkWithDns",
        text: " 07 Private network with DNS (not FQHN)",
      },
      {
        value: "InternetConnectionWithTls",
        text: "08 Internet connection with TLS",
      },
      { value: "PrivateNetworkWithTls", text: "09 Private network with TLS" },
      { value: "PointToPointMigros", text: "20 Point-to-Point Migros" },
      {
        value: "PrivateNetworkPointToPoint",
        text: "21 Private Network Point-to-Point",
      },
    ] as Array<{ value: CommunicationType; text: string }>;

    return (
      <>
        <Input
          invalid={invalid}
          ref={ref}
          type="select"
          value={value}
          onChange={(e) => onChange(e.target.value as CommunicationType)}
        >
          <option value={""}>Select Type</option>

          {options.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.text}
              </option>
            );
          })}
        </Input>
      </>
    );
  }
);

export default CommunicationTypeSelect;
