import React, { useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  InputGroupAddon,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { registerNewUser } from "../../common/userFunctions";
import { useSimplux } from "@simplux/react";
import { user } from "../user/user.module";
import { Redirect } from "react-router-dom";
import RegisterComplete from "./RegisterComplete";

type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  password2: string;
};

const Register = () => {
  const isLoggedIn = useSimplux(user.isLoggedIn);
  const emailVerified = useSimplux(user.emailVerified);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<RegisterFormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
    },
  });
  const password = useRef({});
  password.current = watch("password", "");

  const [registered, setRegistered] = useState(false);

  const onSubmit = async (data: RegisterFormData) => {
    if (
      await registerNewUser(
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.password
      )
    ) {
      setRegistered(true);
    }
  };

  if (registered) {
    return (
      <>
        <RegisterComplete />
      </>
    );
  } else if (isLoggedIn && emailVerified) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white text-center">
                  <h3 className="mb-0">Registration</h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Please fill in all fields</small>
                  </div>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup className="mb-3">
                      <Controller
                        control={control}
                        name="firstName"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            placeholder="First name"
                            type="text"
                            name="firstName"
                            {...field}
                            invalid={!!errors.firstName}
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Controller
                        control={control}
                        name="lastName"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            placeholder="Last name"
                            type="text"
                            name="lastName"
                            {...field}
                            invalid={!!errors.lastName}
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="fa fa-phone" />
                        </InputGroupText>
                        <Controller
                          control={control}
                          name="phone"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              placeholder="Phone"
                              type="text"
                              {...field}
                              invalid={!!errors.phone}
                            />
                          )}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            required: "Required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "invalid email address",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              placeholder="Email"
                              type="email"
                              {...field}
                              invalid={!!errors.email}
                            />
                          )}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                          control={control}
                          name="password"
                          rules={{
                            required: "You must specify a password",
                            minLength: {
                              value: 8,
                              message:
                                "Password must have at least 8 characters",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              {...field}
                              invalid={!!errors.password}
                            />
                          )}
                        />

                        {errors.password && <p>{errors.password.message}</p>}
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                          control={control}
                          name="password2"
                          rules={{
                            required: "Required",
                            validate: (value) =>
                              value === password.current ||
                              "The passwords do not match",
                          }}
                          render={({ field }) => (
                            <Input
                              placeholder="Password repeat"
                              type="password"
                              autoComplete="off"
                              {...field}
                              invalid={!!errors.password2}
                            />
                          )}
                        />

                        {errors.password2 && <p>{errors.password2.message}</p>}
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="primary">
                        Create Account
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Register;
