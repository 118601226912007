import React from "react";
import Hero from "../../../components/Hero";
import PMSTable from "./PMSTable";

const PMS = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <PMSTable />
      </section>
    </>
  );
};

export default PMS;
