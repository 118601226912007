import React from "react";
import { Nav, NavItem, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useSimplux } from "@simplux/react";
import { user } from "../../pages/user/user.module";
import { logout } from "../../common/userFunctions";

function UserNav() {
  const isLoggedIn = useSimplux(user.isLoggedIn);

  return (
    <Nav className="align-items-lg-center ml-lg-auto" navbar>
      <NavItem className="d-none d-lg-block ml-lg-4">
        {isLoggedIn ? (
          <Button
            className="btn-neutral btn-icon"
            color="default"
            target="_blank"
            onClick={() => {
              logout();
            }}
          >
            <span className="nav-link-inner--text ml-1">Logout</span>
          </Button>
        ) : (
          <Link to="/login">
            <Button
              className="btn-neutral btn-icon"
              color="default"
              target="_blank"
            >
              <span className="nav-link-inner--text ml-1">Login</span>
            </Button>
          </Link>
        )}
      </NavItem>
    </Nav>
  );
}

export default UserNav;
