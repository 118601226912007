import Hero from "components/Hero";
import React, { useEffect } from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import {
  GetApplicationsRequest,
  GetMerchantRangesRequest,
  PagedResponse,
  GetTerminalRangesRequest,
  GetTerminalSuppliersRequest,
  ApplicationDto,
  MerchantRangeDto,
  TerminalRangeDto,
  TerminalSupplierDto,
  GetCommunications,
  CommunicationListDto,
} from "api";
import getApiClient from "common/ApiClientFactory";
import Pagination from "components/pagination/paginationInternal";
import { search } from "./search.module";
import MerchantRangesTable from "./MerchantRangesTable";
import TerminalRangesTable from "./TerminalRangesTable";
import TerminalSuppliersTable from "./TerminalSuppliersTable";
import ApplicationsTable from "./ApplicationsTable";
import CommunicationsTable from "./CommunicationsTable";

const Search = () => {
  const rows = useSimplux(search.rows);
  const page = useSimplux(search.page);
  const pageSize = useSimplux(search.pageSize);
  const searchInput = useSimplux(search.search);
  const orderBy = useSimplux(search.orderBy);
  const total = useSimplux(search.total);
  const pages = useSimplux(search.pages);
  const tableType = useSimplux(search.tableType);

  useEffect(() => {
    fetchData(tableType, page, pageSize, searchInput, orderBy);
  }, [tableType, pageSize, searchInput, page, orderBy]);

  return (
    <>
      <Hero />
      <section className="section m-4">
        <div className="d-flex justify-content-between">
          <h3>Search</h3>
        </div>
        <Row>
          <Col lg="5" md="6">
            <FormGroup>
              <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Search"
                  type="text"
                  text={searchInput}
                  defaultValue={searchInput}
                  onChange={(event) => search.setSearch(event.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col>
            <Input
              type="select"
              name="tableType"
              defaultValue={tableType}
              onChange={(event) => search.setTableType(event.target.value)}
            >
              <option value="merchant-ranges">Merchant Ranges</option>
              <option value="terminal-ranges">Terminal Ranges</option>
              <option value="terminal-suppliers">Terminal Suppliers</option>
              <option value="applications">Applications</option>
              <option value="communications">Communications</option>
            </Input>
          </Col>
          <Col lg="2" md="3">
            <Input
              type="select"
              name="select"
              defaultValue={pageSize}
              onChange={(event) =>
                search.setPageSize(parseInt(event.target.value))
              }
            >
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option value="133333337">All</option>
            </Input>
          </Col>
          <Col>{`${rows.length} of ${total}`}</Col>
        </Row>

        {tableType === "merchant-ranges" && (
          <MerchantRangesTable
            rows={rows as MerchantRangeDto[]}
            shouldRefetch={() =>
              fetchData(tableType, page, pageSize, searchInput, orderBy)
            }
          />
        )}
        {tableType === "terminal-ranges" && (
          <TerminalRangesTable
            rows={rows as TerminalRangeDto[]}
            shouldRefetch={() =>
              fetchData(tableType, page, pageSize, searchInput, orderBy)
            }
          />
        )}
        {tableType === "terminal-suppliers" && (
          <TerminalSuppliersTable
            rows={rows as TerminalSupplierDto[]}
            shouldRefetch={() =>
              fetchData(tableType, page, pageSize, searchInput, orderBy)
            }
          />
        )}
        {tableType === "applications" && (
          <ApplicationsTable
            rows={rows as ApplicationDto[]}
            shouldRefetch={() =>
              fetchData(tableType, page, pageSize, searchInput, orderBy)
            }
          />
        )}
        {tableType === "communications" && (
          <CommunicationsTable
            rows={rows as CommunicationListDto[]}
            shouldRefetch={() =>
              fetchData(tableType, page, pageSize, searchInput, orderBy)
            }
          />
        )}

        <Row>
          <Col>
            {pages > 1 && (
              <Row>
                <Col>
                  <Pagination
                    currentPage={page}
                    totalPages={pages}
                    onChange={(page) => search.setPage(page)}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </section>
    </>
  );
};

async function fetchData(
  tableType: string,
  page: number,
  pageSize: number,
  searchInput: string,
  orderBy: string
) {
  let client = getApiClient();

  let request;

  if (tableType === "applications") {
    request = new GetApplicationsRequest();
  } else if (tableType === "merchant-ranges") {
    request = new GetMerchantRangesRequest();
  } else if (tableType === "terminal-ranges") {
    request = new GetTerminalRangesRequest();
  } else if (tableType === "terminal-suppliers") {
    request = new GetTerminalSuppliersRequest();
  } else if (tableType === "communications") {
    request = new GetCommunications();
  }

  request.page = page;
  request.pageSize = pageSize;
  request.search = searchInput;
  request.orderBy = orderBy;

  let response = (await client.get(request)) as PagedResponse<any>;

  search.fromResponse(response);
}

export default Search;
