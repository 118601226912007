import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  Button,
  InputGroupAddon,
} from "reactstrap";
import { companies } from "./companies.module";
import { useSimplux } from "@simplux/react";
import getApiClient from "common/ApiClientFactory";
import { GetCompaniesRequest } from "api";
import { Link, useHistory } from "react-router-dom";
import OrderByToggle from "components/OrderByToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import Pagination from "components/pagination/paginationInternal";

const CompanyTable = () => {
  const rows = useSimplux(companies.rows);
  const page = useSimplux(companies.page);
  const pageSize = useSimplux(companies.pageSize);
  const search = useSimplux(companies.search);
  const total = useSimplux(companies.total);
  const orderBy = useSimplux(companies.orderBy);
  const pages = useSimplux(companies.pages);

  const history = useHistory();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetCompaniesRequest();
      request.page = page;
      request.pageSize = pageSize;
      request.search = search;
      request.orderBy = orderBy;
      let response = await client.get(request);

      companies.fromResponse(response);
    }

    async();
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Companies</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) => companies.setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            value={pageSize}
            onChange={(event) =>
              companies.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>
          <Button
            color="primary"
            onClick={() => history.push("/admin/companies/new")}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm companies table-hover">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="Name"
                text="Name"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Street1"
                text="Street 1"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Street2"
                text="Street 2"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="City"
                text="City"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Country"
                text="Country"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Type"
                text="Type"
                orderBy={orderBy}
                onChange={(orderBy) => companies.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto, i) => {
            return (
              <tr key={dto.id}>
                <td>{dto.name}</td>
                <td>{dto.street1}</td>
                <td>{dto.street2}</td>
                <td>{dto.city}</td>
                <td>{dto.country}</td>
                <td>
                  <Badge color="secondary">{dto.type}</Badge>
                </td>
                <td>
                  <Link to={`/admin/${dto.id}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => companies.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CompanyTable;
