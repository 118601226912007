import {
  createMutations,
  createSimpluxModule,
  createSelectors,
} from "@simplux/core";
import { UserDetailDto, CompanyDto } from "../../../api";

const userDetailModule = createSimpluxModule({
  name: "user-detail",
  initialState: {
    dto: new UserDetailDto(),
    selectedCompany: undefined as any,
  },
});

export const userDetail = {
  ...userDetailModule,
  ...createMutations(userDetailModule, {
    setUser(state, user: UserDetailDto) {
      state.dto = user;
    },
    reset(state) {
      state.dto = new UserDetailDto();
    },
    addCompany(state, company: CompanyDto) {
      if (!state.dto.companyIds) {
        state.dto.companyIds = [];
      }
      if (!state.dto.companies) {
        state.dto.companies = [];
      }

      if (state.dto.companyIds.indexOf(company.id) === -1) {
        state.dto.companyIds.push(company.id);
        state.dto.companies.push(company);
      }

      state.selectedCompany = null;
    },
    removeCompany(state, companyId: string) {
      state.dto.companyIds = state.dto.companyIds.filter(
        (p) => p !== companyId
      );
      state.dto.companies = state.dto.companies.filter(
        (p) => p.id !== companyId
      );
    },
    setRoles(state, roles: string[]) {
      state.dto.roles = roles;
    },
  }),
  ...createSelectors(userDetailModule, {
    dto: ({ dto }) => dto,
    id: ({ dto }) => dto.key,
    selectedCompany: ({ selectedCompany }) => selectedCompany,
  }),
};
