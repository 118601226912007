import React from "react";
import Hero from "../../components/Hero";
import { Container, Col } from "reactstrap";

type NotAllowedToRegisterProps = {
  isLoggedIn: boolean;
  emailVerified: boolean;
  approved: boolean;
};

const NotAllowedToRegister = (props: NotAllowedToRegisterProps) => {
  let content = <span></span>;

  if (!props.isLoggedIn) {
    content = (
      <Col md="12">
        <h4>Please login or register first</h4>
      </Col>
    );
  } else if (!props.emailVerified) {
    content = (
      <Col md="12">
        <h4>Please check your mailbox to verify your email address first.</h4>
      </Col>
    );
  } else if (!props.approved) {
    content = (
      <Col md="12">
        <h4>Your account has not been approved yet by an administrator.</h4>
      </Col>
    );
  }

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container className="mb-5">{content}</Container>
      </section>
    </>
  );
};

export default NotAllowedToRegister;
