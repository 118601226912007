import React from "react";
import Hero from "../../../components/Hero";
import UserTable from "./UserTable";

const UserManagement = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <UserTable />
      </section>
    </>
  );
};

export default UserManagement;
