import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import UserNav from "./UserNav";

const TopNavbar = () => {
  const isAdmin = useSimplux(user.isAdmin);
  const level = useSimplux(user.level);

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container fluid={isAdmin}>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img alt="logo" src={require("assets/img/brand/logo.png")} />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse toggler="#navbar_global" navbar>
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="logo"
                        src={require("assets/img/brand/logo.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              {isAdmin && (
                <>
                  <Nav
                    className="navbar-nav-hover align-items-lg-center"
                    navbar
                  >
                    <NavItem>
                      <NavLink to="/admin/requests" className="nav-link">
                        Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/acquirers" className="nav-link">
                        Acquirers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/service-centers" className="nav-link">
                        Service Centers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/pms" className="nav-link">
                        PMS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/kernels" className="nav-link">
                        Domestic Kernels
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/nonce-offsets" className="nav-link">
                        Nonce Offsets
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/companies" className="nav-link">
                        Companies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/contact-people" className="nav-link">
                        Contact People
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/search" className="nav-link">
                        Search
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/admin/user-management" className="nav-link">
                        Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/downloads" className="nav-link">
                        Downloads
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/notifications" className="nav-link">
                        Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </>
              )}

              <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                {!isAdmin && (level === "Subscriber" || level === "None") && (
                  <>
                    <NavItem>
                      <NavLink to="/requests" className="nav-link">
                        Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/companies" className="nav-link">
                        Companies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/notifications" className="nav-link">
                        Notifications
                      </NavLink>
                    </NavItem>
                  </>
                )}
                {!isAdmin && level === "Supplier" && (
                  <>
                    <NavItem>
                      <NavLink to="/requests" className="nav-link">
                        Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/companies" className="nav-link">
                        Companies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/service-centers" className="nav-link">
                        Service Centers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/pms" className="nav-link">
                        PMS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/notifications" className="nav-link">
                        Notifications
                      </NavLink>
                    </NavItem>
                  </>
                )}
                {!isAdmin && level === "Acquirer" && (
                  <>
                    <NavItem>
                      <NavLink to="/requests" className="nav-link">
                        Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/companies" className="nav-link">
                        Companies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/service-centers" className="nav-link">
                        Service Centers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/pms" className="nav-link">
                        PMS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/acquirers" className="nav-link">
                        Acquirers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/notifications" className="nav-link">
                        Notifications
                      </NavLink>
                    </NavItem>
                  </>
                )}
                {!isAdmin && (
                  <NavItem>
                    <NavLink to="/downloads" className="nav-link">
                      Downloads
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <UserNav />
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default TopNavbar;
