import React from "react";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import getApiClient from "common/ApiClientFactory";
import { CreateTerminalSupplier } from "api";
import { toast } from "react-toastify";

type PropType = {
  serviceCenterId: string;
  onChange: () => void;
};

type FormData = {
  description: string;
};

const RequestTerminalSupplier = (props: PropType) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { description: "" } });

  const onSubmit = (data: FormData) => {
    let request = new CreateTerminalSupplier();
    request.serviceCenterId = props.serviceCenterId;
    request.description = data.description;

    let client = getApiClient();
    client.post(request).then(() => {
      toast.success("Terminal Supplier created");
      props.onChange();
      reset({ description: "" });
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 mt-4">
          <small className="text-uppercase font-weight-bold">
            New Terminal Supplier ID
          </small>
        </div>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    placeholder="Description"
                    type="text"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" color="success">
              Add
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default RequestTerminalSupplier;
