import { Badge } from "reactstrap";
import React from "react";

type ApprovalStateProps = {
  approved: boolean;
};

const ApprovalState = (props: ApprovalStateProps) => {
  if (props.approved) {
    return (
      <Badge color="success" className="mr-3">
        approved
      </Badge>
    );
  }

  return (
    <Badge color="danger" className="mr-3">
      rejected
    </Badge>
  );
};

export default ApprovalState;
