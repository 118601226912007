import React from "react";
import Hero from "../../../components/Hero";
import TerminalSuppliersTable from "./TerminalSuppliersTable";

const TerminalSuppliers = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <TerminalSuppliersTable />
      </section>
    </>
  );
};

export default TerminalSuppliers;
