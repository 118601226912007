import { KeyInterface } from "api";
import React from "react";
import { Row, Col } from "reactstrap";
import CertificateUpload from "./CertificateUpload";

type CertificatesProps = {
  TargetId: string;
  AllowedInterfaces: KeyInterface[];
};

const Certificates = (props: CertificatesProps) => {
  return (
    <>
      {props.AllowedInterfaces.map((keyInterface: KeyInterface, index) => {
        return (
          <Row className="mb-4" key={index}>
            <Col md="6" key={`${props.TargetId}_${keyInterface}_prod_1}`}>
              <CertificateUpload
                BelongsTo={props.TargetId}
                KeyInterface={keyInterface}
                KeySystem={"Prod"}
                Number={1}
              />
            </Col>
            <Col md="6" key={`${props.TargetId}_${keyInterface}_test_1}`}>
              <CertificateUpload
                BelongsTo={props.TargetId}
                KeyInterface={keyInterface}
                KeySystem={"Test"}
                Number={1}
              />
            </Col>
            <Col md="6" key={`${props.TargetId}_${keyInterface}_prod_2}`}>
              <CertificateUpload
                BelongsTo={props.TargetId}
                KeyInterface={keyInterface}
                KeySystem={"Prod"}
                Number={2}
              />
            </Col>
            <Col md="6" key={`${props.TargetId}_${keyInterface}_test_2}`}>
              <CertificateUpload
                BelongsTo={props.TargetId}
                KeyInterface={keyInterface}
                KeySystem={"Test"}
                Number={2}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default Certificates;
