import {
  faAngleRight,
  faMinus,
  faTrash,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import {
  AcquirerDto,
  AddContactPersonRequest,
  ApplicationDto,
  ContactPersonDto,
  DeleteContactPersonFromTargetRequest,
  GetApplicationRequest,
  GetContactPeopleFromTargetRequest,
  SetPrimaryContactPerson,
  UpdateApplicationRequest,
} from "api";
import classNames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix } from "common/utils";
import AcquirerSelect from "components/AcquirerSelect";
import Hero from "components/Hero";
import SearchContactPersonTable from "components/SearchContactPersonTable";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";

const ApplicationDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  let { id, acquirerId } = useParams<any>();
  const [dto, setDto] = useState<ApplicationDto>();
  const [contactPeople, setContactPeople] = useState<ContactPersonDto[]>();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ApplicationDto>({
    defaultValues: dto,
  });
  const history = useHistory();

  const onAcquirerSelected = (selection: AcquirerDto) => {
    dto.acquirer = selection;

    setDto(dto);
  };

  const onSubmit = async (data: ApplicationDto) => {
    let client = getApiClient();

    let request = new UpdateApplicationRequest();
    request.id = `Applications/${id}`;
    request.description = data.description;
    request.remarks = data.remarks;

    if (dto.acquirer) {
      request.acquirerId = dto.acquirer.id;
    }

    request.applicationId = data.applicationId;

    await client.put(request);

    toast.success("Application saved");
  };

  useEffect(() => {
    if (id !== "new" && id) {
      fetchApplication(id, setDto);
      fetchContactPeople(id, setContactPeople);
    }
  }, [id]);

  const addContactPerson = (targetId: string, contactPersonId: string) => {
    let client = getApiClient();

    let request = new AddContactPersonRequest();
    request.id = targetId;
    request.contactPersonId = contactPersonId;

    client.post(request).then(() => {
      fetchContactPeople(id, setContactPeople);
      toast.success("contact person added");
    });
  };

  const deleteContactPerson = (targetId: string, contactPersonId: string) => {
    let client = getApiClient();

    let request = new DeleteContactPersonFromTargetRequest();
    request.id = targetId;
    request.contactPersonId = contactPersonId;

    client.delete(request).then(() => {
      toast.success("contact person removed");
      fetchApplication(id, setDto);
      fetchContactPeople(id, setContactPeople);
    });
  };

  const linkToDetail = (contactPersonId: string) => {
    return `${
      isAdmin ? "/admin" : ""
    }/applications/${id}/contact-people/${removeCollectionPrefix(
      contactPersonId
    )}`;
  };

  const buttonText = id === "new" ? "add" : "save";

  const goToNewContactPerson = () => {
    if (acquirerId) {
      history.push(
        `/acquirers/${acquirerId}/applications/${id}/contact-people/new`
      );
    } else {
      history.push(`/admin/applications/${id}/contact-people/new`);
    }
  };

  const setPrimary = (contactPersonId: string) => {
    let request = new SetPrimaryContactPerson();
    request.contactPersonId = contactPersonId;
    request.targetId = dto.id;

    let client = getApiClient();
    client.put(request).then(() => {
      dto.primaryContactPersonId = contactPersonId;
      fetchContactPeople(id, setContactPeople);
      toast.success("Primary contact person updated");
    });
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        {dto && (
          <Container className="mb-5">
            <h3>
              <Link
                to={`${acquirerId ? `/acquirers/${acquirerId}` : "/search"}`}
              >
                <i className="ni ni-bold-left"></i>
              </Link>
              Application{" "}
              <small className="text-primary">{dto.applicationId}</small>
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Details
                </small>
              </div>
              {isAdmin && (
                <Row>
                  <Col lg="12">
                    <FormGroup
                      className={classNames({
                        "has-danger": errors.applicationId,
                      })}
                    >
                      <Controller
                        control={control}
                        name="applicationId"
                        render={({ field }) => (
                          <Input
                            placeholder="Application ID"
                            type="text"
                            defaultValue={dto.applicationId}
                            {...field}
                            className={classNames({
                              "is-invalid": errors.applicationId,
                            })}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg="12">
                  <FormGroup
                    className={classNames({ "has-danger": errors.description })}
                  >
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <Input
                          placeholder="Description"
                          type="text"
                          defaultValue={dto.description}
                          className={classNames({
                            "is-invalid": errors.description,
                          })}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isAdmin && (
                <>
                  <Row>
                    <Col lg="12">
                      <FormGroup
                        className={classNames({ "has-danger": errors.remarks })}
                      >
                        <Controller
                          control={control}
                          name="remarks"
                          render={({ field }) => (
                            <Input
                              placeholder="Remarks"
                              type="text"
                              defaultValue={dto.remarks}
                              className={classNames({
                                "is-invalid": errors.remarks,
                              })}
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <AcquirerSelect
                          defaultValue={dto.acquirer}
                          onChange={(selection) =>
                            onAcquirerSelected(selection)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-2">
                <Col>
                  <Button type="submit" color="primary">
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
            <div className="mb-3 mt-4">
              <small className="text-uppercase font-weight-bold">
                Contact people
              </small>
            </div>
            <Row>
              <Col>
                <Table className="align-items-center table-sm">
                  <thead>
                    <tr>
                      <th scope="col">First name</th>
                      <th scope="col">Last name</th>
                      <th scope="col">E-Mail</th>
                      <th scope="col">Phone</th>
                      <th scope="col">&nbsp;</th>
                      {isAdmin && <th scope="col">User</th>}
                      <th scope="col">&nbsp;</th>
                      {isAdmin && <th scope="col">&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {contactPeople?.map((contactPerson) => {
                      return (
                        <tr key={contactPerson.id}>
                          <td>{contactPerson.firstName}</td>
                          <td>{contactPerson.lastName}</td>
                          <td>{contactPerson.email}</td>
                          <td>{contactPerson.phone}</td>
                          <td>
                            {contactPerson.id === dto.primaryContactPersonId ? (
                              <Badge color="info">primary</Badge>
                            ) : (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => setPrimary(contactPerson.id)}
                              >
                                set primary
                              </Button>
                            )}
                          </td>
                          {isAdmin && (
                            <td>
                              {contactPerson.userId ? (
                                <FontAwesomeIcon icon={faUserCheck} />
                              ) : (
                                <FontAwesomeIcon icon={faMinus} />
                              )}
                            </td>
                          )}
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() =>
                                deleteContactPerson(
                                  `Applications/${id}`,
                                  contactPerson.id
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                          {isAdmin && (
                            <td>
                              <Link to={linkToDetail(contactPerson.id)}>
                                <FontAwesomeIcon icon={faAngleRight} />
                              </Link>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {isAdmin && (
              <Row>
                <Col>
                  <SearchContactPersonTable
                    action={(contactPerson) => {
                      addContactPerson(`Applications/${id}`, contactPerson.id);
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => goToNewContactPerson()}
                >
                  New Contact Person
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

async function fetchApplication(
  id: string,
  setDto: React.Dispatch<React.SetStateAction<ApplicationDto>>
) {
  let client = getApiClient();
  let request = new GetApplicationRequest();

  request.id = id;
  let dto = await client.get(request);

  setDto(dto);
}

async function fetchContactPeople(
  id: string,
  setContactPeople: React.Dispatch<React.SetStateAction<ContactPersonDto[]>>
) {
  let client = getApiClient();
  let request = new GetContactPeopleFromTargetRequest();

  request.targetId = `Applications/${id}`;

  let response = await client.get(request);
  setContactPeople(response);
}

export default ApplicationDetail;
