import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import { ApproveAidRequest, RequestDto, RequestType } from "api";
import getApiClient from "common/ApiClientFactory";
import { toDateTime } from "common/date";
import { user } from "pages/user/user.module";
import React from "react";
import { toast } from "react-toastify";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import ApprovalState from "./ApprovalState";

type AidRequestProps = {
  request: RequestDto;
  dataChanged: () => void;
};

const AidRequest = (props: AidRequestProps) => {
  const isAdmin = useSimplux(user.isAdmin);

  const dispatch = (approve: boolean) => {
    let request = new ApproveAidRequest();
    request.approve = approve;
    request.requestId = props.request.id;

    let client = getApiClient();
    client.post(request).then(() => {
      if (approve) {
        toast.success("approved");
      } else {
        toast.success("rejected");
      }

      props.dataChanged();
    });
  };

  return (
    <>
      <Row className="mb-4">
        <Col lg="8">
          <div className="col-lg-12">
            <div className="card border-0 shadow">
              <div className="card-body py-5">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="text-primary text-uppercase">
                      <i className="ni ni-tv-2"></i>{" "}
                      {typeText(props.request.type)}
                    </h6>
                  </div>
                  <div>
                    {props.request.done && (
                      <ApprovalState approved={props.request.approved} />
                    )}
                    {toDateTime(props.request.createdAt)}
                  </div>
                </div>
                <Container fluid>
                  <Row>
                    <Col md="6">
                      {props.request.description && (
                        <>
                          <strong>Description: </strong>{" "}
                          {props.request.description}
                        </>
                      )}
                    </Col>
                    <Col md="6">
                      <strong>Requester</strong>
                      <br />
                      {props.request.requester.firstName}{" "}
                      {props.request.requester.lastName}
                      <br />
                      <FontAwesomeIcon icon={faPhone} />{" "}
                      {props.request.requester.phone}
                      <br />
                      <FontAwesomeIcon icon={faMailBulk} />{" "}
                      {props.request.requester.email}
                      <br />
                    </Col>
                  </Row>
                  {!props.request.done && isAdmin && (
                    <>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            color="success"
                            onClick={() => dispatch(true)}
                          >
                            Approve
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => dispatch(false)}
                          >
                            Reject
                          </Button>
                          {props.request.aid ? (
                            <Badge
                              color="primary"
                              className="text-huge float-right"
                            >
                              {props.request.aid}
                            </Badge>
                          ) : (
                            <Badge
                              color="primary"
                              className="text-huge float-right"
                            >
                              {props.request.nextIdString}
                            </Badge>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AidRequest;

const typeText = (type: RequestType) => {
  switch (type) {
    case "MagStripeAid":
      return "Magstripe AID";
    case "InternationalChipAid":
      return "International Chip AID";
    case "DomesticChipAid":
      return "Domestic Chip AID";
    case "MiFareChipAid":
      return "MIFARE AID";
  }

  return "";
};
