import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { GetTerminalSuppliersRequest } from "../../../api";
import { toDate } from "../../../common/date";
import getApiClient from "../../../common/ApiClientFactory";
import { terminalSuppliers } from "./terminal-suppliers.module";
import { Link } from "react-router-dom";
import OrderByToggle from "components/OrderByToggle";
import Pagination from "components/pagination/paginationInternal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { removeCollectionPrefix, copyToClipboard, to11 } from "common/utils";
import Disabler from "components/Disabler";

const TerminalSuppliersTable = (props: any) => {
  const rows = useSimplux(terminalSuppliers.rows);
  const page = useSimplux(terminalSuppliers.page);
  const pageSize = useSimplux(terminalSuppliers.pageSize);
  const search = useSimplux(terminalSuppliers.search);
  const total = useSimplux(terminalSuppliers.total);
  const orderBy = useSimplux(terminalSuppliers.orderBy);
  const pages = useSimplux(terminalSuppliers.pages);

  useEffect(() => {
    fetchData(page, pageSize, search, orderBy);
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Terminal Suppliers</h3>
        <div>
          Showing {rows.length} of {total}
        </div>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) =>
                  terminalSuppliers.setSearch(event.target.value)
                }
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            id="page-size"
            type="select"
            name="select"
            defaultValue={pageSize}
            onChange={(event) =>
              terminalSuppliers.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm generic-approval table-hover">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="TerminalSupplierId"
                text="Terminal Supplier ID"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="CompanyName"
                text="Company"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Description"
                text="Description"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="RequestDate"
                text="Request Date"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="AcceptDate"
                text="Accept Date"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="DisableDate"
                text="Disable Date"
                orderBy={orderBy}
                onChange={(orderBy) => terminalSuppliers.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto, i) => {
            return (
              <tr key={dto.id}>
                <td>
                  <Badge
                    color="primary"
                    className="pointer"
                    onClick={() =>
                      copyToClipboard(dto.terminalSupplierId?.toString())
                    }
                  >
                    {to11(dto.terminalSupplierId)}
                  </Badge>
                </td>
                <td>
                  <Link to={`/${dto.serviceCenter?.company?.id}`}>
                    {dto.serviceCenter?.company?.name}
                  </Link>
                </td>
                <td>{dto.description}</td>
                <td>{toDate(dto.requestDate)}</td>
                <td>{toDate(dto.acceptDate)}</td>
                <td>
                  <Disabler
                    disabledDate={dto.disableDate}
                    targetId={dto.id}
                    onUpdated={() => fetchData(page, pageSize, search, orderBy)}
                    id={to11(dto.terminalSupplierId).toString()}
                    description={dto.description}
                  />
                </td>
                <td>
                  <Link
                    to={`terminal-suppliers/${removeCollectionPrefix(dto.id)}`}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => terminalSuppliers.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  orderBy: string
) {
  let client = getApiClient();
  let request = new GetTerminalSuppliersRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = orderBy;
  let response = await client.get(request);

  terminalSuppliers.fromResponse(response);
}

export default TerminalSuppliersTable;
