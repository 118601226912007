import React, { useEffect } from "react";
import Hero from "components/Hero";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { acquirers } from "pages/admin/acquirer/acquirer.module";
import { useSimplux } from "@simplux/react";
import getApiClient from "common/ApiClientFactory";
import {
  GetAcquirersRequest,
  AcquirerDto,
  DisableRequest,
  CompanyDto,
} from "api";
import CompanyDisplay from "components/CompanyDisplay";
import ContactPersonDisplay from "components/ContactPersonDisplay";
import { to11 } from "common/utils";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";

const MyAcquirers = () => {
  const rows = useSimplux(acquirers.rows);

  useEffect(() => {
    fetchAcquirer();
  }, []);

  const disable = async (acquirer: AcquirerDto) => {
    let confirmed = await confirm({
      title: "Disabling",
      message: (
        <>
          <span>
            Please confirm disabling Acquirer{" "}
            <Badge color="primary">{acquirer.acquirerId}</Badge>{" "}
            <strong>{acquirer.description}.</strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DisableRequest();
      request.id = acquirer.id;
      request.disable = true;

      let client = getApiClient();
      await client.post(request);
      toast.success("disabled");

      fetchAcquirer();
    }
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="mb-3">
            <h3>Acquirers</h3>
          </div>
          <Row>
            <Col>
              <ListGroup className="mb-2">
                {rows?.map((acquirer) => {
                  return (
                    <ListGroupItem key={acquirer.id.toLowerCase()}>
                      <div className="d-flex justify-content-between">
                        <div className="flex-fill">
                          <h5 className="mb-1">
                            <Link to={`/${acquirer.id}`} className="mr-1">
                              {to11(acquirer.acquirerId)}
                            </Link>
                          </h5>
                          <p className="mb-1">{acquirer.description}</p>
                        </div>
                        <div className="px-2 listview-block">
                          <CompanyDisplay
                            dto={acquirer.company as CompanyDto}
                          />
                        </div>
                        <div className="px-2 listview-block">
                          <ContactPersonDisplay
                            dto={acquirer.company.primaryContactPerson}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div>
                            <small>
                              <Link to={acquirer.id.toLowerCase()}>
                                <Button size="sm" color="primary">
                                  Manage
                                </Button>
                              </Link>
                            </small>
                          </div>
                          <div>
                            <small>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() => disable(acquirer as AcquirerDto)}
                              >
                                Disable
                              </Button>
                            </small>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  );
                })}
                {rows?.length === 0 && (
                  <ListGroupItem>
                    No acquirer IDs are assigned to you. Request one below.
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Link to="/request/acquirer-id">
                <Button color="success">Request Acquirer ID</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

async function fetchAcquirer() {
  let client = getApiClient();
  let request = new GetAcquirersRequest();
  request.page = 1;
  request.pageSize = 1333337;
  request.orderBy = "AcquirerId";

  let response = await client.get(request);

  acquirers.fromResponse(response);
}

export default MyAcquirers;
