import { CommunicationType } from "api";
import React from "react";
import { toast } from "react-toastify";

export function removeCollectionPrefix(s: string) {
  if (!s) {
    return s;
  }

  let index = s.indexOf("/");

  if (index === -1) {
    return s;
  }

  return s.substring(s.indexOf("/") + 1).toLowerCase();
}

export function copyToClipboard(text: string) {
  if (!text) {
    return;
  }

  navigator.clipboard.writeText(text);

  toast.info(
    <span>
      copied to clipboard: <strong>{text}</strong>
    </span>,
    { autoClose: 2000, pauseOnFocusLoss: false }
  );
}

export function to11(number: number) {
  if (typeof number !== "undefined" && number !== null) {
    return number.toString().padStart(11, "0");
  }
  return number;
}

export function getBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }

      resolve(encoded);
    };

    reader.onerror = (error) => reject(error);
  });
}

export function communicationTypeDisplay(enumValue: CommunicationType) {
  switch (enumValue) {
    case "InternetConnection":
      return "04 Internet Connection";
    case "NotSpecified":
      return "-";
    case "LteInternetConnection":
      return "03 LTE - internet connection";
    case "PrivateNetwork":
      return "05 Private network";
    case "PrivateNetworkWithDnsFqhn":
      return "06 Private network with DNS (fully qualified FQHN)";
    case "PrivateNetworkWithDns":
      return "07 Private network with DNS (not fully qualified)";
    case "InternetConnectionWithTls":
      return "08 Internet connection with TLS";
    case "PrivateNetworkWithTls":
      return "09 Private network with TLS";
    case "PointToPointMigros":
      return "20 Point-to-Point Migros";
    case "PrivateNetworkPointToPoint":
      return "21 Private Network Point-to-Point";

    default:
      return "-";
  }
}
