import {
  createMutations,
  createSelectors,
  createSimpluxModule,
} from "@simplux/core";
import { isNumber } from "util";
import { PagedResponse, UserDto } from "../../../api";

const userManagementModule = createSimpluxModule({
  name: "user-management",
  initialState: {
    rows: [] as UserDto[],
    total: 0,
    page: 1,
    pageSize: 25,
    search: "",
    onlyPendingApproval: false,
    orderBy: "LastName",
    response: new PagedResponse<UserDto>(),
  },
});

export const userManagement = {
  ...userManagementModule,
  ...createMutations(userManagementModule, {
    fromResponse(state, response: PagedResponse<UserDto>) {
      state.response = response;
    },
    setApproval(state, userId, approved) {
      let newList = state.rows.map((user) => {
        if (user.key === userId) {
          user.approved = approved;
        }

        return user;
      });

      state.rows = newList;
    },
    setPage(state, page: number) {
      state.page = page;
    },
    setPageSize(state, pageSize: number) {
      state.page = 1;
      state.pageSize = pageSize;
    },
    setSearch(state, search: string) {
      state.search = search;
      state.page = 1;
    },
    toggleOnlyPendingApproval(state) {
      state.onlyPendingApproval = !state.onlyPendingApproval;
    },
    setOrderBy(state, orderBy: string) {
      state.orderBy = orderBy;
    },
  }),
  ...createSelectors(userManagementModule, {
    response: ({ response }) => response,
    pageSize: ({ pageSize }) => pageSize,
    page: ({ page }) => page,
    search: ({ search }) => search,
    onlyPendingApproval: ({ onlyPendingApproval }) => onlyPendingApproval,
    orderBy: ({ orderBy }) => orderBy,
    pages: ({ response }) => {
      if (
        response &&
        isNumber(response.totalRows) &&
        isNumber(response.pageSize)
      ) {
        return Math.ceil(response.totalRows / response.pageSize);
      }

      return 1;
    },
  }),
};
