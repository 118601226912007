import React from "react";
import { Badge } from "reactstrap";
import { EmailVerificationDto } from "../../../api";

const EmailVerificationBadge = ({
  verification,
}: {
  verification: EmailVerificationDto;
}) => {
  if (!verification) {
    return null;
  }

  if (verification.verified) return <Badge color="success">verified</Badge>;

  return <Badge color="warning">pending</Badge>;
};

export default EmailVerificationBadge;
