import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Table,
  Badge,
  Button,
  Pagination,
  InputGroupAddon,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  PagedResponse,
  ApplicationDto,
  GetApplicationsRequest,
  PatchApplication,
  DeleteApplication,
} from "api";
import { toDateTime } from "common/date";
import { copyToClipboard, removeCollectionPrefix } from "common/utils";
import { useSimplux } from "@simplux/react";
import { user } from "pages/user/user.module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import getApiClient from "common/ApiClientFactory";
import { toast } from "react-toastify";
import confirm from "reactstrap-confirm";
import RequestApplication from "./RequestApplication";

type PropTypes = {
  acquirerId: string;
};

const TabApplications = (props: PropTypes) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [response, setResponse] = useState(new PagedResponse<ApplicationDto>());

  const isAdmin = useSimplux(user.isAdmin);

  useEffect(() => {
    loadApplications(page, pageSize, search, props.acquirerId, setResponse);
  }, [page, pageSize, search, props.acquirerId, setResponse]);

  const updateApplication = (applicationId: string, disabled: boolean) => {
    let client = getApiClient();
    let request = new PatchApplication();
    request.id = applicationId;
    request.disable = disabled;

    client.patch(request).then(() => {
      toast.success("Application updated");
      loadApplications(page, pageSize, search, props.acquirerId, setResponse);
    });
  };

  const onDeleteApplicationClick = async (application: ApplicationDto) => {
    let confirmed = await confirm({
      title: "Delete Application",
      message: (
        <>
          <span>
            Please confirm deleting Application{" "}
            <Badge color="primary">{application.applicationId}</Badge>.{" "}
            <strong>{application.description}</strong>.
          </span>
        </>
      ),
      confirmText: "confirm",
      confirmColor: "danger",
    });

    if (confirmed) {
      let request = new DeleteApplication();
      request.id = application.id;

      let client = getApiClient();
      client.delete(request).then(() => {
        toast.success("Application deleted");
        loadApplications(page, pageSize, search, props.acquirerId, setResponse);
      });
    }
  };

  return (
    <>
      <Row>
        <Col md="10">
          <FormGroup>
            <InputGroup className="mb-1 mt-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search applications..."
                type="text"
                defaultValue={search}
                text={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <InputGroup className="mb-1 mt-2">
              <Input
                type="select"
                name="select"
                value={pageSize}
                onChange={(event) => setPageSize(parseInt(event.target.value))}
              >
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option value="133333337">All</option>
              </Input>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Table className="align-items-center table-bordered table-hover table-sm terminal-suppliers">
            <thead>
              <tr>
                <th scope="col">AID</th>
                <th scope="col">Description</th>
                <th scope="col">Request date</th>
                <th scope="col">Disabled date</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {response?.rows?.map((application) => {
                return (
                  <tr key={application.id}>
                    <td>
                      <Badge
                        color="primary"
                        className="pointer"
                        onClick={() =>
                          copyToClipboard(application.applicationId)
                        }
                      >
                        {application.applicationId}
                      </Badge>
                    </td>
                    <td>{application.description}</td>
                    <td>{toDateTime(application.requestDate)}</td>
                    <td>{toDateTime(application.disableDate)}</td>
                    <td>
                      {application.disableDate ? (
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={() =>
                              updateApplication(application.id, false)
                            }
                          >
                            enable
                          </Button>
                          {isAdmin && (
                            <Button
                              color="danger"
                              size="sm"
                              className="float-right"
                              onClick={() =>
                                onDeleteApplicationClick(application)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() =>
                            updateApplication(application.id, true)
                          }
                        >
                          disable
                        </Button>
                      )}
                      <Link
                        to={`/acquirers/${removeCollectionPrefix(
                          application.acquirer.id
                        )}/applications/${removeCollectionPrefix(
                          application.id
                        )}`}
                      >
                        <Button
                          color="primary"
                          size="sm"
                          className="float-right mr-2"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {response?.totalRows === 0 && (
                <tr>
                  <td colSpan={5}>no entries</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {response?.totalRows > response?.rows?.length && (
        <Row>
          <Col>
            <Pagination
              currentPage={page}
              totalPages={response.lastPage}
              onChange={(page: any) => setPage(page)}
            />
          </Col>
        </Row>
      )}
      <RequestApplication
        acquirerId={props.acquirerId}
        onChange={() =>
          loadApplications(
            page,
            pageSize,
            search,
            props.acquirerId,
            setResponse
          )
        }
      />
    </>
  );
};

const loadApplications = async (
  page,
  pageSize,
  search,
  acquirerId,
  setResponse
) => {
  if (!acquirerId) {
    return;
  }

  let client = getApiClient();
  let request = new GetApplicationsRequest();
  request.orderBy = "ApplicationId";
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.acquirerId = acquirerId;

  let response = await client.get(request);

  setResponse(response);
};

export default TabApplications;
