import {
  createMutations,
  createSimpluxModule,
  createSelectors,
} from "@simplux/core";
import { CompanyType } from "api";

const userModule = createSimpluxModule({
  name: "user",
  initialState: {
    isInitialized: false,
    isLoggedIn: false,
    isLoading: true,
    firstName: "",
    lastName: "",
    userId: "",
    roles: [] as string[],
    emailVerified: false,
    approved: false,
    loginFailed: false,
    level: "None" as CompanyType,
    hasCompanyAssigned: false,
  },
});

export const user = {
  ...userModule,
  ...createMutations(userModule, {
    setIsNotLoggedIn(state) {
      state.isInitialized = true;
      state.isLoggedIn = false;
      state.firstName = "";
      state.lastName = "";
      state.userId = "";
      state.isLoading = false;
      state.emailVerified = false;
    },
    setIsLoggedIn(
      state,
      firstName: string,
      lastName: string,
      userId: string,
      roles: string[],
      emailVerified: boolean,
      approved: boolean,
      level: CompanyType,
      hasCompanyAssigned: boolean
    ) {
      state.isInitialized = true;
      state.isLoggedIn = true;
      state.firstName = firstName;
      state.lastName = lastName;
      state.userId = userId;
      state.roles = roles;
      state.isLoading = false;
      state.emailVerified = emailVerified;
      state.approved = approved;
      state.level = level;
      state.hasCompanyAssigned = hasCompanyAssigned;
    },
    setLoginFailed(state) {
      state.loginFailed = true;
    },
  }),
  ...createSelectors(userModule, {
    isInitialized: ({ isInitialized }) => isInitialized,
    isLoggedIn: ({ isLoggedIn }) => isLoggedIn,
    emailVerified: ({ emailVerified }) => emailVerified,
    isLoading: ({ isLoading }) => isLoading,
    userId: ({ userId }) => userId,
    isAdmin: ({ roles }) => roles?.indexOf("Admin") >= 0,
    loginFailed: ({ loginFailed }) => loginFailed,
    approved: ({ approved }) => approved,
    level: ({ level }) => level,
    hasCompanyAssigned: ({ hasCompanyAssigned }) => hasCompanyAssigned,
  }),
};
