import { yupResolver } from "@hookform/resolvers/yup";
import { GetPublicKeys, PublicKeyDto, UpdatePublicKey } from "api";
import getApiClient from "common/ApiClientFactory";
import KeyInputs from "components/KeyInputs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { KeyData, keyShape } from "validation/key";
import { InferType, object } from "yup";

const formSchema = object({
  coiTest: object(keyShape),
  coiProd: object(keyShape),
  miRecTest: object(keyShape),
  miRecProd: object(keyShape),
  miSubTest: object(keyShape),
  miSubProd: object(keyShape),
  siInitTest: object(keyShape),
  siInitProd: object(keyShape),
});

type FormData = InferType<typeof formSchema>;

const AcquirerKeys = ({ acquirerId }: { acquirerId: string }) => {
  const request = new GetPublicKeys({ targetId: acquirerId });

  const { data, isLoading } = useQuery(
    [request],
    () => {
      return getApiClient().get(request);
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  if (isLoading) {
    return null;
  }

  const mapKey = (key: PublicKeyDto) => {
    return {
      value630: key?.value630 ?? "",
      valuePost630: key?.valuePost630 ?? "",
      valuePre630: key?.valuePre630 ?? "",
      valuePost7402K: key?.valuePost7402K ?? "",
      valuePost7403K: key?.valuePost7403K ?? "",
      valuePost7404K: key?.valuePost7404K ?? "",
    } as KeyData;
  };

  const coiTest = data.find(
    (p) => p.system === "Test" && p.interface === "COI"
  );
  const coiProd = data.find(
    (p) => p.system === "Prod" && p.interface === "COI"
  );
  const miRecTest = data.find(
    (p) => p.system === "Test" && p.interface === "MI_REC"
  );
  const miRecProd = data.find(
    (p) => p.system === "Prod" && p.interface === "MI_REC"
  );
  const miSubTest = data.find(
    (p) => p.system === "Test" && p.interface === "MI_SUB"
  );
  const miSubProd = data.find(
    (p) => p.system === "Prod" && p.interface === "MI_SUB"
  );
  const siInitTest = data.find(
    (p) => p.system === "Test" && p.interface === "SI_INIT"
  );
  const siInitProd = data.find(
    (p) => p.system === "Prod" && p.interface === "SI_INIT"
  );

  const formData = {
    coiTest: mapKey(coiTest),
    coiProd: mapKey(coiProd),
    miRecTest: mapKey(miRecTest),
    miRecProd: mapKey(miRecProd),
    miSubTest: mapKey(miSubTest),
    miSubProd: mapKey(miSubProd),
    siInitTest: mapKey(siInitTest),
    siInitProd: mapKey(siInitProd),
  } as FormData;

  return <AcquirerKeysForm acquirerId={acquirerId} keys={formData} />;
};

const AcquirerKeysForm = ({
  acquirerId,
  keys,
}: {
  acquirerId: string;
  keys: FormData;
}) => {
  const methods = useForm<FormData>({
    defaultValues: keys,
    mode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: FormData) => {
    let api = getApiClient();

    let requests = [
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "COI",
        system: "Test",
        valuePre630: data.coiTest.valuePre630,
        value630: data.coiTest.value630,
        valuePost630: data.coiTest.valuePost630,
        valuePost7402K: data.coiTest.valuePost7402K,
        valuePost7403K: data.coiTest.valuePost7403K,
        valuePost7404K: data.coiTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "COI",
        system: "Prod",
        valuePre630: data.coiProd.valuePre630,
        value630: data.coiProd.value630,
        valuePost630: data.coiProd.valuePost630,
        valuePost7402K: data.coiProd.valuePost7402K,
        valuePost7403K: data.coiProd.valuePost7403K,
        valuePost7404K: data.coiProd.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "MI_REC",
        system: "Test",
        valuePre630: data.miRecTest.valuePre630,
        value630: data.miRecTest.value630,
        valuePost630: data.miRecTest.valuePost630,
        valuePost7402K: data.miRecTest.valuePost7402K,
        valuePost7403K: data.miRecTest.valuePost7403K,
        valuePost7404K: data.miRecTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "MI_REC",
        system: "Prod",
        valuePre630: data.miRecProd.valuePre630,
        value630: data.miRecProd.value630,
        valuePost630: data.miRecProd.valuePost630,
        valuePost7402K: data.miRecProd.valuePost7402K,
        valuePost7403K: data.miRecProd.valuePost7403K,
        valuePost7404K: data.miRecProd.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "MI_SUB",
        system: "Test",
        valuePre630: data.miSubTest.valuePre630,
        value630: data.miSubTest.value630,
        valuePost630: data.miSubTest.valuePost630,
        valuePost7402K: data.miSubTest.valuePost7402K,
        valuePost7403K: data.miSubTest.valuePost7403K,
        valuePost7404K: data.miSubTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "MI_SUB",
        system: "Prod",
        valuePre630: data.miSubProd.valuePre630,
        value630: data.miSubProd.value630,
        valuePost630: data.miSubProd.valuePost630,
        valuePost7402K: data.miSubProd.valuePost7402K,
        valuePost7403K: data.miSubProd.valuePost7403K,
        valuePost7404K: data.miSubProd.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "SI_INIT",
        system: "Test",
        valuePre630: data.siInitTest.valuePre630,
        value630: data.siInitTest.value630,
        valuePost630: data.siInitTest.valuePost630,
        valuePost7402K: data.siInitTest.valuePost7402K,
        valuePost7403K: data.siInitTest.valuePost7403K,
        valuePost7404K: data.siInitTest.valuePost7404K,
      }),
      new UpdatePublicKey({
        belongsTo: acquirerId,
        interface: "SI_INIT",
        system: "Prod",
        valuePre630: data.siInitProd.valuePre630,
        value630: data.siInitProd.value630,
        valuePost630: data.siInitProd.valuePost630,
        valuePost7402K: data.siInitProd.valuePost7402K,
        valuePost7403K: data.siInitProd.valuePost7403K,
        valuePost7404K: data.siInitProd.valuePost7404K,
      }),
    ];

    let promises = requests.map((m) => api.put(m));
    Promise.all(promises).then(() => toast.success("Keys updated"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <KeyInputs interface1="COI" testKey="coiTest" prodKey="coiProd" />
        <KeyInputs
          interface1="MI_REC"
          testKey="miRecTest"
          prodKey="miRecProd"
        />
        <KeyInputs
          interface1="MI_SUB"
          testKey="miSubTest"
          prodKey="miSubProd"
        />
        <KeyInputs
          interface1="SI_INIT"
          testKey="siInitTest"
          prodKey="siInitProd"
        />
        <Row className="mb-4 mt-4">
          <Col>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

export default AcquirerKeys;
