import React, { useEffect } from "react";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
  Badge,
  Button,
  InputGroupAddon,
} from "reactstrap";
import { useSimplux } from "@simplux/react";
import { GetKernelsRequest } from "api";
import getApiClient from "common/ApiClientFactory";
import OrderByToggle from "components/OrderByToggle";
import { Link, useHistory } from "react-router-dom";
import Pagination from "components/pagination/paginationInternal";
import { removeCollectionPrefix, copyToClipboard } from "common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { kernels } from "./kernel.module";

const KernelsTable = () => {
  const rows = useSimplux(kernels.rows);
  const page = useSimplux(kernels.page);
  const pageSize = useSimplux(kernels.pageSize);
  const search = useSimplux(kernels.search);
  const orderBy = useSimplux(kernels.orderBy);
  const total = useSimplux(kernels.total);
  const pages = useSimplux(kernels.pages);

  const history = useHistory();

  useEffect(() => {
    fetchData(page, pageSize, search, orderBy);
  }, [pageSize, search, page, orderBy]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Domestic Kernels</h3>
      </div>
      <Row>
        <Col lg="5" md="6">
          <FormGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Search"
                type="text"
                text={search}
                defaultValue={search}
                onChange={(event) => kernels.setSearch(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="2" md="4">
          <Input
            type="select"
            name="select"
            defaultValue={pageSize}
            onChange={(event) =>
              kernels.setPageSize(parseInt(event.target.value))
            }
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option value="133333337">All</option>
          </Input>
        </Col>
        <Col>
          <Button
            color="primary"
            onClick={() => history.push("/admin/kernels/new")}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Col>{`${rows.length} of ${total}`}</Col>
      </Row>

      <Table className="align-items-center table-sm table-hover kernels">
        <thead>
          <tr>
            <th scope="col">
              <OrderByToggle
                field="KernelId"
                text="Kernel ID"
                orderBy={orderBy}
                onChange={(orderBy) => kernels.setOrderBy(orderBy)}
                isNumeric={true}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="CompanyName"
                text="Company"
                orderBy={orderBy}
                onChange={(orderBy) => kernels.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">
              <OrderByToggle
                field="Description"
                text="Description"
                orderBy={orderBy}
                onChange={(orderBy) => kernels.setOrderBy(orderBy)}
              />
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((dto) => {
            return (
              <tr key={dto.id}>
                <td>
                  <Badge
                    color="primary"
                    className="pointer"
                    onClick={() => copyToClipboard(dto.kernelId)}
                  >
                    {dto.kernelId}
                  </Badge>
                </td>
                <td>
                  <Link to={`/${dto.company?.id}`}>{dto.company?.name}</Link>
                </td>
                <td>{dto.description}</td>
                <td>
                  <Link to={`kernels/${removeCollectionPrefix(dto.id)}`}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          {pages > 1 && (
            <Row>
              <Col>
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onChange={(page) => kernels.setPage(page)}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  orderBy: string
) {
  let client = getApiClient();
  let request = new GetKernelsRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = orderBy;

  let response = await client.get(request);

  kernels.fromResponse(response);
}

export default KernelsTable;
