import React from "react";
import { Table, Badge, Button } from "reactstrap";
import { useSimplux } from "@simplux/react";
import { TerminalRangeDto, DeleteTerminalRange } from "api";
import getApiClient from "common/ApiClientFactory";
import { search } from "./search.module";
import OrderByToggle from "components/OrderByToggle";
import { copyToClipboard, to11, removeCollectionPrefix } from "common/utils";
import { Link } from "react-router-dom";
import { toDateTime } from "common/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";
import Disabler from "components/Disabler";

const TerminalRangesTable = ({
  rows,
  shouldRefetch,
}: {
  rows: TerminalRangeDto[];
  shouldRefetch: () => void;
}) => {
  const orderBy = useSimplux(search.orderBy);

  const deleteTerminalRange = async (terminalRange: TerminalRangeDto) => {
    let confirmed = await confirm({
      title: "Delete Terminal Range",
      message: (
        <>
          <span>
            Please confirm deleting Terminal Range{" "}
            <Badge color="primary">{terminalRange.terminalRangeId}</Badge>.{" "}
            <strong>{terminalRange.description}</strong>.
          </span>
        </>
      ),
      confirmText: "confirm",
      confirmColor: "danger",
    });

    if (confirmed) {
      let request = new DeleteTerminalRange();
      request.id = terminalRange.id;

      let client = getApiClient();
      client.delete(request).then(() => {
        toast.success("Terminal Range deleted");
        shouldRefetch();
      });
    }
  };

  return (
    <Table className="align-items-center table-sm table-hover search-sc">
      <thead>
        <tr>
          <th scope="col">
            <OrderByToggle
              field="TerminalRangeId"
              text="Terminal Range ID"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="CompanyName"
              text="Company"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>

          <th scope="col">
            <OrderByToggle
              field="ServiceCenterNumber"
              text="Service Center ID"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="Description"
              text="Description"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="RequestDate"
              text="Request Date"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">
            <OrderByToggle
              field="DisableDate"
              text="Disable Date"
              orderBy={orderBy}
              onChange={(orderBy) => search.setOrderBy(orderBy)}
              isNumeric={true}
            />
          </th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((dto) => {
          const id = dto.terminalRangeId;
          const companyLink = `/search/companies/${removeCollectionPrefix(
            dto.companyId
          )}`;

          return (
            <tr key={dto.id}>
              <td>
                <Badge
                  color="primary"
                  className="pointer"
                  onClick={() => copyToClipboard(id?.toString())}
                >
                  {id}
                </Badge>
              </td>
              <td>
                {companyLink && <Link to={companyLink}>{dto.companyName}</Link>}
              </td>
              <td>
                <Link
                  to={`/service-centers/${removeCollectionPrefix(
                    dto.serviceCenterId
                  )}`}
                >
                  {to11(dto.serviceCenterNumber)}
                </Link>
              </td>
              <td>{dto.description}</td>
              <td>{toDateTime(dto.requestDate)}</td>
              <td>
                <Disabler
                  disabledDate={dto.disableDate}
                  targetId={dto.id}
                  onUpdated={() => shouldRefetch()}
                  id={dto.terminalRangeId?.toString()}
                  description={dto.description}
                />
                {dto.disableDate && (
                  <Button
                    color="danger"
                    size="sm mx-4"
                    onClick={async () => await deleteTerminalRange(dto)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </td>
              <td>
                <Link
                  to={`/search/terminal-ranges/${removeCollectionPrefix(
                    dto.id
                  )}`}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TerminalRangesTable;
