import {
  createMutations,
  createSelectors,
  createSimpluxModule,
} from "@simplux/core";
import { NonceOffsetDto, PagedResponse } from "api";

const nonceOffsetsModule = createSimpluxModule({
  name: "nonce-offsets",
  initialState: {
    rows: [] as NonceOffsetDto[],
    total: 0,
    page: 1,
    pageSize: 25,
    search: "",
    orderBy: "NonceOffsetId",
  },
});

export const nonceOffsets = {
  ...nonceOffsetsModule,
  ...createMutations(nonceOffsetsModule, {
    fromResponse(state, response: PagedResponse<NonceOffsetDto>) {
      state.rows = response.rows;
      state.total = response.totalRows;
      state.page = response.page;
      state.pageSize = response.pageSize;
    },
    setPageSize(state, pageSize: number) {
      state.page = 1;
      state.pageSize = pageSize;
    },
    setSearch(state, search: string) {
      state.search = search;
      state.page = 1;
    },
    setOrderBy(state, orderBy: string) {
      state.orderBy = orderBy;
    },
    setPage(state, page: number) {
      state.page = page;
    },
  }),
  ...createSelectors(nonceOffsetsModule, {
    rows: ({ rows }) => rows,
    page: ({ page }) => page,
    pageSize: ({ pageSize }) => pageSize,
    search: ({ search }) => search,
    orderBy: ({ orderBy }) => orderBy,
    total: ({ total }) => total,
    pages: ({ total, pageSize }) => Math.ceil(total / pageSize),
  }),
};
