import React, { useEffect, useState } from "react";
import Hero from "components/Hero";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import getApiClient from "common/ApiClientFactory";
import { GetAssignedCompanies, CompanyDto } from "api";
import ContactPersonDisplay from "components/ContactPersonDisplay";

const MyCompanies = () => {
  const [companies, setCompanies] = useState<CompanyDto[]>();

  useEffect(() => {
    async function async() {
      let client = getApiClient();
      let request = new GetAssignedCompanies();

      let response = await client.get(request);

      setCompanies(response);
    }

    async();
  }, []);

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="d-flex justify-content-between">
            <h3>Companies</h3>
          </div>
          <Row>
            <Col>
              <ListGroup className="mb-2">
                {companies?.map((company, i) => {
                  return (
                    <ListGroupItem key={company.id}>
                      <div className="d-flex justify-content-between">
                        <div className="flex-fill">
                          <h5 className="mb-1">
                            <Link to={`/${company.id}`} className="mr-1">
                              {company.name}
                            </Link>
                          </h5>
                          <p className="mb-1">
                            {company.street1}, {company.postCode} {company.city}
                          </p>
                        </div>
                        <div className="px-2 listview-block">
                          <ContactPersonDisplay
                            dto={company.primaryContactPerson}
                          />
                        </div>
                        <div>
                          <small>
                            <Link to={company.id}>
                              <Button size="sm" color="primary">
                                Manage
                              </Button>
                            </Link>
                          </small>
                        </div>
                      </div>
                    </ListGroupItem>
                  );
                })}
                {companies?.length === 0 && (
                  <ListGroupItem>
                    No companies are assigned to you. Request one below.
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Link to="/request/company">
                <Button color="success">Request Company</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyCompanies;
