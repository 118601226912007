import { PublicKeyDto } from "api";
import { InferType, object, string } from "yup";

const getModulusLength1Byte = (key: string) => {
  if (!key) {
    return 0;
  }

  const regex =
    /^(?<OwnerIdentifier>[0-9]{10})(?<Index>[0-9A-F]{2})(?<Expiration>[0-9]{4})(?<HashAlgoIndicator>[0-9A-F]{2})(?<AlgoIndicator>[0-9A-F]{2})(?<ModulusLength>[0-9A-F]{2})(?<Exponent>[0-9A-F]{2})(?<Modulus>[0-9A-F]+)/s;

  const match = key.match(regex);

  if (!match) {
    return 0;
  }

  const keySizeInBytes = parseInt(match.groups["ModulusLength"], 16);

  return keySizeInBytes;
};

const getModulusLength2Bytes = (key: string) => {
  if (!key) {
    return "0";
  }

  const regex =
    /^(?<OwnerIdentifier>[0-9]{10})(?<Index>[0-9A-F]{2})(?<Expiration>[0-9]{4})(?<HashAlgoIndicator>[0-9A-F]{2})(?<AlgoIndicator>[0-9A-F]{2})(?<ModulusLength>[0-9A-F]{4})(?<Exponent>[0-9A-F]{2})(?<Modulus>[0-9A-F]+)/s;

  const match = key.match(regex);

  if (!match) {
    return "0";
  }

  //return parseInt(match.groups["ModulusLength"], 16);
  return match.groups["ModulusLength"];
};

const getModulusLength2BytesValue = (key: string) => {
  if (!key) {
    return 0;
  }

  const regex =
    /^(?<OwnerIdentifier>[0-9]{10})(?<Index>[0-9A-F]{2})(?<Expiration>[0-9]{4})(?<HashAlgoIndicator>[0-9A-F]{2})(?<AlgoIndicator>[0-9A-F]{2})(?<ModulusLength>[0-9A-F]{4})(?<Exponent>[0-9A-F]{2})(?<Modulus>[0-9A-F]+)/s;

  const match = key.match(regex);

  if (!match) {
    return 0;
  }

  let value = parseInt(match.groups["ModulusLength"], 16);

  console.log("value ", value);

  return value;
};

const validateSingleByteLength = (value: string) => {
  if (!value) {
    return true;
  }

  let modulusLength = getModulusLength1Byte(value);

  return modulusLength > 0 && modulusLength <= 248; // F8
};

const keyShape = {
  valuePre630: string().test("modulus-length", validateSingleByteLength),
  value630: string().test(
    "modulus-length",
    (value) => {
      return (
        !value || getModulusLength2BytesValue(value) <= 256 // <= 0x0100 <= 256 (TODO)
      );
    } // <= 0x0100 <= 256 (TODO)
  ),
  valuePost630: string().test(
    "modulus-length",
    (value) => !value || getModulusLength2Bytes(value) === "0100"
  ),
  valuePost7402K: string().test(
    "modulus-length",
    (value) => !value || getModulusLength2Bytes(value) === "0100"
  ),
  valuePost7403K: string().test(
    "modulus-length",
    (value) => !value || getModulusLength2Bytes(value) === "0180"
  ),
  valuePost7404K: string().test(
    "modulus-length",
    (value) => !value || getModulusLength2Bytes(value) === "0200"
  ),
};

const keySchema = object(keyShape);
export type KeyData = InferType<typeof keySchema>;

const dtoToFormData = (key: PublicKeyDto) => {
  return {
    value630: key?.value630 ?? "",
    valuePost630: key?.valuePost630 ?? "",
    valuePre630: key?.valuePre630 ?? "",
    valuePost7402K: key?.valuePost7402K ?? "",
    valuePost7403K: key?.valuePost7403K ?? "",
    valuePost7404K: key?.valuePost7404K ?? "",
  } as KeyData;
};

export {
  getModulusLength1Byte,
  getModulusLength2Bytes,
  validateSingleByteLength,
  keyShape,
  dtoToFormData,
};
