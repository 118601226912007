import { useSimplux } from "@simplux/react";
import { GetRequestsRequest, RequestDto } from "api";
import getApiClient from "common/ApiClientFactory";
import Hero from "components/Hero";
import Pagination from "components/pagination/paginationInternal";
import { user } from "pages/user/user.module";
import React, { useEffect } from "react";
import {
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import AcquirerRequest from "./AcquirerRequest";
import AidRequest from "./AidRequest";
import CompanyRequest from "./CompanyRequest";
import MerchantRangeRequest from "./MerchantRangeRequest";
import PendingUsersInfo from "./PendingUsersInfo";
import PMSRequest from "./PMSRequest";
import { requests } from "./requests.module";
import ServiceCenterRequest from "./ServiceCenterRequest";
import TerminalRangeRequest from "./TerminalRangeRequest";

const Requests = () => {
  const isAdmin = useSimplux(user.isAdmin);

  const rows = useSimplux(requests.rows);
  const page = useSimplux(requests.page);
  const pageSize = useSimplux(requests.pageSize);
  const search = useSimplux(requests.search);
  const pages = useSimplux(requests.pages);
  const showOnlyOpen = useSimplux(requests.showOnlyOpen);
  const total = useSimplux(requests.total);

  useEffect(() => {
    fetchData(page, pageSize, search, showOnlyOpen);
  }, [pageSize, search, page, showOnlyOpen]);

  return (
    <>
      <Hero />
      <Container fluid={isAdmin}>
        <section className="section mb-dropdown m-4">
          <div className="d-flex justify-content-between">
            <h3>Requests</h3>
          </div>
          <Row>
            <Col lg="5" md="6">
              <FormGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Search"
                    type="text"
                    text={search}
                    onChange={(event) => requests.setSearch(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="2" md="4">
              <Input
                type="select"
                name="select"
                value={pageSize}
                onChange={(event) =>
                  requests.setPageSize(parseInt(event.target.value))
                }
              >
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option value="133333337">All</option>
              </Input>
            </Col>
            <Col>
              <div className="custom-control custom-checkbox mt-2">
                <input
                  className="custom-control-input"
                  id="onlyPending"
                  type="checkbox"
                  defaultChecked={showOnlyOpen}
                  onChange={() => requests.setShowOnlyOpen(!showOnlyOpen)}
                />
                <label className="custom-control-label" htmlFor="onlyPending">
                  Open
                </label>
              </div>
            </Col>
            <Col>{`${rows.length} of ${total}`}</Col>
          </Row>
          {isAdmin && (
            <Row>
              <Col>
                <PendingUsersInfo />
              </Col>
            </Row>
          )}
          {rows.map((dto) => {
            if (dto.type === "Company") {
              return (
                <CompanyRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (dto.type === "PMS") {
              return (
                <PMSRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (dto.type === "Acquirer") {
              return (
                <AcquirerRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (dto.type === "ServiceCenter") {
              return (
                <ServiceCenterRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (dto.type === "MerchantRange") {
              return (
                <MerchantRangeRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (dto.type === "TerminalRange") {
              return (
                <TerminalRangeRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() =>
                    fetchData(page, pageSize, search, showOnlyOpen)
                  }
                />
              );
            } else if (
              dto.type === "MagStripeAid" ||
              dto.type === "DomesticChipAid" ||
              dto.type === "InternationalChipAid" ||
              dto.type === "MiFareChipAid"
            ) {
              return (
                <AidRequest
                  request={dto as RequestDto}
                  key={dto.id}
                  dataChanged={() => {
                    fetchData(page, pageSize, search, showOnlyOpen);
                  }}
                />
              );
            }

            // } else if (dto.type === 'TerminalSupplier') {
            //   return <TerminalSupplierIdRequest request={dto} key={dto.id} dataChanged={() => fetchData(page, pageSize, search, showOnlyOpen)} />
            // } else if (dto.type === 'Application') {
            //   return <ApplicationIdRequest request={dto} key={dto.id} dataChanged={() => fetchData(page, pageSize, search, showOnlyOpen)} />
            // }

            return null;
          })}
          <Row>
            <Col>
              {pages > 1 && (
                <Row>
                  <Col>
                    <Pagination
                      currentPage={page}
                      totalPages={pages}
                      onChange={(page) => requests.setPage(page)}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

async function fetchData(
  page: number,
  pageSize: number,
  search: string,
  filterForOpen: boolean
) {
  let client = getApiClient();
  let request = new GetRequestsRequest();
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.filterForOpen = filterForOpen;

  let response = await client.get(request);

  requests.fromResponse(response);
}

export default Requests;
