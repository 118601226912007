import {
  faCertificate,
  faEdit,
  faIdBadge,
  faKey,
  faStoreAlt,
  faTerminal,
  faTrash,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import {
  CreateServiceCenter,
  DeleteMerchantRange,
  DeleteTerminalRange,
  DeleteTerminalSupplier,
  GetMerchantRangesRequest,
  GetServiceCenterRequest,
  GetTerminalRangesRequest,
  GetTerminalSuppliersRequest,
  MerchantRangeDto,
  PatchTerminalSupplier,
  ServiceCenterDto,
  TerminalRangeDto,
  TerminalSupplierDto,
  UpdateMerchantRangeState,
  UpdateServiceCenterRequest,
  UpdateTerminalRangeState,
} from "api";
import classnames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { toDateTime } from "common/date";
import { thousandSplit } from "common/numbers";
import { removeCollectionPrefix, to11 } from "common/utils";
import CompanySelect from "components/CompanySelect";
import Hero from "components/Hero";
import Pagination from "components/pagination/paginationInternal";
import { user } from "pages/user/user.module";
import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import Certificates from "../certificates/Certificates";
import Communications from "../communications/Communications";
import RequestMerchantRange from "./RequestMerchantRange";
import RequestTerminalRange from "./RequestTerminalRange";
import RequestTerminalSupplier from "./RequestTerminalSupplier";
import { serviceCenterDetail } from "./service-center-detail.module";
import ServiceCenterKeys from "./ServiceCenterKeys";

const ServiceCenterDetail = () => {
  const { id } = useParams<any>();
  const dto = useSimplux(serviceCenterDetail.dto);
  const activeTab = useSimplux(serviceCenterDetail.activeTab);

  const merchantRanges = useSimplux(serviceCenterDetail.merchantRangesResponse);
  const merchantRangesPage = useSimplux(serviceCenterDetail.merchantRangesPage);
  const merchantRangesPageSize = useSimplux(
    serviceCenterDetail.merchantRangesPageSize
  );
  const merchantRangesSearch = useSimplux(
    serviceCenterDetail.merchantRangesSearch
  );
  const merchantRangesPages = useSimplux(
    serviceCenterDetail.merchantRangesPages
  );

  const terminalRanges = useSimplux(serviceCenterDetail.terminalRangesResponse);
  const terminalRangesPage = useSimplux(serviceCenterDetail.terminalRangesPage);
  const terminalRangesPageSize = useSimplux(
    serviceCenterDetail.terminalRangesPageSize
  );
  const terminalRangesSearch = useSimplux(
    serviceCenterDetail.terminalRangesSearch
  );
  const terminalRangesPages = useSimplux(
    serviceCenterDetail.terminalRangesPages
  );

  const terminalSuppliers = useSimplux(
    serviceCenterDetail.terminalSuppliersResponse
  );
  const terminalSuppliersPage = useSimplux(
    serviceCenterDetail.terminalSuppliersPage
  );
  const terminalSuppliersPageSize = useSimplux(
    serviceCenterDetail.terminalSuppliersPageSize
  );
  const terminalSuppliersSearch = useSimplux(
    serviceCenterDetail.terminalSuppliersSearch
  );
  const terminalSuppliersPages = useSimplux(
    serviceCenterDetail.terminalSuppliersPages
  );

  const isAdmin = useSimplux(user.isAdmin);

  const history = useHistory();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ServiceCenterDto>();

  const onSubmit = async (data: ServiceCenterDto) => {
    let client = getApiClient();

    if (id === "new") {
      let request = new CreateServiceCenter();
      Object.assign(request, data);

      request.description = data.description;
      request.remarks = data.remarks;
      request.serviceCenterId = data.serviceCenterId;
      request.companyId = data.company?.id;

      client
        .post(request)
        .then((response) => {
          toast.success("Service Center created");
          history.push(
            `/service-centers/${removeCollectionPrefix(response.id)}`
          );
        })
        .catch(() => {
          toast.error("Error");
        });
    } else {
      let request = new UpdateServiceCenterRequest();

      // from form
      request.description = data.description;
      request.remarks = data.remarks;
      request.serviceCenterId = data.serviceCenterId;
      request.companyId = data.company?.id;

      // not updated fields
      request.acceptDate = dto.acceptDate;
      request.requestDate = dto.requestDate;
      request.disableDate = dto.disableDate;
      request.id = dto.id;

      client
        .put(request)
        .then(() => {
          toast.success("Service Center updated");
        })
        .catch(() => {
          toast.error("Error");
        });
    }
  };

  useEffect(() => {
    async function async() {
      const client = getApiClient();

      if (id !== "new") {
        let request = new GetServiceCenterRequest();

        request.id = `servicecenters/${id}`;
        let dto = await client.get(request);

        reset(dto);
        serviceCenterDetail.setServiceCenter(dto);
      }
    }

    async();
  }, [id, reset]);

  useEffect(() => {
    if (!dto || !dto.id) {
      return;
    }

    loadMerchantRanges(
      dto.id,
      merchantRangesPage,
      merchantRangesPageSize,
      merchantRangesSearch
    );
  }, [
    dto,
    dto.id,
    merchantRangesPage,
    merchantRangesPageSize,
    merchantRangesSearch,
  ]);

  useEffect(() => {
    if (!dto || !dto.id) {
      return;
    }

    loadTerminalRanges(
      dto.id,
      terminalRangesPage,
      terminalRangesPageSize,
      terminalRangesSearch
    );
  }, [
    dto,
    dto.id,
    terminalRangesPage,
    terminalRangesPageSize,
    terminalRangesSearch,
  ]);

  useEffect(() => {
    if (!dto || !dto.id) {
      return;
    }

    loadTerminalSuppliers(
      dto.id,
      terminalSuppliersPage,
      terminalSuppliersPageSize,
      terminalSuppliersSearch
    );
  }, [
    dto,
    dto.id,
    terminalSuppliersPage,
    terminalSuppliersPageSize,
    terminalSuppliersSearch,
  ]);

  const updateMerchantRange = (merchantRangeId: string, disabled: boolean) => {
    let client = getApiClient();
    let request = new UpdateMerchantRangeState();
    request.id = merchantRangeId;
    request.disable = disabled;

    client.put(request).then(() => {
      toast.success("Merchant Range updated");
      loadMerchantRanges(
        dto.id,
        merchantRangesPage,
        merchantRangesPageSize,
        merchantRangesSearch
      );
    });
  };

  const updateTerminalRange = (terminalRangeId: string, disabled: boolean) => {
    let client = getApiClient();
    let request = new UpdateTerminalRangeState();
    request.id = terminalRangeId;
    request.disable = disabled;

    client.put(request).then(() => {
      toast.success("Terminal Range updated");
      loadTerminalRanges(
        dto.id,
        terminalRangesPage,
        terminalRangesPageSize,
        terminalRangesSearch
      );
    });
  };

  const updateTerminalSupplier = (
    terminalSupplierId: string,
    disabled: boolean
  ) => {
    let client = getApiClient();
    let request = new PatchTerminalSupplier();
    request.id = terminalSupplierId;
    request.disable = disabled;

    client.patch(request).then(() => {
      toast.success("Terminal Supplier updated");
      loadTerminalSuppliers(
        dto.id,
        terminalSuppliersPage,
        terminalSuppliersPageSize,
        terminalSuppliersSearch
      );
    });
  };

  const onDeleteMerchantRangeClick = async (
    merchantRange: MerchantRangeDto
  ) => {
    let confirmed = await confirm({
      title: "Delete Merchant Range",
      message: (
        <>
          <span>
            Please confirm deleting Merchant Range{" "}
            <Badge color="primary">{merchantRange.merchantRangeId}</Badge> of{" "}
            <strong>{merchantRange.description}</strong>.
          </span>
        </>
      ),
      confirmText: "confirm",
      confirmColor: "danger",
    });

    if (confirmed) {
      let request = new DeleteMerchantRange();
      request.id = merchantRange.id;

      let client = getApiClient();
      client.delete(request).then(() => {
        toast.success("Merchant Range deleted");
        loadMerchantRanges(
          dto.id,
          merchantRangesPage,
          merchantRangesPageSize,
          merchantRangesSearch
        );
      });
    }
  };

  const onDeleteTerminalRangeClick = async (
    terminalRange: TerminalRangeDto
  ) => {
    let confirmed = await confirm({
      title: "Delete Terminal Range",
      message: (
        <>
          <span>
            Please confirm deleting Terminal Range{" "}
            <Badge color="primary">{terminalRange.terminalRangeId}</Badge>{" "}
            <strong>({terminalRange.description})</strong>.
          </span>
        </>
      ),
      confirmText: "confirm",
      confirmColor: "danger",
    });

    if (confirmed) {
      let request = new DeleteTerminalRange();
      request.id = terminalRange.id;

      let client = getApiClient();
      client.delete(request).then(() => {
        toast.success("Terminal Range deleted");
        loadTerminalRanges(
          dto.id,
          terminalRangesPage,
          terminalRangesPageSize,
          terminalRangesSearch
        );
      });
    }
  };

  const onDeleteTerminalSupplierClick = async (
    terminalSupplier: TerminalSupplierDto
  ) => {
    let confirmed = await confirm({
      title: "Delete Terminal Range",
      message: (
        <>
          <span>
            Please confirm deleting Terminal Supplier{" "}
            <Badge color="primary">{terminalSupplier.terminalSupplierId}</Badge>{" "}
            <strong>({terminalSupplier.description})</strong>.
          </span>
        </>
      ),
      confirmText: "confirm",
      confirmColor: "danger",
    });

    if (confirmed) {
      let request = new DeleteTerminalSupplier();
      request.id = terminalSupplier.id;

      let client = getApiClient();
      client.delete(request).then(() => {
        toast.success("Terminal Supplier deleted");
        loadTerminalSuppliers(
          dto.id,
          terminalSuppliersPage,
          terminalSuppliersPageSize,
          terminalSuppliersSearch
        );
      });
    }
  };

  const buttonText = id === "new" ? "add" : "save";

  const Title = () => {
    const serviceCenterId = useWatch({
      control: control,
      name: "serviceCenterId",
    });

    return (
      <h3>
        Service Center{" "}
        <small className="text-primary">{to11(serviceCenterId)}</small>
      </h3>
    );
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container className="mb-5">
          <Title />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Details</small>
            </div>
            {isAdmin && (
              <Row>
                <Col lg="6" sm="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="serviceCenterId"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Service Center ID"
                          type="number"
                          {...field}
                          invalid={!!errors.serviceCenterId}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg="6" sm="12">
                <FormGroup>
                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Description"
                        type="text"
                        {...field}
                        invalid={!!errors.description}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isAdmin && (
              <>
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Controller
                        control={control}
                        name="remarks"
                        render={({ field }) => (
                          <Input placeholder="Remarks" type="text" {...field} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Assigned Company
                  </small>
                </div>

                <Row>
                  <Col>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field }) => <CompanySelect {...field} />}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-2">
              <Col>
                <Button type="submit" color="primary">
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </form>
        </Container>

        {id !== "new" && (
          <Container>
            <hr />
            <Nav tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "merchant-ranges",
                  })}
                  onClick={() => {
                    serviceCenterDetail.setActiveTab("merchant-ranges");
                  }}
                >
                  <FontAwesomeIcon icon={faStoreAlt} /> Merchant Ranges{" "}
                  <Badge color="info">
                    {thousandSplit(merchantRanges?.totalRows)}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "terminal-ranges",
                  })}
                  onClick={() => {
                    serviceCenterDetail.setActiveTab("terminal-ranges");
                  }}
                >
                  <FontAwesomeIcon icon={faTerminal} /> Terminal Ranges{" "}
                  <Badge color="info">
                    {thousandSplit(terminalRanges?.totalRows)}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "terminal-suppliers",
                  })}
                  onClick={() => {
                    serviceCenterDetail.setActiveTab("terminal-suppliers");
                  }}
                >
                  <FontAwesomeIcon icon={faIdBadge} /> Terminal Supplier IDs{" "}
                  <Badge color="info">
                    {thousandSplit(terminalSuppliers?.totalRows)}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "public-keys",
                  })}
                  onClick={() => {
                    serviceCenterDetail.setActiveTab("public-keys");
                  }}
                >
                  <FontAwesomeIcon icon={faKey} /> Public Keys
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "certificates",
                  })}
                  onClick={() => {
                    serviceCenterDetail.setActiveTab("certificates");
                  }}
                >
                  <FontAwesomeIcon icon={faCertificate} /> Root Certs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "communications",
                  })}
                  onClick={() =>
                    serviceCenterDetail.setActiveTab("communications")
                  }
                >
                  <FontAwesomeIcon icon={faTty} /> Comm.
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pt-3">
              <TabPane tabId="merchant-ranges">
                <Row>
                  <Col md="10">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search merchant range..."
                          type="text"
                          text={merchantRangesSearch}
                          onChange={(event) =>
                            serviceCenterDetail.setMerchantRangesSearch(
                              event.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <Input
                          type="select"
                          name="select"
                          value={merchantRangesPageSize}
                          onChange={(event) =>
                            serviceCenterDetail.setMerchantRangesPageSize(
                              parseInt(event.target.value)
                            )
                          }
                        >
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option value="133333337">All</option>
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Table className="align-items-center table-bordered table-hover table-responsive-md table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Merchant Range ID</th>
                          <th scope="col">Description</th>
                          <th scope="col">Request Date</th>
                          <th scope="col">Disable Date</th>
                          <th scope="col">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {merchantRanges?.rows?.map((merchantRange) => {
                          return (
                            <tr key={merchantRange.id}>
                              <td>
                                <Badge color="primary">
                                  {merchantRange.merchantRangeId}
                                </Badge>
                              </td>
                              <td>{merchantRange.description}</td>
                              <td>{toDateTime(merchantRange.requestDate)}</td>
                              <td>{toDateTime(merchantRange.disableDate)}</td>
                              <td>
                                {merchantRange.disableDate ? (
                                  <>
                                    <Button
                                      color="success"
                                      size="sm"
                                      onClick={() =>
                                        updateMerchantRange(
                                          merchantRange.id,
                                          false
                                        )
                                      }
                                    >
                                      enable
                                    </Button>
                                    {isAdmin && (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="float-right"
                                        onClick={() =>
                                          onDeleteMerchantRangeClick(
                                            merchantRange
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    color="warning"
                                    size="sm"
                                    onClick={() =>
                                      updateMerchantRange(
                                        merchantRange.id,
                                        true
                                      )
                                    }
                                  >
                                    disable
                                  </Button>
                                )}
                                <Link
                                  to={`/service-centers/${id}/merchant-ranges/${removeCollectionPrefix(
                                    merchantRange.id
                                  )}`}
                                >
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="float-right mr-2"
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                        {merchantRanges?.totalRows === 0 && (
                          <tr>
                            <td colSpan={5}>no entries</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {merchantRangesPages > 1 && (
                  <Row>
                    <Col>
                      <Pagination
                        currentPage={merchantRangesPage}
                        totalPages={merchantRangesPages}
                        onChange={(page) =>
                          serviceCenterDetail.setMerchantRangesPage(page)
                        }
                      />
                    </Col>
                  </Row>
                )}
                <RequestMerchantRange
                  serviceCenterId={dto.id}
                  onChange={() =>
                    loadMerchantRanges(
                      dto.id,
                      merchantRangesPage,
                      merchantRangesPageSize,
                      merchantRangesSearch
                    )
                  }
                />
              </TabPane>
              <TabPane tabId="terminal-ranges">
                <Row>
                  <Col md="10">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search terminal range..."
                          type="text"
                          text={terminalRangesSearch}
                          onChange={(event) =>
                            serviceCenterDetail.setTerminalRangesSearch(
                              event.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <Input
                          type="select"
                          name="select"
                          value={terminalRangesPageSize}
                          onChange={(event) =>
                            serviceCenterDetail.setTerminalRangesPageSize(
                              parseInt(event.target.value)
                            )
                          }
                        >
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option value="133333337">All</option>
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Table className="align-items-center table-bordered table-hover table-responsive-md table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Terminal Range ID</th>
                          <th scope="col">Description</th>
                          <th scope="col">Request date</th>
                          <th scope="col">Disabled date</th>
                          <th scope="col">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {terminalRanges?.rows?.map((terminalRange) => {
                          return (
                            <tr key={terminalRange.id}>
                              <td>
                                <Badge color="primary">
                                  {terminalRange.terminalRangeId}
                                </Badge>
                              </td>
                              <td>{terminalRange.description}</td>
                              <td>{toDateTime(terminalRange.requestDate)}</td>
                              <td>{toDateTime(terminalRange.disableDate)}</td>
                              <td>
                                {terminalRange.disableDate ? (
                                  <>
                                    <Button
                                      color="success"
                                      size="sm"
                                      onClick={() =>
                                        updateTerminalRange(
                                          terminalRange.id,
                                          false
                                        )
                                      }
                                    >
                                      enable
                                    </Button>
                                    {isAdmin && (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="float-right"
                                        onClick={() =>
                                          onDeleteTerminalRangeClick(
                                            terminalRange
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    color="warning"
                                    size="sm"
                                    onClick={() =>
                                      updateTerminalRange(
                                        terminalRange.id,
                                        true
                                      )
                                    }
                                  >
                                    disable
                                  </Button>
                                )}
                                <Link
                                  to={`/service-centers/${id}/terminal-ranges/${removeCollectionPrefix(
                                    terminalRange.id
                                  )}`}
                                >
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="float-right mr-2"
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                        {terminalRanges?.totalRows === 0 && (
                          <tr>
                            <td colSpan={5}>no entries</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {terminalRangesPages > 1 && (
                  <Row>
                    <Col>
                      <Pagination
                        currentPage={terminalRangesPage}
                        totalPages={terminalRangesPages}
                        onChange={(page) =>
                          serviceCenterDetail.setTerminalRangesPage(page)
                        }
                      />
                    </Col>
                  </Row>
                )}
                <RequestTerminalRange
                  serviceCenterId={dto.id}
                  onChange={() =>
                    loadTerminalRanges(
                      dto.id,
                      terminalRangesPage,
                      terminalRangesPageSize,
                      terminalRangesSearch
                    )
                  }
                />
              </TabPane>
              <TabPane tabId="terminal-suppliers">
                <Row>
                  <Col md="10">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search terminal suppliers..."
                          type="text"
                          text={terminalRangesSearch}
                          onChange={(event) =>
                            serviceCenterDetail.setTerminalSuppliersSearch(
                              event.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup className="mb-1 mt-2">
                        <Input
                          type="select"
                          name="select"
                          value={terminalSuppliersPageSize}
                          onChange={(event) =>
                            serviceCenterDetail.setTerminalSuppliersPageSize(
                              parseInt(event.target.value)
                            )
                          }
                        >
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option value="133333337">All</option>
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Table className="align-items-center table-bordered table-hover table-sm terminal-suppliers">
                      <thead>
                        <tr>
                          <th scope="col">TSU ID</th>
                          <th scope="col">Description</th>
                          <th scope="col">Request date</th>
                          <th scope="col">Disabled date</th>
                          <th scope="col">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {terminalSuppliers?.rows?.map((terminalSupplier) => {
                          return (
                            <tr key={terminalSupplier.id}>
                              <td>
                                <Badge color="primary">
                                  {terminalSupplier.terminalSupplierId}
                                </Badge>
                              </td>
                              <td>{terminalSupplier.description}</td>
                              <td>
                                {toDateTime(terminalSupplier.requestDate)}
                              </td>
                              <td>
                                {toDateTime(terminalSupplier.disableDate)}
                              </td>
                              <td>
                                {terminalSupplier.disableDate ? (
                                  <>
                                    <Button
                                      color="success"
                                      size="sm"
                                      onClick={() =>
                                        updateTerminalSupplier(
                                          terminalSupplier.id,
                                          false
                                        )
                                      }
                                    >
                                      enable
                                    </Button>
                                    {isAdmin && (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="float-right"
                                        onClick={() =>
                                          onDeleteTerminalSupplierClick(
                                            terminalSupplier as TerminalSupplierDto
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    color="warning"
                                    size="sm"
                                    onClick={() =>
                                      updateTerminalSupplier(
                                        terminalSupplier.id,
                                        true
                                      )
                                    }
                                  >
                                    disable
                                  </Button>
                                )}
                                <Link
                                  to={`/service-centers/${id}/terminal-suppliers/${removeCollectionPrefix(
                                    terminalSupplier.id
                                  )}`}
                                >
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="float-right mr-2"
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                        {terminalRanges?.totalRows === 0 && (
                          <tr>
                            <td colSpan={5}>no entries</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {terminalSuppliersPages > 1 && (
                  <Row>
                    <Col>
                      <Pagination
                        currentPage={terminalSuppliersPage}
                        totalPages={terminalSuppliersPages}
                        onChange={(page) =>
                          serviceCenterDetail.setTerminalSuppliersPage(page)
                        }
                      />
                    </Col>
                  </Row>
                )}
                <RequestTerminalSupplier
                  serviceCenterId={dto.id}
                  onChange={() =>
                    loadTerminalSuppliers(
                      dto.id,
                      terminalSuppliersPage,
                      terminalSuppliersPageSize,
                      terminalSuppliersSearch
                    )
                  }
                />
              </TabPane>
              <TabPane tabId="public-keys">
                <ServiceCenterKeys serviceCenterId={`ServiceCenters/${id}`} />
              </TabPane>
              <TabPane tabId="certificates">
                <Certificates
                  TargetId={`ServiceCenters/${id}`}
                  AllowedInterfaces={["SI_CONF"]}
                />
              </TabPane>
              <TabPane tabId="communications">
                <Communications
                  TargetId={`ServiceCenters/${id}`}
                  AllowedInterfaces={["SI_CONF"]}
                />
              </TabPane>
            </TabContent>
          </Container>
        )}
      </section>
    </>
  );
};

async function loadMerchantRanges(serviceCenterId, page, pageSize, search) {
  const client = getApiClient();
  let request = new GetMerchantRangesRequest();
  request.serviceCenterId = serviceCenterId;
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = "MerchantRangeId";

  let response = await client.get(request);
  serviceCenterDetail.setMerchantRanges(response);
}

async function loadTerminalRanges(serviceCenterId, page, pageSize, search) {
  const client = getApiClient();
  let request = new GetTerminalRangesRequest();
  request.serviceCenterId = serviceCenterId;
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = "TerminalRangeId";

  let response = await client.get(request);
  serviceCenterDetail.setTerminalRanges(response);
}

async function loadTerminalSuppliers(serviceCenterId, page, pageSize, search) {
  const client = getApiClient();
  let request = new GetTerminalSuppliersRequest();
  request.serviceCenterId = serviceCenterId;
  request.page = page;
  request.pageSize = pageSize;
  request.search = search;
  request.orderBy = "TerminalSupplierId";

  let response = await client.get(request);
  serviceCenterDetail.setTerminalSuppliers(response);
}

export default ServiceCenterDetail;
