import React, { useEffect } from "react";
import Hero from "components/Hero";
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Container,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { pms } from "pages/admin/pms/pms.module";
import { useSimplux } from "@simplux/react";
import getApiClient from "common/ApiClientFactory";
import { GetPMSsRequest, PMSDto, DisableRequest, CompanyDto } from "api";
import CompanyDisplay from "components/CompanyDisplay";
import ContactPersonDisplay from "components/ContactPersonDisplay";
import confirm from "reactstrap-confirm";
import { toast } from "react-toastify";

const MyPMS = () => {
  const rows = useSimplux(pms.rows);

  useEffect(() => {
    fetchPms();
  }, []);

  const disable = async (pms: PMSDto) => {
    let confirmed = await confirm({
      title: "Disabling",
      message: (
        <>
          <span>
            Please confirm disabling PMS{" "}
            <Badge color="primary">{pms.pmsId}</Badge>{" "}
            <strong>{pms.description}.</strong>
          </span>
        </>
      ),
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DisableRequest();
      request.id = pms.id;
      request.disable = true;

      let client = getApiClient();
      await client.post(request);
      toast.success("disabled");

      fetchPms();
    }
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        <Container>
          <div className="mb-3">
            <h3>PMS</h3>
          </div>
          <Row>
            <Col>
              <ListGroup className="mb-2">
                {rows?.map((pms) => {
                  return (
                    <ListGroupItem key={pms.id}>
                      <div className="d-flex justify-content-between">
                        <div className="flex-fill">
                          <h5 className="mb-1">
                            <Link
                              to={`/${pms.id?.toLowerCase()}`}
                              className="mr-1"
                            >
                              {pms.pmsId}
                            </Link>
                          </h5>
                          <p className="mb-1">{pms.description}</p>
                        </div>
                        <div className="px-2 listview-block">
                          <CompanyDisplay dto={pms.company as CompanyDto} />
                        </div>
                        <div className="px-2 listview-block">
                          <ContactPersonDisplay
                            dto={pms.company.primaryContactPerson}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div>
                            <small>
                              <Link to={pms.id?.toLowerCase()}>
                                <Button size="sm" color="primary">
                                  Manage
                                </Button>
                              </Link>
                            </small>
                          </div>
                          <div>
                            <small>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() => disable(pms as PMSDto)}
                              >
                                Disable
                              </Button>
                            </small>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  );
                })}
                {rows?.length === 0 && (
                  <ListGroupItem>
                    No PMS IDs are assigned to you. Request one below.
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Link to="/request/pms-id">
                <Button color="success">Request PMS ID</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

async function fetchPms() {
  let client = getApiClient();
  let request = new GetPMSsRequest();
  request.page = 1;
  request.pageSize = 133337;
  request.orderBy = "PMSId";

  let response = await client.get(request);

  pms.fromResponse(response);
}

export default MyPMS;
