import React from "react";
import Hero from "../../components/Hero";
import { Row, Col } from "reactstrap";

const RegisterComplete = () => {
  return (
    <>
      <Hero />
      <section className="section m-4">
        <div className="d-flex justify-content-between">
          <h3>Registration complete</h3>
        </div>
        <Row>
          <Col lg="12">
            <p className="lead">
              Thank you for registering. To verify your E-Mail address, please
              click on the activation link that has been sent to you by E-Mail.
              <br />
              <br />
              Your registration will be checked meanwhile by an administrator.
              After successful check, you will be able to register new IDs.
            </p>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default RegisterComplete;
