import React from "react";
import {
  KeyInterface,
  GetCommunicationsByOwner,
  KeySystem,
  CommunicationType,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { useQuery } from "react-query";
import CommunicationsForm from "./CommunicationsForm";

type CommunicationsProps = {
  TargetId: string;
  AllowedInterfaces: KeyInterface[];
};

export type CommunicationsFormValues = {
  groups: Array<CommunicationsFormData>;
};

export type CommunicationsFormData = {
  interface: KeyInterface;
  system: KeySystem;
  communications: Array<{
    host: string;
    port: number;
    type: CommunicationType;
  }>;
};

const Communications = (props: CommunicationsProps) => {
  const request = new GetCommunicationsByOwner({ belongsTo: props.TargetId });

  const { data, isLoading } = useQuery(
    [request],
    () => {
      return getApiClient().get(request);
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  if (isLoading) {
    return null;
  }

  let formData = {
    groups: [],
  } as CommunicationsFormValues;

  data.forEach((entry) => {
    let element = formData.groups.find(
      (p) => p.interface === entry.interface && p.system === entry.system
    );

    if (!element) {
      element = {
        interface: entry.interface,
        system: entry.system,
        communications: [],
      };

      formData.groups.push(element);
    }

    element.communications.push({
      host: entry.host,
      port: entry.port,
      type: entry.type,
    });
  });

  props.AllowedInterfaces.forEach((iface) => {
    if (
      !formData.groups.find((p) => p.interface === iface && p.system === "Test")
    ) {
      formData.groups.push({
        interface: iface,
        system: "Test",
        communications: [],
      });
    }

    if (!data.find((p) => p.interface === iface && p.system === "Prod")) {
      formData.groups.push({
        interface: iface,
        system: "Prod",
        communications: [],
      });
    }
  });

  formData.groups = formData.groups.sort(sortKey);

  return <CommunicationsForm value={formData} belongsTo={props.TargetId} />;
};

const sortKey = (
  key1: { interface: KeyInterface; system: KeySystem },
  key2: { interface: KeyInterface; system: KeySystem }
) => {
  if (key1.interface > key2.interface) {
    return 1;
  } else if (key1.interface < key2.interface) {
    return -1;
  }

  if (key1.system > key2.system) {
    return 1;
  } else if (key1.system < key2.system) {
    return -1;
  }

  return 0;
};

export default Communications;
