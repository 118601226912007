import { SimpluxProvider } from "@simplux/react";
import { ReactQueryDevtools } from 'react-query/devtools'
import App from "App";
import React from "react";
import { QueryClient,QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Root = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SimpluxProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SimpluxProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default Root;
